import React from "react";
import Footer from "../footer";
import Header from "../header";
import Applink from "../applink";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import "./careers.css";
import { API_URL } from "../../config/contants";
export default class Careers extends React.Component {

  constructor(props) {
    super(props);
    this.state = { open: false, jobs: [] };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    fetch(`${API_URL}/job/getJob`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query: { status: true } }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        this.setState({ jobs: result.response });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleClick() {
    this.setState(state => ({
      open: !state.open
    }));
  };

  render() {

    const jobs = [
      {
        id: 1,
        title: 'Product Designer',
        city: 'Bengaluru, Karnataka',
        //description: 'Lorem ipsum doler sit amet lorem ipsum sole'
      },
      {
        id: 2,
        title: 'Android Developer',
        city: 'Bengaluru, Karnataka',
        //description: 'Lorem ipsum doler sit amet lorem ipsum sole'
      },
      {
        id: 3,
        title: 'Java - Spring Boot Developer',
        city: 'Bengaluru, Karnataka',
        //description: 'Lorem ipsum doler sit amet lorem ipsum sole'
      },
      {
        id: 4,
        title: 'Growth Product Manager',
        city: 'Bengaluru, Karnataka',
        //description: 'Lorem ipsum doler sit amet lorem ipsum sole'
      },
      {
        id: 5,
        title: 'Quality Analyst',
        city: 'Bengaluru, Karnataka',
        // description: 'Lorem ipsum doler sit amet lorem ipsum sole'
      }
    ]


    return (
      <>
        <Helmet>
          <title>Tummoc | Careers</title>
          <link rel="canonical" href="https://tummoc.com/careers" />
        </Helmet>
        <div>
          <Header />

          <div className="top">

            <section>
              <div className="cahead">
                <div className="row v-sec" style={{ margin: 0 }}>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 headtext v-sec-1">
                    <p className="head1 headb">Teamwork makes <br /> the Dreamwork <br /> Join us! </p>
                    <p className="head2">
                      Join our team to be a part of the commute revolution in India!
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 v-sec-2" style={{ textAlign: 'center' }}>
                    <img src="/images/desk.png" alt="top header logo" className="header1-img" />
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="row" style={{ margin: 0, textAlign: 'center' }}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 wh-1">
                  <h1>Why work with us?</h1>
                  <p className="ph-1">
                    When you join Team Tummoc, you will be a part of the smart commute revolution in India.
                  </p>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12 col-12 wh-p">
                  <img src="/images/career1.png" alt="top header logo" style={{ height: '7rem' }} />
                  <h4 className="h-1">Growth </h4>
                  <p className="ph-1">Being a part of the early stages of something big involves a lot of personal growth as well.</p>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12 col-12 wh-p">
                  <img src="/images/career3.png" alt="top header logo" style={{ height: '7rem' }} />
                  <h4 className="h-1">Learning</h4>
                  <p className="ph-1">We can promise that you're always going to be learning new things — be it life lessons or professional skills. </p>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12 col-12 wh-p">
                  <img src="/images/career3.png" alt="top header logo" style={{ height: '7rem' }} />
                  <h4 className="h-1">Impact </h4>
                  <p className="ph-1">As a part of the Tummoc team, everything that you do will be creating a large impact. </p>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12 col-12 wh-p">
                  <img src="/images/career2.png" alt="top header logo" style={{ height: '7rem' }} />
                  <h4 className="h-1">Balance</h4>
                  <p className="ph-1">We don't believe in all work and no play. You will be encouraged to strike a balance at Tummoc.</p>
                </div>
              </div>
            </section>


            {/* <section className="mediaTwo mb-5">
              <div className="row mb-5" style={{ margin: 0 }}>
                <div className="col-lg-3 col-md-3 col-sm-3 col-12 testimonial">
                  <p className="news-head nh-1">Job Openings</p>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-12 cs-2" style={{ margin: '0' }}>

                  <div className="row" style={{ margin: 0, borderBottom: '1px solid #ccc' }}>
                    {this.state.jobs && this.state.jobs.map((item, i) => {
                      return (i < 3) && <div className="col-lg-4 col-md-6 col-sm-12 col-12 col-c">
                        <div class="card news-card jb-c">
                          <div style={{ height: '63%', padding: '1rem' }}>
                            <p className="txt-grad p3"> {item.title} </p>
                            <p style={{ marginTop: '1rem' }}>{item.location}</p>
                          </div>
                          <div class="card-body">
                            <p class="card-text">{item.description}</p>
                            <NavLink to={`/job-description/${item._id}`}>
                              <h6 class="card-title"> Apply Now <i class="fa fa-arrow-right"></i> </h6>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    })
                    }

                    {
                      !this.state.open && this.state.jobs.length > 3 &&
                      <div className="col-lg-12 exp-txt" style={{ padding: 0 }}>
                        <p onClick={this.handleClick} style={{ cursor: 'pointer', color: '#0e2d39', paddingRight: '1rem' }}>
                          See all job opportunities <i className="fa fa-chevron-down"></i>
                        </p>
                      </div>
                    }

                    {this.state.open && this.state.jobs && this.state.jobs.map((item, i) => {
                      return (i >= 3) && <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div class="card news-card jb-c">
                          <div style={{ height: '63%', padding: '1rem' }}>
                            <p className="txt-grad p3"> {item.title} </p>
                            <p style={{ marginTop: '1rem' }}>{item.location}</p>
                          </div>
                          <div class="card-body">
                            <p class="card-text">{item.description}</p>
                            <NavLink to={`/job-description/${item._id}`}>
                              <h6 class="card-title"> Apply Now <i class="fa fa-arrow-right"></i> </h6>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    })
                    }

                    {
                      this.state.open &&
                      <div className="col-lg-12 exp-txt" style={{ padding: 0 }}>
                        <p onClick={this.handleClick} style={{ cursor: 'pointer', color: '#0e2d39', paddingRight: '1rem' }}>
                          Collapse <i className="fa fa-chevron-up"></i>
                        </p>
                      </div>
                    }
                  </div>

                </div>
              </div>
            </section> */}

            <section className="mediaTwo mb-5">
              <div style={{ textAlign: "center", marginBottom: "5rem" }}>
                <a href="https://cutshort.io/company/tummoc-63-4DO62Zeo" rel="noreferrer" target="_blank">
                  <button className="btn my-2 my-sm-0 gradient-bg nav-btn" type="button" style={{padding: "0.5rem 4rem"}}>Apply Now
                </button>
                </a>
              </div>
            </section>



            {/* <section className="mediaTwo dwus" style={{ height: 'auto', marginBottom: '5rem' }}>
              <div className="row" style={{ margin: 0 }}>
                <div className="col-lg-3 col-12">
                  <p className="news-head nh-1" style={{ margin: '1rem' }}>Drive with us</p>
                </div>
                <div className="col-lg-9 cs-2" style={{ margin: 0 }}>
                  <div className="row rider v-sec" style={{ margin: 0 }}>
                    <div className="col-lg-4 col-12 v-sec-1">
                      <p className="txt-grad p3"> Be a rider, <br /> join our <br /> fleet </p>
                    </div>

                    <div className="col-lg-4 col-12 v-sec-1">
                      <p class="card-text">To and Fro, last mile connectivity made easy</p>
                      <h6 class="card-title">
                        <a href="https://play.google.com/store/apps/details?id=org.transhelp.bykerrpartner" target="_blank">
                          Find out how <i class="fa fa-arrow-right"></i>
                        </a>
                        <NavLink to="/drive-with-us">
                          Find out how <i class="fa fa-arrow-right"></i>
                        </NavLink>
                      </h6>
                    </div>

                    <div className="col-lg-4 col-12 v-sec-2" style={{ textAlign: 'center' }}>
                      <img src="/images/driver.png" alt="top header logo" style={{ height: '9rem' }} />
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            <section style={{ position: "relative" }}>

              <section className="vote-section" style={{ position: 'absolute', top: '-10rem', width: '100%' }}>
                <div style={{ background: '#fff', borderRadius: '2rem' }}>
                  <div className="link-section">
                    <Applink />
                  </div>
                </div>
              </section>

              <Footer />
            </section>
          </div>

        </div>
      </>
    );
  }
}
