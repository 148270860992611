import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import "./App.css";
import About from "./pages/about/about";
import Careers from "./pages/careers/careers";
import Contact from "./pages/contact/contact";
import Home from "./pages/home/home";
import HowItWorks from "./pages/howitworks/howitworks";
import DriveWithUs from "./pages/drivewithus/drivewithus";
import JobDescription from "./pages/jobdescription/jobdescription";
import DtcTicketHowToUse from "./pages/dtcTicketHowToUse/dtcTicketHowToUse.js";
import KtclTicketHowToUse from "./pages/ktclTicketHowToUse/ktclTicketHowToUse.js";
import Media from "./pages/media/media";
import Vote from "./pages/vote/vote";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Refund from "./pages/refund";
import DTCRefund from "./pages/dtcRefund.js"

class App extends Component {
  render() {
    console.log(window.location.pathname)
    var url = window.location.pathname;
    if(
      url == '/Contact' ||
      url == '/Terms-of-usage' ||
      url == '/Privacy-policy' ||
      url == '/Careers' ||
      url == '/Contact' 
    ) 
    window.location.pathname = (window.location.pathname).toLowerCase();

    if(url == '/Faq') window.location.pathname = '/contact';
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/how-it-works" component={HowItWorks} />
          <Route exact path="/media" component={Media} />
          <Route exact path="/vote-for-city" component={Vote} />
          <Route exact path="/terms-of-usage" component={Terms} />
          <Route exact path="/privacy-policy" component={Privacy} />
          <Route exact path="/cancellation-and-refund-policy" component={Refund} />
          <Route exact path="/drive-with-us" component={DriveWithUs} />
          <Route exact path="/job-description/:id" component={JobDescription} />
          <Route exact path="/dtc-ticket-how-to-use" component={DtcTicketHowToUse} />
          <Route exact path="/ktcl-ticket-how-to-use" component={KtclTicketHowToUse} />

          <Route exact path="/dtc-cancellation-and-refund-policy" component={DTCRefund} />

          <Redirect to="/home" />
        </Switch>
      </Router>
    );
  }
}
export default App;
