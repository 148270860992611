import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

class Header extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  

  constructor(props) {
    super(props);
    this.state = {
      showButton: false
    };

    this.scrollup = this.scrollup.bind(this);

    //old
    ReactGA.initialize("UA-174649081-1");
    ReactGA.pageview(window.location.pathname + window.location.search);


    // new
    ReactGA4.initialize("G-D791BXLRG7");
    ReactGA4.send(window.location.pathname + window.location.search);
  }


  scrollup() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState(state => ({
      showButton: false
    }));
  }

  componentDidMount() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        this.setState(state => ({
          showButton: true
        }));
      } else {
        this.setState(state => ({
          showButton: false
        }));
      }
    });
  }

  render() {

    return (
      <>
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark header-bg">
        <NavLink to="/home">
          <a className="navbar-brand">
            <img src="/images/logoHeader.png" className="logo-img left-img" alt="tummoc logo" />
          </a>
        </NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink to="/home">
                <img src="/images/logoHeader.png" className="logo-img mid-img" alt="tummoc logo" />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nlink" to="/about">ABOUT</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nlink" to="/contact">CONTACT</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nlink" to="/careers">CAREERS</NavLink>
            </li>
            <li className="nav-item">
              <a href="https://blog.tummoc.com/" className="nlink" target="_blank">BLOG</a>
              {/* <NavLink className="nlink" to="/">BLOG</NavLink> */}
            </li>
            <li className="nav-item">
              <NavLink className="nlink" to="/media">MEDIA</NavLink>
            </li>
          </ul>
          <form className="form-inline my-2 my-lg-0">
          <a className="headerappicon" href="https://0x92l.app.link/mql2Jm1fqvb" target="_blank"><i class="fab fa-google-play"></i></a>
          <a className="headerappicon" href="https://0x92l.app.link/mql2Jm1fqvb" target="_blank"><i class="fab fa-apple"></i></a>
         
            <button className="btn my-2 my-sm-0 gradient-bg nav-btn" type="button">
              <NavLink className="nbtnlink" to="/how-it-works">HOW IT WORKS</NavLink>
            </button>

          </form>
        </div>
      </nav>

      {this.state.showButton && (
        <button className="arrowup" onClick={this.scrollup}>
          <i class="fa fa-angle-up"></i>
        </button>
      )}

      </>
    );
  }
}

export default withRouter((props) => <Header {...props} />);
