import React, { useState } from "react";
import "./verticalTimeline.css";


const VerticalTimeline = (props) => {
	const { items } = props;
    	
    const [allItems, setShow] = useState(items);
    
    const handleShow = (index, val) => {

        items.map( (e, ind) => {
            if(ind === index) e.show = val;
            else e.show = false;
        });

        setShow(arr => [ items[0], items[1], items[2], items[3], items[4], items[5], items[6], items[7] ]);
        // console.log(allItems);
    }

	return (

        allItems.length > 0 && (
            <div className="timelinev-container">
                {allItems.map((item, i) => (

                    <div key={i} className="timelinev-item">
                        {
                            item.show &&
                                <div className={ (i%2 === 0) ? "timelinev-item-content timelinev-item-content-right" : "timelinev-item-content timelinev-item-content-left"}>
                                    <h2 style={{fontWeight: '600'}}> {item.name} </h2>
                                    <p style={{fontSize: '0.8rem'}}> {item.description} </p>
                                    <span className="circle" />
                                </div>
                        }

                        {
                            !item.show &&
                            <div className={ (i%2 === 0) ? "timelinev-item-content timelinev-item-content-right" : "timelinev-item-content timelinev-item-content-left"} onClick={(_) => { handleShow(i, true); }} style={{color: '#0e2d39'}}>
                                <h2 style={{fontWeight: '600'}}> {item.name} </h2>
                                <span className="circle" />
                            </div>
                        }

                    </div>

                ))}
            </div>
        )
	)
}

export default VerticalTimeline;
