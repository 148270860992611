import React from "react";
import Footer from "../footer";
import Header from "../header";
import Applink from "../applink";
import "./howitworks.css";
import { Helmet } from "react-helmet";

export default class HowItWorks extends React.Component {

  ind = 1;

  constructor(props) {
    super(props);
    this.state = { open: false, stepselected: 0 };
    this.handleClick = this.handleClick.bind(this);

    setInterval( () => {
      this.setStep(this.ind);
      this.ind++;
      if(this.ind === 3) this.ind = 0;
    }, 5000);
    
  }


  handleClick() {
    // window.scrollTo(0, 600);
    this.setState(state => ({
      open: !state.open
    }));
  }

  setStep(i) {
    this.ind = i;
    this.setState(state => ({
      stepselected: i
    }));
  }

  render() {

    const imgloop = [
      { 
        stepimage: 'step1.png', heading: 'Pick', title: 'your ride', 
        description: 'Bus/Metro Information, Book last mile rides or Plan end to end commute', 
        image: 'st1.png' 
      },
      { 
        stepimage: 'step2.png', heading: 'Choose', title: 'your route', 
        description: 'Find the fastest or cheapest route at the tap of a button!', 
        image: 'st2.png' 
      },
      { 
        stepimage: 'step3.png', heading: 'Map', title: 'your commute', 
        description: 'Get an accurate visualization and navigation of the selected route', 
        image: 'st3.png' 
      }
    ]

    const leaders = [
      { name: 'Sandhya', tag: 'Expert', points: '17730' },
      { name: 'Bhaanojee', tag: 'Expert', points: '17460' },
      { name: 'Sowmya', tag: 'Virtuoso', points: '13680' },
      { name: 'Geetha Madhavi', tag: 'Virtuoso', points: '13140' },
      { name: 'Srinija', tag: 'Virtuoso', points: '11880' },
      { name: 'Saikrishna', tag: 'Virtuoso', points: '11790' },
      { name: 'Rachel Pathi', tag: 'Virtuoso', points: '11340' },
      { name: 'Vijaya', tag: 'Virtuoso', points: '11160' },
      { name: 'Abhilasha', tag: 'Virtuoso', points: '10980' },
      { name: 'Renuka', tag: 'Virtuoso', points: '10980' }
    ]

    return (
      <>
        <Helmet>
          <title>Tummoc | How it Works</title>
        </Helmet>

        <div>
          <Header />

          <div className="top">

            <section style={{ position: 'relative' }}>
              {/* <img src="/images/video.png" alt="leaderboard" className="video" />
              <div className="video-bg"> </div>

              <div className="top-txt">
                <h1 className="v-h1">How it Works</h1>
                <p>Find out how to use the Tummoc app!</p>
              </div> */}

            {/* <iframe width="560" height="315" src="http://www.youtube.com/embed/CZRarXtcV54?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=1" frameborder="0" allowfullscreen title="Tummoc product video"></iframe> */}

              <video style={{width: '100%'}}  muted autoPlay={"autoplay"} preLoad="auto" loop>
                  <source src="images/tummoc_product_video.mp4" type="video/mp4" />
              </video>
            </section>

            <section className="hitwpcm">
              <div className="row v-sec" style={{ margin: 0 }}>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 v-sec-1">
                  {
                    imgloop && imgloop.map( (item, i) => {
                      return <div className={ ( (i) === this.state.stepselected) ? "row step-card high-card": "row step-card"} onClick={ () => this.setStep(i)}>
                              <div className="s-in-c row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                  <img src={`/images/${item.stepimage}`} alt="leaderboard" style={{ height: '5rem' }} />
                                </div>
                                <div className="col-lg-9 col-md-6 col-sm-6 col-12">
                                  <h2 className="txt-grad headb" style={{ marginBottom: 0, fontWeight: '800' }}>{item.heading}</h2>
                                  <p className="txt-grad p2" style={{ marginTop: 0 }}>{item.title}</p>
                                  <p>{item.description}</p>
                                </div>
                              </div>
                            </div>
                    })
                  }
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 v-sec-2 w-img" style={{ textAlign: 'center' }}>
                  <img src={`/images/${imgloop[this.state.stepselected].image}`} alt="leaderboard" className="h-img img-fluid" />
                </div>
              </div>
            </section>

            <section>
              <div className="gradient-bg leaderboard row" style={{ margin: 0 }}>

                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <p className="lead-head">Tummoc Leaderboard</p>
                </div>

                <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">

                  <div className="row" style={{ margin: 0 }}>

                  {leaders && leaders.map((item, i) => {
                      return (i < 3) && <div className="col-lg-4">
                              <div className="card how-card">
                                <div className="row" style={{ margin: 0, padding: 0 }}>
                                  <div className="col-lg-6 col-50" style={{padding: 0}}>
                                    <p style={{ marginBottom: 0 }}>{item.name}</p>
                                    <h6> {item.tag} </h6>
                                    <p className="txt-grad p2 headb"> {item.points}</p>
                                  </div>
                                  <div className="col-lg-6 col-50 w-img">
                                    <img src="/images/medal1.png" alt="leaderboard" className="lead-img" />
                                  </div>
                                </div>
                              </div>
                            </div>
                    }
                  )}

                  </div>

                  {
                    !this.state.open &&
                    <div className="col-lg-12 exp-txt">
                      <p onClick={this.handleClick} style={{ cursor: 'pointer' }}>
                        Expand <i class="fa fa-chevron-down"></i>
                      </p>
                    </div>
                  }

                  <div className="row" style={{ margin: 0 }}>
                    {
                      this.state.open &&

                      leaders && leaders.map((item, i) => {
                        return (i >= 3) &&  <div className="col-lg-4">
                              <div className="card how-card">
                                <div className="row" style={{ margin: 0, padding: 0 }}>
                                  <div className="col-lg-6 col-50" style={{padding: 0}}>
                                    <p style={{ marginBottom: 0 }}>{item.name}</p>
                                    <h6> {item.tag} </h6>
                                    <p className="txt-grad p2 headb"> {item.points}</p>
                                  </div>
                                  <div className="col-lg-6 col-50 w-img">
                                    <img src="/images/medal1.png" alt="leaderboard" className="lead-img" />
                                  </div>
                                </div>
                              </div>
                            </div>
                        }
                      )
                    }
                  </div>

                  {
                    this.state.open &&
                    <div className="col-lg-12 exp-txt">
                      <p onClick={this.handleClick} style={{ cursor: 'pointer' }}>
                        Collapse <i class="fa fa-chevron-up"></i>
                      </p>
                    </div>
                  }

                </div>

              </div>
            </section>

            <section style={{ position: "relative" }}>

              <section className="vote-section" style={{ position: 'absolute', top: '-10rem', width: '100%' }}>
                <div style={{ background: '#fff', borderRadius: '2rem' }}>
                  <div className="link-section">
                    <Applink />
                  </div>
                </div>
              </section>

              <Footer />
            </section>

          </div>
        </div>

      </>
    );
  }

}
