import React, { useState } from "react";
import "./timeline.css";

const Timeline = (props) => {
	const { items } = props;
	// const totalItems = items.length;
	// const numberOfActiveItems = items.filter(item => item.active).length;
	// const progressBarWidth = totalItems > 1 ? (numberOfActiveItems - 1) / (totalItems - 1) * 100 : 0;
    	
    // console.log(items);
    const [allItems, setShow] = useState(items);
    
    // console.log(allItems);

    const handleShow = (index, val) => {

        items.map( (e, ind) => {
            // console.log('in')
            if(ind === index) e.show = val;
            else e.show = false;
        });

        setShow(arr => [ items[0], items[1], items[2], items[3], items[4], items[5], items[6], items[7] ]);
        // console.log(allItems);
    }

	return (
		<div className="timeline">
			<div className="timeline-progress"></div>
			<div className="timeline-items">
				{(allItems && allItems.length) && allItems.map((item, i) => (
					<div key={i} className={"timeline-item"} onClick={(_) => { handleShow(i, true); }}>
                        {
                            item.show &&
                            <div className={ (i%2 === 0) ? "timeline-content-up-expand" : "timeline-content-expand"} style={{color: '#0e2d39'}}>
                                <h2 style={{fontWeight: '600'}}> {item.name} </h2>
                                <p style={{fontSize: '0.8rem'}}> {item.description} </p>
                            </div>
                        }

                        {
                            !item.show &&
                            <div className={ (i%2 === 0) ? "timeline-content-up" : "timeline-content"} style={{color: '#0e2d39'}}>
                                <h2 style={{fontWeight: '600'}}> {item.name} </h2>
                            </div>
                        }
						
					</div>
				))}
			</div>
		</div>
	)
}

export default Timeline;
