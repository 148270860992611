import React from "react";
import Footer from "../footer";
import Header from "../header";
import { Helmet } from "react-helmet";
import Applink from "../applink";
import "./jobdescription.css";
import { API_URL } from "../../config/contants";


export default class Media extends React.Component {

  id = '';

  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;

    this.state = { open: false, successMsg: '', attachment: '', details: {} };
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
  }

  
  componentDidMount(){

    console.log(this.id);
    
    fetch(`${API_URL}/job/getJob`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query: {_id:  this.id} }), 
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        this.setState( { details: result.response[0]} );
      })
      .catch((error) => {
        console.error(error);
      });      
  }

  handleClick() {
    this.setState(state => ({
      open: !state.open
    }));
  };

  onChangeFile = (event) => {

    var file = event.target.files[0];
    this.setState( {attachment: file.name} );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    e.target.reset();

    fetch("https://www.tummoc.com/api/tummoc/career", {
      method: "POST",
      headers: { enctype: "multipart/form-data" },
      body: formData, //JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState( {successMsg: result.message} );

        setTimeout(() => {
          this.setState( {successMsg: ''} );
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {

    return (
      <>
        <Helmet>
          <title>Tummoc | Media</title>
        </Helmet>
        <div>
          <Header />

          <div className="s-top">

          <section className="mediaOne py-5" style={{backgroundRepeat: 'repeat'}}>
              <div className="row" style={{ margin: 0 }}>
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 headtext top1 my-5">
                  <p className="head1 w-1 txt-grad headb">{this.state.details.title}</p>
                  <p className="head2 w-2">
                    {this.state.details.short_description}
                  </p>
                  {/* <button className="btn my-2 my-sm-0 voteme-btn" type="button">
                    APPLY NOW
                  </button> */}
                </div>
              </div>

              {/* <div className="vote-section vt-1">
                <div className="sectionFour mediaFour">
                  <div className="row" style={{ height: '100%', margin: 0 }}>

                    <div className="col-lg-12 col-md-12 col-sm-12 b-card1">
                      <img src="/images/job.png" class="card-img news-img nw-img md-vdo" alt="newsimage" />
                    </div>

                  </div>
                </div>
              </div> */}

            </section>

            <section className="px-lg-5 px-md-5 mx-lg-5 mx-md-5 pt-3 pb-5 mb-5">
              <div className="row" style={{margin: 0}}>
                <div className="col-lg-6 col-md-6 col-12 p-5">
                    <h3 className="mb-3">Job Description</h3>

                    <p style={{fontSize: '0.8rem'}}>
                      {this.state.details.job_description}
                    </p>

                    <br/>

                    <h3 className="mb-3">Requirements</h3>

                    <p className="jd" style={{fontSize: '0.8rem'}} dangerouslySetInnerHTML={{ __html: this.state.details.requirement}}></p>
                      {/* Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit 
                      quaerendum. At nam minimum ponderum. Est audiam animal molestiae te. Ex duo eripuit 
                      mentitum. */}
                    

                    {/* <ul className="m-ul" style={{ listStyleType: "square", fontSize: '0.8rem' }}>
                      <li className="m-li"> At nam minimum ponderum. Est audiam animal molestiae te. Ex duo eripuit mentitum. </li>
                      <li className="m-li"> At nam minimum ponderum. Est audiam animal molestiae te. Ex duo eripuit mentitum. </li>
                      <li className="m-li"> At nam minimum ponderum. Est audiam animal molestiae te. Ex duo eripuit mentitum. </li>
                      <li className="m-li"> At nam minimum ponderum. Est audiam animal molestiae te. Ex duo eripuit mentitum. </li>
                    </ul> */}
                </div>

                <div className="col-lg-6 col-md-6 col-12 p-5">

                    <h4 className="mx-3">Apply Here</h4>

                    <form
                      autoComplete="off"
                      method="post"
                      onSubmit={ e => this.handleSubmit(e) }
                      encType="multipart/form-data"
                    >
                      <input type="text" placeholder="First Name*" name="firstname" className="input-box c-inp" />
                      <input type="text" placeholder="Last Name*" name="lastname" className="input-box c-inp" />
                      <input type="email" placeholder="Email*" name="email" className="input-box c-inp" />
                      <input type="tel" placeholder="Phone*" name="phone" className="input-box c-inp" />
                      <input type="text" placeholder="Role*" name="role" className="input-box c-inp" />
                      <input type="text" placeholder="Link" name="link" className="input-box c-inp" />
                      <textarea type="text" rows="3" placeholder="Why do you want to join us?" name="message" className="input-box c-inp c-txtb" />

                      <div className="m-lg-3 m-sm-0 my-3">
                        <button className="btn" type="button" onClick={ () => document.getElementById('avatar').click()}>
                          { this.state.attachment || "Attach Resume" }
                        </button>
                        <input
                          type="file"
                          name="avatar"
                          id="avatar"
                          accept=".doc,.docx,.pdf"
                          onChange={ e => this.onChangeFile(e) }
                          style={{display: 'none'}}
                        />
                      </div>

                      {this.state.successMsg && (
                            <p className="m-3 m-sm-0 my-3" style={{fontSize: '0.8rem', color: '#0e2d39'}}>{this.state.successMsg}</p>
                      )}

                      <button className="btn m-lg-3 m-sm-0 my-3 nav-btn vote-btn vote-now nlink s-btn" type="submit" style={{float: 'left'}}>
                        SUBMIT
                      </button>
                    </form>

                </div>

              </div>
            </section>

            <section style={{ position: "relative" }}>

              <section className="vote-section" style={{ position: 'absolute', top: '-10rem', width: '100%' }}>
                <div style={{ background: '#fff', borderRadius: '2rem' }}>
                  <div className="link-section">
                    <Applink />
                  </div>
                </div>
              </section>

              <Footer />
            </section>

          </div>

        </div>
      </>
    );
  }

}
