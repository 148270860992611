import React from "react";
import Footer from "../footer";
import Header from "../header";
import { Helmet } from "react-helmet";
import Applink from "../applink";
import "./contact.css";
import { NavLink } from "react-router-dom";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
export default class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = { successMsg: '' };
    this.handleContact = this.handleContact.bind(this);
  }

  handleContact(e) {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    data.subject = 'Contact us query'
    e.target.reset();

    fetch("https://www.tummoc.com/api/support/contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result.message);
        this.setState({ successMsg: result.message });

        setTimeout(() => {
          this.setState({ successMsg: '' });
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleMobileVal = (event) => {
    console.log(event.target.value.length, event.target, event.target.value, event.keyCode)

    return (event.target.value.charCode >= 48 && event.target.value.charCode <= 57);
    // if(event.target.value.length == 10) return false;
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Tummoc: Local Travel in Bengaluru and Delhi | Contact Us</title>
          <link rel="canonical" href="https://tummoc.com/contact" />
          <meta name="title" content="Tummoc: Local Travel in Bengaluru and Delhi | Contact Us" />
          <meta name="description" content="Travelling in Delhi, Bengaluru and More Is Easy With Live Tracking, Digital Bus Tickets and Passes, An Easy to Use Daily Travel Planner and More With Tummoc" />
        </Helmet>
        <div>
          <Header />

          <div className="top">

            <section>
              <div className="chead">
                <div className="row v-sec" style={{ margin: 0 }}>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 headtext v-sec-1">
                    <p className="head1 headb">Get in touch! <br /> We'd love to hear <br /> from you </p>
                    <p className="head2">
                      Tummoc is an end-to-end, intra-city commute solution provider. It is the only app that connects you the the public transport network
                      and local rideshare options to craft the best suited route for your needs.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 v-sec-2" style={{ textAlign: 'center' }}>
                    <img src="/images/contact.png" alt="top header logo" className="header1-img" />
                  </div>
                </div>
              </div>
            </section>


            <section>
              <div className="con-tainer">
                <Tabs>

                  <div className="row" style={{ margin: 0 }}>
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 con-s">

                      <h3 className="testi1" style={{ margin: 0, marginBottom: '0.5rem' }}>FAQs</h3>
                      <TabList>
                        {/* <Tab>What is Tummoc?</Tab>
                            <Tab>How to use Tummoc?</Tab>
                            <Tab>Booking Related</Tab>
                            <Tab>Payment Related</Tab>
                            <Tab>Trip Planning Related</Tab>
                            <Tab>Safety Related</Tab> */}

                        <Tab>New to Tummoc</Tab>
                        <Tab>Using Tummoc App</Tab>
                        <Tab>Saved Places/Routes</Tab>
                        <Tab>Tummoc Bus Pass/Ticketing</Tab>
                        <Tab>Conductor/Line Checker</Tab>
                        <Tab>BMTC Pass Booking</Tab>
                      </TabList>
                    </div>

                    <div className="col-lg-9 col-sm-8 col-xs-12">

                      <TabPanel>
                        <Accordion allowZeroExpanded>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                I haven’t received an OTP to log in. What should I do?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                By default, the Tummoc app will recognize the OTP once you receive it.
                                In case this doesn’t happen, then you can check your SMS inbox.
                                If you still haven’t received it, then write to us at support@tummoc.com
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Why do I need to log in?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Logging in to the Tummoc app allows us to give you a personalised and seamless
                                commute experience. You can save your favourite locations and add money to your
                                wallet.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What are the different ways to log in?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                There are two ways to log in to Tummoc — using your Google account or
                                with your mobile number and an OTP.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Why is the app asking to access my location?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Since Tummoc is a commuting app, accessing your location is important for us
                                to provide the best service to you. It allows us to find bus stops & metro
                                stations near you, and track each leg of your journey.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I change my notification settings?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Subscribing to Tummoc’s app notifications will allow you to stay on top of public
                                transport updates and any new features. However, if you’d like to unsubscribe,
                                go to the Tummoc app settings in your phone settings and change the notification
                                settings there.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Tummoc is not available in my city. How can I vote for my city?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Currently, Tummoc is available in Bangalore, Hyderabad and Kolkata. We’ll be
                                launching in Mumbai, Delhi and many other cities very soon. To vote for your city,
                                <NavLink to="/vote-for-city"> click here </NavLink>.

                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What do I do if the app shows an internet connectivity issue?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Check the internet connectivity on your phone, or switch off your wifi/mobile data
                                and restart it after a couple of minutes. You can also try closing and restarting the Tummoc app.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I change my city on the Tummoc app?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                You can either select your city on the top right corner of the
                                Tummoc app home screen or from the menu on the top left corner.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Can I use the app offline?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                As of now, you can only use certain features offline. If you have saved
                                routes, you can access them offline. However, you won’t be able to track
                                any buses or metro in real-time while offline.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </TabPanel>


                      <TabPanel>
                        <Accordion allowZeroExpanded>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Is the bus/metro information on Tummoc accurate?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                All information you find on Tummoc has been taken from various reliable sources,
                                including State Transport Departments. If you find an inaccuracy, you can report
                                it on the app, and we’ll look into it at the earliest.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What happens if I click swipe on arrival?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Swiping on arrival will help you access the best of Tummoc’s trip planner and
                                smart commute experience. This allows us to understand when you’ve completed a
                                stage of your commute, and help you plan the next stage accurately.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I book a Rapido bike or auto on Tummoc?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                It’s simple! Just enter your source and destination, and click on the “Book Rapido”
                                button. This can be done from the home screen, or from any page that gives you route options.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                I can't find my location on the map results. What should I do?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                If you can’t find a location while selecting your source or destination, just click on the
                                “Can’t Find My Location” button, and report the address. We'll look into it at the earliest.
                                In the meantime, you can use a location closest to the one you were looking for.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I report bugs/issues with the app?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                You can go through all these FAQs for a quick solution. If the issue you’re facing is still not resolved,
                                then please mail us at support@tummoc.com and we’ll get back to you at the earliest.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I provide feedback/suggestions towards the suggested route?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Once you submit your source & destination and receive route suggestions, you’ll see a banner
                                at the bottom of your screen with the text “Help other travellers”. Tap on this to give your
                                feedback on routes, or report any inaccuracies.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I plan my route for a later time?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Once you enter your source and destination, you’ll see a button on the right which by
                                default says “Depart now”. Tap on this to change the time at which you’d like to reach
                                your destination. This feature allows you to plan your trip for another point in the day.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I track buses in real-time?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Once you've received various route options from Tummoc, click on the one you'd like to follow.
                                A detailed view of the route will open up, with cards for each leg of the journey. Scroll to
                                the bus card, and you'll see a "Live updates" button. Tapping on this will not only allow you
                                to track the bus in real-time but also track the next two scheduled buses.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I delete my account?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Unfortunately, there is no option to delete your account from the app. If there are any bugs or
                                issues you’d like to report, please do so and we’ll resolve them at the earliest. However, if
                                you would still like to delete your account, you can mail us at support@tummoc.com, and we’ll
                                follow through with your request with a heavy heart.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I refer the app to my friends and family?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Open the menu on the top left corner of the Tummoc app, and tap on “Refer and earn”. You can either
                                share the referral code that you’ll find here or tap on the “Share your referral code” button.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </TabPanel>



                      <TabPanel>
                        <Accordion allowZeroExpanded>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I save my route?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Once you’ve entered your source & destination and found possible routes, you’ll see a banner near the bottom of your screen with
                                both source and destination mentioned, along with “Save” and “Share” buttons. Tap on “Save” to save a route.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I save an address?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                While entering your source or destination, you’ll notice a heart icon
                                on the right-hand side of the box. Simply tap on that heart icon to save
                                a place/location to your favourites.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I update my home/work address?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                On the Tummoc app home screen, tap on the heart icon next to “Search destination here”.
                                This will take you to your favourites. Tap on “Saved Places” and find the address
                                that you’d like to update. Tap on the pencil icon/edit button to update it.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I share my saved places/routes?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                On the Tummoc app home screen, tap on the heart icon next to “Search destination here”.
                                This will take you to your favourites. Next to the saved place or saved route that you’d
                                like to share, you’ll find a share button.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Where can I find my saved address?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                You can either head to “My Favourites” in the menu, or tap on the heart icon next to
                                “Search destination here” on the home screen. This will take you to your favourites,
                                where you can access your saved places and saved routes.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </TabPanel>

                      {/* new */}

                      <TabPanel>
                        <Accordion allowZeroExpanded>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What do I do if the conductor doesn’t have the QR to scan and validate my pass/ticket?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                If the conductor/LC doesn’t have the QR, he/she can go through the details of your
                                ticket/pass on the Tummoc app to manually verify the details. The details that will
                                be verified are the route mentioned on the ticket, the validity, the profile
                                picture/name and the dynamic QR code.
                              </p>
                              <p> OR </p>
                              <p>
                                The user can also self validate his pass by scanning the QR code that the conductor is carrying.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What happens if my internet is not working?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                If your internet isn’t working, then you can give the conductor your ticket number or the registered
                                phone number with which you bought the ticket/pass. He/she will then verify this on his app/ETM device.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How do I present the pass to the conductor and the line checker?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                You need to show the ticket/pass on the Tummoc app for the conductor/line checker to verify your ticket/pass.
                              </p>
                              <p>
                                In case the conductor asks for your ID proof, you can either show a physical/digital copy of the ID used at the time of booking.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Can I pre-book tickets/passes?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p> Yes, you can. </p>

                              <p>
                                While booking tickets or daily passes, you need to purchase them on the
                                same calendar day on which you intend to commute.
                              </p>

                              <p>
                                Weekly passes can be purchased up to two days before the start of the week
                                for which you are purchasing a pass.
                              </p>

                              <p>
                                In the case of monthly passes, you can purchase a pass for a month only
                                until the 12th of that month. After the 12th of a month, you can pre-book
                                your monthly passes for the following month.
                              </p>

                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How many tickets can I book at a time?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                You can book up to five tickets at a time.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                When should I activate my ticket(s)?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Ideally, you should activate your ticket once you’re on the bus or just before alighting the bus.
                                Once you activate a ticket, it needs to be validated within 30 minutes. By default, tickets are
                                valid only for the same calendar day.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I validate my ticket/pass inside the bus?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                There are three ways to validate your ticket/pass.
                              </p>
                              <p> Once you open your ticket/pass on the Tummoc app: </p>
                              <p> Tap on the Scan QR button in Tummoc app and scan the QR that the conductor presents. </p>
                              <p> Show your QR code to the conductor for him/her to scan with his/her QR code scanner using ETM device/mobile app. </p>
                              <p> Let the conductor go through the ticket/pass details manually to verify the validity and any other vital details.</p>

                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What do I do if my pass has expired?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                We will notify you one day before your pass expires. You can choose to renew it on
                                the app or pay cash to the conductor for a physical ticket.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What happens if my ticket has expired?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                By default, tickets are valid only for the same calendar day as the date of purchase. If your ticket has expired,
                                you will need to purchase a physical ticket from the conductor.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </TabPanel>


                      {/* new 2 */}

                      <TabPanel>
                        <Accordion allowZeroExpanded>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What are the different methods for pass validation?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                There are three ways to validate a commuter's ticket/pass. First,
                                ask them to open their ticket/pass on their Tummoc App.
                              </p>
                              <p>1.  You can ask them to open the QR code scanner on their ticket/pass, and let them use it to scan your QR code. </p>
                              <p>2. You can use the QR code scanner on your ETM device/mobile app to scan the QR code on their ticket/pass. </p>
                              <p>3. You can go through the ticket/pass validity, route, and commuter identity to manually validate it.</p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How to ensure that a pass/ticket is authentic?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                The QR code on the commuter's ticket/pass page will keep changing to ensure that ticket/pass is authentic.
                              </p>
                              <p>
                                When commuter scans the  conductor’s QR code, the commuter’s app will show a screen that displays
                                whether the ticket/pass is valid or not and it will be marked as validated accordingly.
                                The app will also show the details for last validated time, bus number and route number.
                                The same will show on conductor’s ETM device/mobile app  if they scan the commuter’s QR code.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What fields should be checked while validating the pass/ticket?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p> 1. The pass/ticket validity & the fare paid. </p>
                              <p> 2. The route, in case of a ticket. </p>
                              <p>
                                3. The identity of the commuter. This can be checked through the photo on the ticket/pass.
                                In case of a pass, you can also ask the commuterto present the ID that they used while booking.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What happens if the customer is unable to scan the QR code?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                You can validate the ticket/pass by verifying the details, profile picture
                                and/or scanning the dynamic QR code on their ticket/pass.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What happens if the pass/ticket is expired?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                If the pass is showing as invalid/expired, then the customer will have to buy a
                                physical ticket from you through a cash or UPI payment.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How many tickets can a user book?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                A user can book up to five tickets at a time.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I log in to the ETM device and LC app?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                The login details for ETM devices and LC apps remains the same as provided by BMTC.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can LC’s validate tickets/passes?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                LC’s can validate tickets/passes using the Tummoc validation app with the
                                QR code scanner, or by manually checking.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How to validate the pass/ticket ?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>Three ways to validate your ticket/pass:</p>
                              <p>The conductor can verify all details manually. </p>
                              <p>The conductor/LC can scan the QR code on your ticket using the ETM and validate the ticket/pass</p>
                              <p>The conductor will show you a QR code that you can scan on the Tummoc app to validate it. </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                In case of any doubts or clarification whom do I reach out to?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                If you have any doubts, you can call the BMTC support line at 080 22483777 and the Tummoc support line at 8147378946.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </TabPanel>

                      {/* new 3 */}

                      <TabPanel>
                        <Accordion allowZeroExpanded>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What do I do if the conductor doesn’t have the QR to scan and validate my pass/ticket?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                If the conductor/LC doesn’t have the QR, you can go through the details of your ticket/pass on the Tummoc app to manually verify the details. The details that will be verified are the route mentioned on the ticket, the validity, the profile picture/name and the dynamic QR code.
                              </p>
                              <p>OR </p>
                              <p>You can also self validate your pass by scanning the QR code that the conductor is carrying.</p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What happens if my internet is not working?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                If your internet isn’t working, then you can give the conductor your ticket number or the registered phone number with which you bought the ticket/pass. He/she will then verify this on his app/ETM device.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How do I present the pass to the conductor and the line checker?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                You need to show the ticket/pass on the Tummoc app for the conductor/line checker to verify your ticket.
                              </p>
                              <p>
                                Additionally, in the case of passes, you may be asked for the ID number of the ID that you used while booking your pass.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Can I pre-book tickets/passes?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Yes, you can.
                              </p>
                              <p>
                                While booking tickets or daily passes, you need to purchase them on the same calendar day on which you intend to commute.
                              </p>
                              <p>
                                Weekly passes can be purchased up to two days before the start of the week for which you are purchasing a pass.
                              </p>
                              <p>
                                In the case of monthly passes, you can purchase a pass for a month only until the 15th of that month. You can pre-book your monthly passes for the following month starting from the last four days of the previous month.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How can I validate my pass inside the bus?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                There are three ways to validate your ticket/pass.
                              </p>
                              <p>
                                Once you open your ticket/pass on the Tummoc app:
                              </p>
                              <p>
                                1. Tap on the Scan QR button in Tummoc app and scan the QR that the conductor presents.
                              </p>
                              <p>
                                2. Show your QR code to the conductor for him/her to scan with his/her QR code scanner using ETM device/mobile app.
                              </p>
                              <p>
                                3. Let the conductor go through the ticket/pass details manually to verify the validity and any other vital details.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What do I do if my pass has expired?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                We will notify you one day before your pass expires. You can choose to renew it on the app or pay cash to the conductor for a physical ticket.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Can I download my pass or access it offline?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Your BMTC bus pass will be available on the Tummoc app in offline mode too.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Can I cancel my pass purchase and avail a refund?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                No, you cannot cancel your bus pass. It is non-transferrable and non-refundable.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Can the validity of the monthly pass be extended to the next month?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Monthly passes, as per the norm, are valid for the same calendar month only. They will expire on the last day of the month.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Once I've booked my pass, how can I use it to travel via BMTC buses?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Once a pass is booked, a dynamic QR code will be generated. You can show this QR code to validate your pass. While travelling, you will be required to carry the ID card that was used in the booking process.
                              </p>
                              <p>
                                In case the conductor's ETM isn't working for some reason, you can scan the QR code available with the conductor to validate your pass.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Is there an option to pay for BMTC bus passes via internet banking, paytm wallet, etc.?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                At present, we accept UPI payments. Soon, you will also be able to pay through Internet banking.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                If I have purchased a Vayu Vajra Monthly Pass, can I use it to travel via ordinary non-ac buses?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Yes, in case you have a monthly pass, here's what you need to keep in mind:
                              </p>
                              <p>
                                * If it's a Vajra Gold Pass - it is valid for ordinary non-AC buses too but not for Vayu Vajra buses.
                              </p>
                              <p>
                                * If it's a Vayu Vajra Gold Pass - itis valid in all AC and non-AC buses.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Do I need to scan the QR code every time I travel via BMTC buses using a digital pass?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Yes, you need to scan the QR code every time you travel via BMTC buses using online passes. You can either validate it with the conductor or self-validate using the QR code available in the bus.  This is because you will be travelling via different buses across different dates.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What should I do if the conductor refuses to accept the BMTC pass that I have availed on the Tummoc app?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                We're sorry your facing this issue. If the conductor is refusing to accept your pass, you can share his/her token no., the vehicle no., and route no. with us. This will help us forward the details to the concerned department and address the issue at the earliest.
                              </p>
                              <p>
                                You can also contact us on:
                              </p>
                              <p>
                                Tummoc Support Phone No.: +91 81473 78946 <br />
                                BMTC Helpline No. — 08022483777
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                        </Accordion>
                      </TabPanel>


                    </div>
                  </div>
                </Tabs>
              </div>
            </section>


            <section className="c-bird">
              <div className="row">
                <div className="col-lg-6">
                  <ul className="c-ul">
                    <h4 style={{ marginBottom: '1rem' }}>Get in Touch</h4>
                    <li> <img src="/images/call.png" alt="top header logo" className="co-img" /> 81473 78946 </li>
                    <li> <img src="/images/mail.png" alt="top header logo" className="co-img1" /> support@tummoc.com </li>
                    <li style={{ display: 'flex' }}>
                      <span> <img src="/images/address.png" alt="top header logo" className="co-img" /> </span>
                      <span>
                        Tummoc - No. 5 Maruthi Complex, GF, 9th'B Main,<br />
                        Dodda Banaswadi Main Rd, Kalyan Nagar,<br />
                        Bengaluru, Karnataka 560043<br />
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-6" style={{ textAlign: 'right' }}>
                  <img src="/images/contactbird.png" alt="top header logo" className="c-bird-img" />
                </div>
              </div>

              <div className="drop-box">
                <h4>Drop us a Message</h4>
                {/* <p style={{fontSize: '0.7rem'}}>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    </p> */}
                <form
                  method="post"
                  data-form-title="CONTACT US"
                  onSubmit={e => this.handleContact(e)}
                >
                  <input type="text" placeholder="Name" name="name" className="input-box msg-inp" />
                  <input type="email" placeholder="Email" name="email" className="input-box msg-inp" />
                  <input type="text" placeholder="Phone" name="phone" maxLength={10} pattern="[0-9]*"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className="input-box msg-inp" />
                  <textarea type="text" rows="5" placeholder="Message" name="message" className="input-box msg-txtb" />

                  {this.state.successMsg && (
                    <p style={{ fontSize: '0.8rem', color: '#0e2d39' }}>{this.state.successMsg}</p>
                  )}

                  <button className="btn my-2 my-sm-0 nav-btn vote-btn vote-now nlink s-btn " type="submit">
                    SUBMIT
                  </button>
                </form>
              </div>

            </section>

            <section style={{ height: '100vh' }}>
              <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.209045057634!2d77.64465481461184!3d13.02235581728648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae140541846b17%3A0xb41e8103d1f540f1!2sTummoc%20(formerly%20Bykerr)!5e0!3m2!1sen!2sin!4v1633934357410!5m2!1sen!2sin" width="100%" height="100%" frameBorder="0" style={{ border: '0' }} allowFullScreen="" loading="lazy"></iframe>
            </section>

            <section style={{ position: "relative" }}>

              <section className="vote-section" style={{ position: 'absolute', top: '-10rem', width: '100%' }}>
                <div style={{ background: '#fff', borderRadius: '2rem' }}>
                  <div className="link-section">
                    <Applink />
                  </div>
                </div>
              </section>

              <Footer />
            </section>

          </div>

        </div>
      </>
    );
  }

}
