import React from "react";
import Footer from "../footer";
import Applink from "../applink";
import Header from "../header";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./home.css";
import { NavLink } from "react-router-dom";
export default class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // reportdata


    const reportdata = [
      {
        name: "Amit Kashyap",
        description: " Nice app super concept and the most important part it is very helpful to travel a short distance in very less time!",
        image: "",

      },
      {
        name: "Raju Kumar",
        description: "Quick and affordable, currently best in league!",
        image: "",
      },
      {
        name: "Lenin Kumar Gungutla",
        description: "This app & the service is supporting me a lot for my daily commute to the office and to the home with a reasonable fare. Kudos to the Bykerr team and will see this app to spread across the city to increase more!",
        image: "",
      },
      {
        name: "Manogna G",
        description: "The app is a great initiative. Handy and affordable for metro users!",
        image: "",
      },
      {
        name: "Pavithra SathyaKumar",
        description: "The app is a boon to all those people who rely on public transport. Not only do they resolve the last mile connectivity issue faced by scores of people like us who use public transport but also do it at a very reasonable price. I hope they begin services at more stations soon! ",
        image: "",
      },
      {
        name: "Shailaja G",
        description: "Good app. very reasonable and comfortable too & easy access. Drivers are also polite & their driving is also good!",
        image: "",
      },
      {
        name: "Shreelatha Bandi",
        description: "Bykerr is very nice transport option at a reasonable cost!",
        image: "",
      },
      {
        name: "Sandhya Kumari",
        description: "Faster mode of transport without waiting. Reasonable in price and quick pickups!",
        image: "",
      },
      {
        name: "Sushil Kumar",
        description: "Great riding experience, Ever I felt with any other riding apps. This my pocket-friendly app ever becomes my TravelMate during my weekdays. I loved it. In a hurry and have to go to office on time? Is ur office just 4km radiance distance & looking for any budget-friendly ride app. Then u must have to go with BYKERR!",
        image: "",
      }
    ]


    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    }


    const responsivenext = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 2,
        partialVisibilityGutter: 90
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        partialVisibilityGutter: 90
      },
      mini: {
        breakpoint: { max: 1024, min: 786 },
        items: 2,
        partialVisibilityGutter: 50
      },
      tablet: {
        breakpoint: { max: 786, min: 360 },
        items: 1,
        partialVisibilityGutter: 40
      },
      mobile: {
        breakpoint: { max: 360, min: 0 },
        items: 1
      }
    }


    const CustomDot = ({ onClick, ...rest }) => {
      const {
        onMove,
        index,
        active,
        carouselState: { currentSlide, deviceType }
      } = rest;
      const carouselItems = ["/images/m-dot.png", "/images/g-dot.png"];

      return (
        <div className="sl-dot-c">
          <img src={active ? React.Children.toArray(carouselItems)[0] : React.Children.toArray(carouselItems)[1]} alt=""
            className={active ? "active sl-dot-a1" : "inactive sl-dot-a2"}
            onClick={() => onClick()}
          />
        </div>
      );
    };


    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
      const { carouselState: { currentSlide } } = rest;
      return (
        <div className="sl-bt-ps1">
          <button onClick={() => previous()} className="pn-btn">
            <i className="fa fa-chevron-left" style={{ fontSize: "1.4rem" }} aria-hidden="true"></i>
          </button>
          <button onClick={() => next()} className="pn-btn" style={{ marginLeft: '2rem' }}>
            <i className="fa fa-chevron-right" style={{ fontSize: "1.4rem" }} aria-hidden="true"></i>
          </button>
        </div>
      );
    };


    const ButtonGroupNext = ({ next, previous, goToSlide, ...rest }) => {
      const { carouselState: { currentSlide } } = rest;
      return (
        <div className="sl-bt-ps2">
          <button onClick={() => previous()} className="pn-btn">
            <i className="fa fa-chevron-left" style={{ fontSize: "1.4rem" }} aria-hidden="true"></i>
          </button>
          <button onClick={() => next()} className="pn-btn" style={{ marginLeft: '2rem' }}>
            <i className="fa fa-chevron-right" style={{ fontSize: "1.4rem" }} aria-hidden="true"></i>
          </button>
        </div>
      );
    };


    const news = [

      // latest Dec 2023
      {
        title: 'Economic Times Auto',
        image: 'ETAuto.png',
        description: 'Tummoc partners with redBus to simplify outstation travel with one solution',
        link: 'https://auto.economictimes.indiatimes.com/news/commercial-vehicle/mhcv/tummoc-partners-with-redbus-to-simplify-outstation-travel-with-one-solution/105102841'
      },
      {
        title: 'Express Mobility',
        image: 'expressmobility.png',
        description: 'Tummoc partners redBus for end-to-end outstation transportation solution',
        link: 'https://www.financialexpress.com/business/express-mobility-tummoc-partners-redbus-for-end-to-end-outstation-transportation-solution-3302454/'
      },
      {
        title: 'The Times of India',
        image: 'timesofindia.png',
        description: '85 percent commuters think Indian public transport is inadequate: Overcrowding, maintenance among major issues',
        link: 'https://timesofindia.indiatimes.com/auto/news/85-percent-commuters-think-indian-public-transport-is-inadequate-overcrowding-maintenance-among-major-issues/articleshow/104553408.cms'
      },
      {
        title: 'Josh Talk',
        image: 'joshtalk.png',
        description: 'कब तक सिर्फ Middle Class औरतों को ये सहना पड़ेगा ? | Monalisha Thakur | Josh Talks Aasha',
        link: 'https://www.youtube.com/watch?v=LUBP3gbIsnc'
      },
      {
        title: 'Financial Express',
        image: 'financialexpress_fundraised.png',
        description: 'Tummoc raises $1 million in pre-series A round led by Inflection Point Ventures',
        link: 'https://www.financialexpress.com/business/industry-tummoc-raises-1-million-in-pre-series-a-round-led-by-inflection-point-ventures-3137656/'
      },
      {
        title: 'Tech Graph',
        image: 'techgraph.png',
        description: 'Tummoc’s Adaptability: Hiranmay Mallick On Meeting Diverse Public Transportation Needs In India',
        link: 'https://techgraph.co/interviews/tummocs-hiranmay-mallick-meeting-diverse-transportation-needs-india/'
      },
      {
        title: 'Your Story',
        image: 'yourstory_CEO.png',
        description: 'WHAT DOES THE RISE OF ALL-IN-ONE MOBILITY APPS REPRESENT?',
        link: 'https://yourstory.com/2023/08/-all-in-one-mobility-commute-digital-age'
      },
      {
        title: 'APN News',
        image: 'apnnews_bmtcpasses.png',
        description: 'Tummoc Surpasses 1.5 Million Distributed BMTC Passes, Celebrates Unprecedented Achievement',
        link: 'https://www.apnnews.com/tummoc-surpasses-1-5-million-distributed-bmtc-passes-celebrates-unprecedented-achievement/'
      },
      {
        title: 'Deccan Herald',
        image: 'deccanherald_rapido.png',
        description: 'Bengaluru: Book Rapido rides through Tummoc app',
        link: 'https://www.deccanherald.com/india/karnataka/bengaluru/bengaluru-book-rapido-rides-through-tummoc-app-1233679.html'
      },
      {
        title: 'Economic Times Auto',
        image: 'ETAuto_rapido.png',
        description: 'Tummoc partners with Rapido for first and last mile connectivity',
        link: 'https://auto.economictimes.indiatimes.com/news/aftermarket/tummoc-partners-with-rapido-for-first-and-last-mile-connectivity/101451762'
      },
      {
        title: 'CXOtoday.com',
        image: 'cxotoday.png',
        description: 'Tummoc: Revolutionizing Urban Commuting with AI-Powered Multi-Modal Solutions',
        link: 'https://cxotoday.com/interviews/tummoc-revolutionizing-urban-commuting-with-ai-powered-multi-modal-solutions/'
      },
      {
        title: 'Deccan Chronicle',
        image: 'deccanchronicle.png',
        description: 'Reimagining Urban Mobility: Role of Public Transport in Reducing Carbon Footprint',
        link: 'https://www.deccanchronicle.com/nation/in-other-news/240623/reimagining-urban-mobility-role-of-public-transport-in-reducing-carbo.html'
      },
      {
        title: 'ET Travel World',
        image: 'ETtravelworld.png',
        description: 'The impact of transit apps on sustainable tourism: Opportunities & challenge',
        link: 'https://travel.economictimes.indiatimes.com/news/people/the-impact-of-transit-apps-on-sustainable-tourism-opportunities-challenges/100065850'
      },
      {
        title: 'Times Drive',
        image: 'timesdrive.png',
        description: 'This smartphone application can live-track DTC buses for you',
        link: 'https://www.timesnownews.com/auto/this-smartphone-application-can-live-track-dtc-buses-for-you-article-98444006'
      },
      {
        title: 'Economic Times Auto',
        image: 'ETAuto_livetracking.png',
        description: 'Tummoc launches live-tracking feature for DTC buses',
        link: 'https://auto.economictimes.indiatimes.com/news/commercial-vehicle/tummoc-launches-live-tracking-feature-for-dtc-buses/98444924'
      },
      {
        title: 'Financial Express Mobility',
        image: 'livetracking_delhi.png',
        description: 'Tummoc introduces live tracking feature for Delhi Transport Corporation buses',
        link: 'https://www.financialexpress.com/business/express-mobility-tummoc-introduces-live-tracking-feature-for-delhi-transport-corporation-buses-3001031/'
      },
      {
        title: 'BW Auto World',
        image: 'bwautoworld_100Cities.png',
        description: 'Tummoc Plans To Expand Its Footprint In 100 Cities By FY24',
        link: 'https://bwautoworld.businessworld.in/article/Tummoc-Plans-To-Expand-Its-Footprint-In-100-Cities-By-FY24/22-03-2023-470075/'
      },
      // {
      //   title: 'Financial Express Mobility',
      //   image: '2million_downloads.png',
      //   description: 'Tummoc, transit app, reaches 2 million downloads',
      //   link: 'https://www.financialexpress.com/business/express-mobility-tummoc-transit-app-reaches-2-million-downloads-2955287/'
      // },
      
      // latest feb 2023
      {
        title: 'BW Auto World',
        image: 'bwautoworld.png',
        description: 'Tech Is Making Public Transport Easy, Says Tummoc CEO',
        link: 'http://bwautoworld.businessworld.in/article/Tech-Is-Making-Public-Transport-Easy-Says-Tummoc-CEO/03-12-2022-456573/'
      },
      {
        title: 'Travel Economic Times',
        image: 'travel-transport.png',
        description: 'Buses are most preferred mode of transport for 30% women, reveals survey',
        link: 'https://travel.economictimes.indiatimes.com/news/research-and-statistics/figures/buses-are-most-preferred-mode-of-transport-for-30-women-reveals-survey/96421439'
      },
      {
        title: 'Financial Express',
        image: 'urban-mobility.png',
        description: 'Trends in the urban mobility sector that will bloom in 2023',
        link: 'https://www.financialexpress.com/express-mobility/trends-in-the-urban-mobility-sector-that-will-bloom-in-2023/2932854/'
      },
      {
        title: 'Business News Week',
        image: 'auto-sector.png',
        description: 'Expectation of Auto Sector from Budget 2023',
        link: 'https://businessnewsweek.in/business/expectation-of-auto-sector-from-budget-2023/'
      },
      {
        title: 'BW Auto World',
        image: 'bwauto-multimodal.png',
        description: 'Tummoc\'s Multi-Modal Transit App Reaches 2 Million Downloads',
        link: 'http://bwautoworld.businessworld.in/article/Tummoc-s-Multi-Modal-Transit-App-Reaches-2-Million-Downloads/20-01-2023-462718/'
      },
      {
        title: 'Financial Express',
        image: 'financialexpress-tummoc.png',
        description: 'Tummoc, transit app, reaches 2 million downloads',
        link: 'https://www.financialexpress.com/express-mobility/tummoc-transit-app-reaches-2-million-downloads/2955287/'
      },
      {
        title: 'EV STORY',
        image: 'evstory-tech.png',
        description: 'Sustainable Startups Transforming The EV Tech Industry',
        link: 'https://www.evstory.in/sustainable-startups-transforming-the-tech-industry/'
      },
      {
        title: 'Sakshi POST',
        image: 'sakshi-post-tech.png',
        description: 'Technology Brands Hitting The Right Goal',
        link: 'https://english.sakshi.com/news/technology/technology-brands-hitting-right-goal-168307'
      },
      {
        title: 'Life and More',
        image: 'unionbudget.png',
        description: 'CAIT terms Union Budget 2023-24 as a ‘Progressive Economic Document’',
        link: 'https://lifeandmore.in/more/business-industry/progressive-economic-document/'
      },

      // latest-12 dec 2022
      {
        title: 'Financial Express',
        image: 'local-transport.png',
        description: 'Digital transport industry is the new ‘Vocal for Local',
        link: 'https://www.financialexpress.com/lifestyle/travel-tourism/digital-transport-industry-is-the-new-vocal-for-local/2688282/'
      },
      {
        title: 'Times of India',
        image: 'multimodal-mobility.png',
        description: 'Multimodal mobility: Pedaling towards a greener future',
        link: 'https://timesofindia.indiatimes.com/blogs/voices/multimodal-mobility-pedaling-towards-a-greener-future/'
      },
      {
        title: 'Forbes',
        image: 'forbes.png',
        description: 'Every Leader Can Benefit From Coaching. Here’s Why',
        link: 'https://www.forbes.com/sites/benjaminlaker/2022/10/04/every-leader-can-benefit-from-coaching-heres-why/?sh=7bb5787e7e1f'
      },
      {
        title: 'Deccan Herald',
        image: 'tummoc-kannada.png',
        description: 'Tummoc app in Kannada',
        link: 'https://www.deccanherald.com/amp/city/tummoc-app-in-kannada-1154545.html'
      },
      {
        title: 'Bangalore Mirror',
        image: 'tummoc-kannada.png',
        description: 'BMTC TO INTRODUCE E-TICKETS',
        link: 'https://bangaloremirror.indiatimes.com/bangalore/civic/bmtc-to-introduce-e-tickets/amp_articleshow/95019323.cms'
      },
      {
        title: 'sakshi',
        image: 'sakshi.png',
        description: 'Making Commute Easier and Simpler, Tummoc Launches Chatbot Support',
        link: 'https://menglish.sakshi.com/amp/news/business/making-commute-easier-and-simpler-tummoc-launches-chatbot-support-164438'
      },

      // latest
      {
        title: 'Analytics Insight',
        image: 'narayan.jpg',
        description: 'EXCLUSIVE INTERVIEW WITH NARAYAN MISHRA, CO-FOUNDER & CTO AT TUMMOC',
        link: 'https://www.analyticsinsight.net/exclusive-interview-with-narayan-mishra-co-founder-cto-at-tummoc/'
      },
      {
        title: 'Tech Podcast Series',
        image: 'narayan2.png',
        description: 'Tech Podcast Series | Narayan Mishra',
        link: 'https://www.youtube.com/watch?v=LbDM7SkPjHg&list=PLVQFoklbAYzYhvOfWxSZrpFok0tAZYI0w&index=46'
      },
      {
        title: 'The Hindu',
        image: 'n5.jpg',
        description: 'App makes it easy to buy BMTC daily, weekly and monthly passes',
        link: 'https://www.thehindu.com/news/cities/bangalore/bmtcs-digital-passes-generate-25-crore-revenue-within-20-days-of-july/article65667232.ece'
      },
      {
        title: 'Vijay Karnataka',
        image: 'n5.jpg',
        description: "ಬಿಎಂಟಿಸಿಗೆ 'ಟುಮೋಕ್‌' ಟಾನಿಕ್‌, ಡಿಜಿಟಲ್‌ ಆ್ಯಪ್‌ನಿಂದ 24 ದಿನಗಳಲ್ಲೇ ₹2.61 ಕೋಟಿ ಆದಾಯ",
        link: 'https://vijaykarnataka.com/business/news/bmtc-tummoc-digital-bus-passes-a-hit-app-earns-rs-2-61-crore-in-24-days-of-july/articleshow/93150408.cms'
      },
      {
        title: 'APN News',
        image: 'flexi-pass.jpg',
        description: 'Multi Modal Transit App Tummoc Launches Flexi Pass for Hassle Free Travel',
        link: 'https://www.apnnews.com/multi-modal-transit-app-tummoc-launches-flexi-pass-for-hassle-free-travel/'
      },
      {
        title: 'Media Bulletins',
        image: '1M-Download.jpg',
        description: 'Tummoc reaches 1 million downloads',
        link: 'http://mediabulletins.com/business/multi-modal-transit-app/'
      },
      {
        title: 'The Hans India',
        image: '1M-Download.jpg',
        description: 'Multi Modal Transit App, Tummoc reaches 1 million downloads',
        link: 'https://www.thehansindia.com/amp/technology/tech-news/multi-modal-transit-app-tummoc-reaches-1-million-downloads-754004'
      },
      // new 
      {
        title: 'ANI News',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://www.aninews.in/news/business/business/tummoc-the-mobility-startup-eying-to-launch-in-10-more-cities20220308150238/'
      },
      {
        title: 'Business Standard',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://www.business-standard.com/content/press-releases-ani/tummoc-the-mobility-startup-eying-to-launch-in-10-more-cities-122030801087_1.html#:~:text=10%20more%20cities-,New%20Delhi%20%5BIndia%5D%2C%20March%208%20(ANI%2FSRV,Bengaluru%2C%20Kolkata%2C%20and%20Hyderabad.'
      },
      {
        title: 'Daily Hunt',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://m.dailyhunt.in/news/india/english/ani67917250816496966-epaper-anieng/tummoc+the+mobility+startup+eying+to+launch+in+10+more+cities-newsid-n366165834?listname=newspaperLanding&index=68&topicIndex=0&mode=pwa&action=click'
      },
      {
        title: 'Zee 5',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://www.zee5.com/articles/tummoc-the-mobility-startup-eying-to-launch-in-10-more-cities'
      },
      {
        title: 'Print',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://theprint.in/ani-press-releases/tummoc-the-mobility-startup-eying-to-launch-in-10-more-cities/863738/'
      },
      {
        title: 'Hans India',
        image: 'n2.jpg',
        description: "International Women's Day 2022 Live Updates: Women Tech Leaders on 'gender equality today for a sustainable tomorrow' ",
        link: 'https://www.thehansindia.com/amp/live-updates/international-womens-day-2022-live-updates-women-tech-leaders-on-gender-equality-today-for-a-sustainable-tomorrow-732423'
      },
      {
        title: 'India TV',
        image: 'n3.jpg',
        description: "International Women's Day: Women's role in socio-economic development of a country",
        link: 'https://www.indiatvnews.com/amp/news/india/international-women-s-day-women-s-role-in-socio-economic-development-of-a-country-2022-03-08-763195'
      },
      {
        title: 'Christine Twitter',
        image: 'n4.jpg',
        description: "Soon, avail @BMTC_BENGALURU bus passes through @tummoc4u app",
        link: 'https://twitter.com/ChristinMP_TOI/status/1501853160209469446?t=pSLGENsJ3Pyyt4SPIaUGhQ&s=19'
      },
      {
        title: 'BMTC Twitter',
        image: 'n4.jpg',
        description: "Tummoc @BMTC_BENGALURU",
        link: 'https://twitter.com/BMTC_BENGALURU/status/1502103305048436740?t=Qsge3G_5JzhqAnxsx5ZiMw&s=19'
      },
      {
        title: 'The Hindu',
        image: 'n4.jpg',
        description: "Cashless payment options for BMTC passes",
        link: 'https://www.thehindu.com/news/national/karnataka/cashless-payment-options-for-bmtc-passes/article65212221.ece'
      },
      {
        title: 'Survarna - Dailyhunt',
        image: 'n5.webp',
        description: "ಇನ್ಮುಂದೆ ಮೊಬೈಲ್‌ ಆಯಪಲ್ಲೇ BMTC ಬಸ್‌ ಪಾಸ್‌..!",
        link: 'https://m.dailyhunt.in/news/india/kannada/suvarnanews+tv-epaper-suvarna/inmunde+mobail+aayapalle+bmtc+bas+paas-newsid-n366962986?s=a&ss=wsp'
      },
      {
        title: 'Asia Net News',
        image: 'n5.webp',
        description: "ಇನ್ಮುಂದೆ ಮೊಬೈಲ್‌ ಆ್ಯಪಲ್ಲೇ BMTC ಬಸ್‌ ಪಾಸ್‌..!",
        link: 'https://kannada.asianetnews.com/business/bmtc-bus-pass-is-now-available-on-mobile-app-grg-r8k1sy'
      },
      {
        title: 'Times of India',
        image: 'n6.webp',
        description: "Bengaluru: Use mobile app to buy BMTC bus passes",
        link: 'https://timesofindia.indiatimes.com/city/bengaluru/soon-bengalurueans-to-buy-bmtc-bus-passes-through-mobile-app/articleshow/90145697.cms'
      },
      {
        title: 'Free Press Journal',
        image: 'n7.webp',
        description: "Mobility startup Tummoc plans to establish operations in 10 more cities by end of 2022",
        link: 'https://www.freepressjournal.in/business/mobility-startup-tummoc-plans-to-establish-operations-in-10-more-cities-by-end-of-2022'
      },
      {
        title: 'Press Headliner',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://pressheadliner.com/tummoc-the-mobility-startup-eying-to-launch-in-10-more-cities/'
      },
      {
        title: 'India Times',
        image: 'n1.jpg',
        description: 'All things pass',
        link: 'https://bangaloremirror.indiatimes.com/bangalore/cover-story/all-things-pass/articleshow/90158274.cms'
      },
      {
        title: 'Hindustan Times',
        image: 'n8.webp',
        description: "Bengaluru city bus pass will now be available online, here's how you can get one",
        link: 'https://www.hindustantimes.com/cities/bengaluru-news/bengaluru-city-bus-pass-will-now-be-available-online-here-s-how-you-can-get-one-101646993732808-amp.html'
      },


      // old
      {
        title: 'Deccan Herald',
        image: 'n-new1.jpg',
        description: 'This app combines public transport with first and last-mile commute',
        link: 'https://www.deccanherald.com/city/this-app-combines-public-transport-with-first-and-last-mile-commute-997936.html'
      },
      {
        title: 'Business Standard',
        image: 'n-new2.jpg',
        description: 'Track the most economical and easiest way to reach your destination through Tummoc App',
        link: 'https://www.business-standard.com/content/press-releases-ani/track-the-most-economical-and-easiest-way-to-reach-your-destination-through-tummoc-app-121082501241_1.html'
      },
      {
        title: 'Your Story',
        image: 'n-new3.png',
        description: '[Funding alert] Bengaluru-based Tummoc raises $540K in seed round',
        link: 'https://yourstory.com/2021/09/funding-bengaluru-based-mobility-startup-tummoc/amp'
      },
      {
        title: 'Most Popular Stories',
        image: 'n-new4.jpg',
        description: 'Interview with Hiranmay Mallick | Founder of Tummoc (formerly Bykerr)',
        link: 'https://mostpopularstories.com/tummoc/'
      },
      //new card end
      {
        title: 'The New Indian Express',
        image: '1.png',
        description: 'To and Fro, last mile connectivity made easy',
        link: 'https://www.newindianexpress.com/cities/bengaluru/2017/jul/19/to-and-fro-last-mile-connectivity-made-easy-1630882.html'
      },
      {
        title: 'Your Story',
        image: '2.png',
        description: 'Hassled by last-mile connectivity problems, couple puts Bykerr on the road',
        link: 'https://yourstory.com/2017/12/hassled-last-mile-connectivity-problems-couple-puts-bykerr-road/'
      },
      {
        title: 'Deccan Herald',
        image: '3.png',
        description: 'App enables shared bike rides to reach Metro, bus stations',
        link: 'https://www.deccanherald.com/content/646762/app-enables-shared-bike-rides.html'
      },
      {
        title: 'Times of India',
        image: '4.png',
        description: 'BMTC plans to get commuters to bike or pedal to bus terminals',
        link: 'https://timesofindia.indiatimes.com/city/bengaluru/bmtc-plans-to-get-commuters-to-bike-or-pedal-to-bus-terminals/articleshow/62911316.cms'
      },
      {
        title: 'Times of India',
        image: '5.png',
        description: 'KIA passengers can seal last-mile connectivity on Vayu Vajra buses',
        link: 'https://timesofindia.indiatimes.com/city/bengaluru/kia-passengers-can-seal-last-mile-connectivity-on-vayu-vajra-buses/articleshow/65371269.cms'
      },
      {
        title: 'Times of India',
        image: '6.png',
        description: 'STAMP challenge: 3 start-ups selected for Metro connectivity',
        link: 'https://timesofindia.indiatimes.com/city/hyderabad/stamp-challenge-3-start-ups-selected-for-metro-connectivity/articleshow/65371182.cms'
      },
      {
        title: 'Your Story',
        image: '7.png',
        description: 'Karnataka selects 77 startups for funding at Elevate 2018',
        link: 'https://yourstory.com/2018/09/karnataka-selects-77-startups-funding-elevate-2018'
      },
      {
        title: 'ET Now',
        image: '8.png',
        description: 'Solutions that Impact everyday Life',
        link: 'https://youtu.be/DcyNIPgbPfw?t=986'
      },
      {
        title: 'Most Popular Stories',
        image: '9.png',
        description: 'Interview with Hiranmay Mallick | Founder of Tummoc (formerly Bykerr)',
        link: 'https://mostpopularstories.com/tummoc/'
      }
    ];


    return (
      <>
        <Helmet>
          <title>Tummoc: Your Daily Travel Buddy in Delhi and Bengaluru</title>
          <link rel="canonical" href="https://tummoc.com/" />
          <meta name="title" content="Tummoc: Your Daily Travel Buddy in Delhi and Bengaluru" />
          <meta name="description" content="Use Tummoc to Make Your Daily Travel Easier. Book Daily, Weekly and Monthly BMTC Passes Online | Book DTC Tickets Online | Live Tracking of DTC Buses & More..." />
        </Helmet>
        <div>
          <Header />

          <div className="top">

            <section>
              <div className="sectionOne">
                <div className="row v-sec" style={{ margin: 0 }}>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 headtext v-sec-1">
                    <p className="head1 headb">India’s first multi-modal public transport app</p>
                    <br></br>
                    <p className="head2">
                      Tummoc is the first Made-in-India transit application that provides accurate real-time public transport information, easy ticketing options and last-mile connectivity.
                    </p>

                    <div>
                      <a href="https://0x92l.app.link/mql2Jm1fqvb" rel="noreferrer" target="_blank">
                        <img src="/images/googleplay.png" alt="top header logo" className="appheaderlink" />
                      </a>

                      <a href="https://0x92l.app.link/mql2Jm1fqvb" rel="noreferrer" target="_blank">
                        <img src="/images/appstore.png" alt="top header logo" className="appheaderlink" />
                      </a>
                    </div>

                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 v-sec-2" style={{ textAlign: 'center' }}>
                    <img src="/images/header1.png" alt="top header logo" className="header1-img" />
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="slider-section">

                <Carousel
                  additionalTransfrom={0}
                  arrows={false}
                  draggable
                  autoPlay
                  autoPlaySpeed={30000}
                  focusOnSelect={false}
                  infinite
                  keyBoardControl
                  minimumTouchDrag={80}
                  responsive={responsive}
                  showDots
                  renderDotsOutside={false}
                  customDot={<CustomDot />}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                >
                  <div>
                    <img src="/images/carousel1.png" alt="slider img1" className="slider-bgimgs" />

                    <div className="slider-txt">
                      <h1 className="sl-h1 headb">Why Tummoc?</h1>
                      <h4 className="sl-h4">Smart Commute</h4>
                      <p className="sl-p">
                        Access actionable information for various modes of public transport, enjoy easy ticketing and pass booking options, and book rides for your first and last mile. Soon, you will also be able to enjoy single-source tickets across multiple modes of transport.
                      </p>
                    </div>

                    <div className="slider-img">
                      <img src="/images/smart.png" alt="slider img1" className="sl-img" />
                    </div>
                  </div>


                  <div>
                    <img src="/images/carousel3.png" alt="slider img3" className="slider-bgimgs" />

                    <div className="slider-txt">
                      <h1 className="sl-h1 headb">Why Tummoc?</h1>
                      <h4 className="sl-h4">Affordable Commute</h4>
                      <p className="sl-p">
                        Tummoc provides the most affordable,
                        safe and hassle-free end-to-end commute options by
                        incorporating the public transport network and providing first
                        and last-mile connectivity.
                      </p>
                    </div>

                    <div className="slider-img">
                      <img src="/images/affordable.png" alt="slider img1" className="sl-img" />
                    </div>
                  </div>

                  <div>
                    <img src="/images/carousel2.png" alt="slider img2" className="slider-bgimgs" />

                    <div className="slider-txt">
                      <h1 className="sl-h1 headb">Why Tummoc?</h1>
                      <h4 className="sl-h4">Master of All Modes</h4>
                      <p className="sl-p">
                        In addition to all modes of public
                        transport, Tummoc offers first and last-mile options like electric
                        vehicles, cycles, autos, bikes, cabs and more.
                      </p>
                    </div>

                    <div className="slider-img">
                      <img src="/images/master.png" alt="slider img1" className="sl-img" />
                    </div>
                  </div>

                  <div>
                    <img src="/images/carousel4.png" alt="slider img4" className="slider-bgimgs" />

                    <div className="slider-txt">
                      <h1 className="sl-h1 headb">Why Tummoc?</h1>
                      <h4 className="sl-h4">Green commute</h4>
                      <p className="sl-p">
                        Tummoc is working towards a greener
                        approach to commuting in India with initiatives like a gamified
                        carbon emission tracker, tree-planting drives, and more to come.
                      </p>
                    </div>

                    <div className="slider-img">
                      <img src="/images/green.png" alt="slider img1" className="sl-img" />
                    </div>
                  </div>

                </Carousel>
              </div>
            </section>

            <section>
              <div className="sectionTwo">
                <div className="row v-sec" style={{ margin: 0 }}>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 impacttext v-sec-1">

                    <div className="impacttext1">
                      <p className="head1 headb">Tummoc Impact</p>
                      <p className="head2">
                        Tummoc is the one stop solution to all the hassles of regular transportation options &
                        the struggle to find reasonably priced short distance rides. We started in 2016 and the
                        impact we have had on the lives of our users and their environment inspires us every day.
                      </p>
                    </div>

                    <div className="row" style={{ margin: 0, marginTop: "3rem" }}>

                      {/* <div className="col-lg-4 col-sm-12 white-box box1">
                              <p className="txt-grad p1"> 20 </p>
                              <p className="txt-grad p1" style={{marginTop: "-2rem"}}> 16 </p>
                              <p className="p4">FOUNDING <br/> YEAR</p>
                            </div> */}

                      {/* <div className="col-lg-12 col-sm-12 row" style={{paddingLeft: "1.5rem"}}> */}

                      {/* 1,70,000+
Happy Commuters
10,000+
Commute & Last-Mile Partners
30,000+
Hassle-Free Trips Completed
1000+
Trees Planted So Far
2.5 million trips planned */}

                      <div className="col-lg-4 col-md-4 col-sm-6 col-6" style={{ padding: 0 }}>
                        <div className="white-box box2">
                          <p className="txt-grad p2 headb">2 Million+</p>
                          {/* <p style={{ margin: 0 }}> gms </p> */}
                          <p className="p4">Happy Commuters</p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-6" style={{ padding: 0 }}>
                        <div className="white-box box2">
                          <p className="txt-grad p2 headb">5 Million+</p>
                          <p className="p4">Trips Planned</p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-6" style={{ padding: 0 }}>
                        <div className="white-box box2">
                          <p className="txt-grad p2 headb">1 Million+</p>
                          <p className="p4">BMTC Pass Booked</p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-6" style={{ padding: 0 }}>
                        <div className="white-box box2">
                          <p className="txt-grad p2 headb">10,000+</p>
                          <p className="p4">Commute & Last-Mile Partners</p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-6" style={{ padding: 0 }}>
                        <div className="white-box box2">
                          <p className="txt-grad p2 headb">1000+</p>
                          <p className="p4">Trees Planted So Far</p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-6" style={{ padding: 0 }}>
                        <div className="white-box box2">
                          <p className="txt-grad p2 headb"> 33,96,323 </p>
                          <p className="p4">Grams Carbon Saved </p>
                        </div>
                      </div>

                      {/* </div> */}

                    </div>

                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 impact-img v-sec-2">
                    <img src="/images/header2.png" alt="top header logo" className="header1-img headerimapactimg" />
                  </div>
                </div>
              </div>
            </section>


            <Carousel
              additionalTransfrom={0}
              arrows={false}
              customButtonGroup={<ButtonGroup />}
              draggable
              autoPlay
              autoPlaySpeed={30000}
              focusOnSelect={false}
              infinite
              keyBoardControl
              minimumTouchDrag={80}
              responsive={responsive}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {
                (reportdata) && reportdata.map((item, i) => {
                  return <section className="section-report">
                    <div className="sectionThree">
                      <div className="row" style={{ margin: 0 }}>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 testimonial">
                          <p className="testi1 headb">Our Report Card</p>
                          <p className="testi2">
                            {item.description}
                            <br />

                            <p style={{ fontSize: "13px", marginTop: "2rem" }}>
                              <b>{item.name}</b>
                            </p>
                          </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 balloon">
                          <div class="greenBaloon">
                            <svg width="113" height="139" viewBox="0 0 113 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M112.967 55.3172C112.986 65.1441 110.314 74.7962 105.23 83.2688C105.225 83.2879 105.215 83.3054 105.202 83.3196L100.158 90.3964L69.2003 133.818H68.7285C67.1551 135.454 65.2567 136.758 63.1496 137.649C61.0426 138.54 58.7715 139 56.4763 139C54.1812 139 51.9103 138.54 49.8033 137.649C47.6963 136.758 45.7978 135.454 44.2244 133.818L43.9413 133.513L41.7193 130.451L11.5962 88.9044L9.23734 85.6385C9.22364 85.626 9.2138 85.61 9.20901 85.5923C4.39502 78.3823 1.36723 70.1711 0.366639 61.613C-0.633954 53.0548 0.42032 44.3861 3.44563 36.2974C6.47094 28.2087 11.3837 20.9234 17.7925 15.022C24.2012 9.12062 31.9286 4.76609 40.3597 2.30532C48.7907 -0.155445 57.6921 -0.654459 66.3549 0.848032C75.0176 2.35052 83.2025 5.813 90.2572 10.9597C97.312 16.1063 103.042 22.7949 106.99 30.4927C110.939 38.1905 112.996 46.6847 113 55.2987L112.967 55.3172Z" fill="#98C44F" />
                            </svg>
                          </div>

                          <div class="myClip">
                            <svg width="270" height="341" viewBox="0 0 270 341" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <clipPath id="clipPath">
                                <path d="M269.921 135.706C269.967 159.814 263.583 183.493 251.434 204.278C251.423 204.325 251.4 204.368 251.367 204.403L239.316 221.764L165.346 328.288H164.219C160.459 332.302 155.923 335.5 150.889 337.686C145.854 339.872 140.428 341 134.943 341C129.459 341 124.033 339.872 118.999 337.686C113.965 335.5 109.428 332.302 105.669 328.288L104.992 327.54L99.6832 320.026L27.7078 218.104L22.0715 210.092C22.0388 210.061 22.0153 210.022 22.0038 209.978V209.978C10.5014 192.29 3.26684 172.146 0.87604 151.151C-1.51476 130.156 1.0043 108.89 8.23292 89.0461C15.4615 69.2026 27.2001 51.3302 42.5129 36.8526C57.8258 22.375 76.2897 11.6924 96.4346 5.65551C116.58 -0.381344 137.848 -1.60554 158.547 2.08042C179.246 5.76639 198.802 14.2607 215.659 26.8866C232.515 39.5126 246.206 55.9213 255.64 74.8058C265.074 93.6903 269.992 114.529 270 135.661L269.921 135.706Z" fill="#98C44F" />
                              </clipPath>
                            </svg>
                          </div>

                          <div class="yellowBaloon">
                            <svg width="157" height="198" viewBox="0 0 157 198" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M156.954 78.7972C156.981 92.7952 153.269 106.544 146.204 118.613C146.198 118.64 146.184 118.665 146.165 118.685L139.158 128.766L96.1455 190.619H95.49C93.304 192.949 90.6663 194.806 87.7389 196.076C84.8114 197.345 81.656 198 78.4671 198C75.2783 198 72.1231 197.345 69.1957 196.076C66.2683 194.806 63.6306 192.949 61.4445 190.619L61.0512 190.184L57.9639 185.822L16.1116 126.641L12.8342 121.989C12.8151 121.971 12.8015 121.948 12.7948 121.923C6.10635 111.652 1.8996 99.956 0.509401 87.7653C-0.880803 75.5745 0.583984 63.2263 4.78729 51.7042C8.9906 40.1821 15.8164 29.8046 24.7205 21.3983C33.6246 12.992 44.361 6.78911 56.0749 3.28384C67.7888 -0.221426 80.1563 -0.932251 92.1922 1.20799C104.228 3.34823 115.6 8.28038 125.402 15.6116C135.203 22.9428 143.164 32.4705 148.65 43.4356C154.136 54.4008 156.995 66.5005 157 78.7709L156.954 78.7972Z" fill="#FFCC05" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>

                  </section>
                })
              }
            </Carousel>

            <section className="vote-section">
              <div className="sectionFour">
                <div className="row v-sec" style={{ margin: 0, position: 'relative' }}>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 vote-txt v-sec-1">

                    <p className="txt-grad p3 headb">You decide where we go next</p>

                    <p className="vote-txt2">
                      After all, we’re building a smarter commute for YOU. So, tell us which cities you’d like us to bring Tummoc to!
                    </p>

                    <button className="btn my-2 my-sm-0 nav-btn vote-btn vote-now" type="button">
                      <NavLink className="nlink" to="/vote-for-city">VOTE NOW</NavLink>
                      {/* <NavLink className="nlink" to="/">COMING SOON</NavLink> */}
                    </button>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 vote-now-img v-sec-2" style={{ textAlign: 'center' }}>
                    <img src="/images/vote.png" alt="top header logo" className="vote-img" />
                  </div>
                </div>
              </div>
            </section>

            <section className="vote-section">

              <div className="row v-sec" style={{ margin: 0 }}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 vote-txt">

                  <p className="txt-grad p3 headb" style={{ textAlign: 'center' }}>Our Partners and Affiliations</p>

                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 " style={{ textAlign: 'center' }}>
                  <img src="/images/partners.png" alt="top header logo" style={{ width: '100%' }} />
                </div>
              </div>

            </section>

            {/* <section style={{ position: 'relative', marginTop: "10rem" }}> */}
            <section style={{ position: 'relative', marginTop: "3rem" }}>

              {/* <section className="newsletter-section">
                <div className="sectionFive">
                  <div className="row" style={{ margin: 0 }}>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <p className="newsletter-head">Subscribe to our newsletter</p>
                      <p className="newsletter-txt">
                        We are more than problem solvers. We are commute enthusiasts.
                        Our blogs cover all aspects of the Tummoc experience, commute trends &
                        solutions, and a unique take on public transport.
                      </p>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 subscribe">
                      <input type="email" placeholder="Enter your email address" className="input-box" />
                      <button className="btn my-2 my-sm-0 nav-btn vote-btn nlink subscribe-btn" type="submit">
                        SUBSCRIBE
                      </button>
                    </div>

                  </div>
                </div>
              </section> */}

              <div className="sectionSix">
                <div className="row new-c">
                  <div className="new-c1">
                    <p className="news-head headb">The Word on the Street</p>
                    <p className="news-txt">
                      Our journey has been eventful to say the least. With every challenge we faced,
                      our belief in Tummoc as a service tailored for India pushed us to come out
                      stronger. While we were focused on getting here, the world took notice.
                    </p>
                  </div>
                  <div className="new-c2">

                    <Carousel
                      additionalTransfrom={0}
                      arrows={false}
                      customButtonGroup={<ButtonGroupNext />}
                      renderButtonGroupOutside={true}
                      draggable
                      autoPlay
                      autoPlaySpeed={30000}
                      focusOnSelect={false}
                      infinite
                      keyBoardControl
                      minimumTouchDrag={80}
                      responsive={responsivenext}
                      showDots={false}
                      partialVisible={true}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                    >
                      {/* <div class="card news-card">
                        <img src="/images/1.png" class="card-img news-img" style={{ borderRadius: "1rem" }} alt="newsimage" />
                        <div class="card-body">
                          <p class="card-text">To and Fro, last mile connectivity made easy</p>
                          <h6 class="card-title">The New Indian Express <i class="fa fa-arrow-right"></i> </h6>
                        </div>
                      </div> */}

                      {news && news.map((item, i) => {
                        return <div class="card news-card">
                          <a href={item.link} target="_blank" rel="noreferrer">
                            <img src={`/images/news/${item.image}`} class="card-img news-img" style={{ borderRadius: "1rem" }} alt="newsimage" />
                          </a>
                          <div class="card-body">
                            <p class="card-text">{item.description}</p>
                            <a
                              href={item.link}
                              target="_blank" rel="noreferrer"
                            >   <h6 class="card-title"> {item.title} <i class="fa fa-arrow-right"></i> </h6>
                            </a>
                          </div>
                        </div>
                      })
                      }

                    </Carousel>
                  </div>
                </div>
              </div>
            </section>

            <section style={{ position: "relative" }}>

              <section className="vote-section" style={{ position: 'absolute', top: '-10rem', width: '100%' }}>
                <div style={{ background: '#fff', borderRadius: '2rem' }}>
                  <div className="link-section">
                    <Applink />
                  </div>
                </div>
              </section>

              <Footer />
            </section>

          </div>

        </div>
      </>
    );
  }

}
