import React from "react";
import { NavLink } from "react-router-dom";
export default class Footer extends React.Component {

  render() {
    return (
      <>
        <div className="footer-bg">
          <div className="row foot" style={{ margin: 0 }}>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 footer-sec1">
              <NavLink to="/">
                <img src="/images/logoHeader.png" alt="tummoc logo" />
              </NavLink>

              <p style={{ margin: '1rem 0' }}>
                No. 5 Maruthi Complex, GF, 9th'B Main,<br />
                Dodda Banaswadi Main Rd, Kalyan Nagar,<br />
                Bengaluru, Karnataka 560043<br />
              </p>

              <a href="mailto:support@tummoc.com" style={{ color: '#fff' }}> support@tummoc.com </a>

            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 footer-sec2">
              <div className="row" style={{ margin: 0 }}>
                {/* <div className="col-lg-3">
                  <ul>
                    <li>
                      <NavLink to="/" style={{ color: '#fff' }}>
                        Partners
                      </NavLink>
                    </li>
                     <li>
                      <NavLink to="/drive-with-us" style={{color: '#fff'}}>
                       Drive with us
                      </NavLink>
                      </li> 
                    <li>
                      <NavLink to="/careers" style={{ color: '#fff' }}>
                        Work with us
                      </NavLink>
                    </li>
                  </ul>
                </div> */}

                <div className="col-lg-4">
                  <ul>
                    <li>
                      <NavLink to="/vote-for-city" style={{ color: '#fff' }}>
                        Our Cities
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/media" style={{ color: '#fff' }}>
                        Media
                      </NavLink>
                    </li>
                    <li>
                      <a style={{ color: '#fff' }} href="https://blog.tummoc.com/" target="_blank">Blog</a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4">
                  <ul>
                    <li>
                      <NavLink to="/contact" style={{ color: '#fff' }}>
                        FAQs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/careers" style={{ color: '#fff' }}>
                        Career
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/privacy-policy" style={{ color: '#fff' }}>
                        Privacy Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/terms-of-usage" style={{ color: '#fff' }}>
                        Terms of Usage
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/cancellation-and-refund-policy" style={{ color: '#fff' }}>
                        Cancellation & Refund Policy
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row" style={{ margin: 0, marginTop: '2rem' }}>
                <div className="col-lg-6">
                  <p style={{ letterSpacing: '0.8rem', fontSize: '1.5rem' }}>
                    <a style={{ color: 'white', marginRight: '5px' }} href="https://www.facebook.com/tummoc4u/" target="_blank" rel="nofollow"><i class="fab fa-facebook-f"></i></a>
                    <a style={{ color: 'white', marginRight: '5px' }} href="https://twitter.com/tummoc4u" target="_blank" rel="nofollow"><i class="fab fa-twitter"></i></a>
                    <a style={{ color: 'white', marginRight: '5px' }} href="https://www.instagram.com/tummoc/?hl=en" target="_blank" rel="nofollow"><i class="fab fa-instagram" ></i></a>
                    <a style={{ color: 'white', marginRight: '5px' }} href="https://www.linkedin.com/company/tummoc/" target="_blank" rel="nofollow"><i class="fab fa-linkedin"></i></a>
                    <a style={{ color: 'white' }} href="https://www.youtube.com/channel/UCTmmasfzCjyQyK3SWpVZMZA" target="_blank" rel="nofollow"><i class="fab fa-youtube"></i></a>
                  </p>
                </div>
                <div className="col-lg-6" style={{ fontSize: '0.7rem', paddingTop: '0.6rem' }}>
                  <p>Copyrights 2022 @Transhelp Technologies Pvt Ltd</p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </>
    );
  }

}
