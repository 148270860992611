import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../header'
import Step1 from "../../assets/ktcl_01.png"
import Step2 from "../../assets/ktcl_02.png"
import Step3 from "../../assets/ktcl_03.png"
import Step4 from "../../assets/ktcl_04.png"
import ThinkTummoc from "../../assets/chiya_think_tummoc_center.png"


import "../dtcTicketHowToUse/dtcTicketHowToUse.css"
import Idea from "../../assets/idea.png"
function ktclTicketHowToUse() {
    return (
        <>
            <Helmet>
                <title>Tummoc | How to Use DTC Bus Ticket</title>
                <link rel="canonical" href="https://tummoc.com/dtc-ticket-how-to-use" />
            </Helmet>
            <div className='how_to_use_parent_div' >
                <section className='how_to_use_inner_div'>
                    <div className='step_text_div'>
                        <span className='step_text'>Step 1: Activate Ticket</span>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                        <img src={Step1} style={{ width: "280px", height: "320px" }} />
                    </div>

                    <div style={{ alignSelf: "center", marginTop: "-1.5rem" }}>
                        <span className='tap_text_ktcl_ktcl'> Tap on "Activate Ticket"</span>
                    </div>

                    <div className='info_div'>
                        <div >
                            <img src={Idea} style={{ width: "17px", height: "17px" }} />
                        </div>
                        <div >
                            <span className='info_text'>Your ticket will be valid till the end of business day</span>
                        </div>
                    </div>


                </section>

                <section className='how_to_use_inner_div'>
                    <div className='step_text_div'>
                        <span className='step_text'>Step 2: Validate Ticket</span>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                        <img src={Step2} style={{ width: "280px", height: "320px" }} />
                    </div>

                    <div style={{ alignSelf: "center", marginTop: "-1.5rem" }}>
                        <p className='tap_text_ktcl'> Scan the QR Sticker Pasted in the Bus</p>
                        <p className='or_text'>OR</p>

                        <p className='tap_text_ktcl'>
                            By showing the ticket to the conductor, who will validate the ticket using his QR code scanner
                        </p>
                    </div>

                    <div className='info_div'>
                        <div >
                            <img src={Idea} style={{ width: "17px", height: "17px" }} />
                        </div>
                        <div >
                            <span className='info_text'>Validate Your Ticket Within 30 Minutes of Activating It</span>
                        </div>
                    </div>


                </section>

                <section className='how_to_use_inner_div'>
                    <div className='step_text_div'>
                        <span className='step_text'>How to Check Validation History</span>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                        <img src={Step3} style={{ width: "280px", height: "355px" }} />
                    </div>

                    <div style={{ alignSelf: "center", textAlign: "center" }}>
                        <span className='tap_text_ktcl'> You Can Find Your Ticket Validation History Below the Ticket</span>
                    </div>

                    <div className='info_div'>
                        <div >
                            <img src={Idea} style={{ width: "17px", height: "17px" }} />
                        </div>
                        <div >
                            <span className='info_text'>You Can Show Your Validation History as Proof to the Bus Conductor</span>
                        </div>
                    </div>


                </section>

                <section className='how_to_use_inner_div'>
                    <div className='step_text_div'>
                        <span className='step_text'>Ticket expired</span>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                        <img src={Step4} style={{ width: "280px", height: "275px" }} />
                    </div>

                    <div style={{ alignSelf: "center", textAlign: "center" }}>
                        <span className='tap_text_ktcl'> Once validated, tickets expire after 2 hours.</span>
                    </div>
                </section>
                <div style={{textAlign:"center"}}>
                    <img src={ThinkTummoc} style={{ width: "200px", height: "235px",marginBottom:"32px",marginTop:"0px" }} />
                </div>




            </div>
        </>
    )
}

export default ktclTicketHowToUse