import React from "react";
import Footer from "./footer";
import Applink from "./applink";
import Header from "./header";
import { Helmet } from "react-helmet";

export default class Vote extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Tummoc | Privacy Policy</title>
                    <link rel="canonical" href="https://tummoc.com/privacy-policy" />
                </Helmet>
                <div>
                    <Header />

                    <section className="t-pd">
                        <div className="container-fluid  privacy">
                            <div className="col-md-12">
                                <h2 className="text-center" style={{ marginBottom: '1rem' }}>
                                    <strong>Privacy Policy</strong>
                                </h2>
                                <div className="notifications_bg">
                                    <p className="terms_text">
                                        We, Transhelp Technologies Pvt Ltd.(hereinafter referred to as
                                        the “Company”) having its office at 5th Floor, No.501-509,
                                        Tower D,No.150, Diamond District, Old Airport Road, Near
                                        Domlur Flyover, Kodihalli, Bengaluru (Bangalore) Urban,
                                        Karnataka, 560008, India represented by its members, where
                                        such expression shall, unless repugnant to the context
                                        thereof, be deemed to include its respective legal heirs,
                                        representatives, administrators, permitted successors and
                                        assigns. The creator of this Privacy Policy ensures our steady
                                        commitment to Your privacy with regard to the protection of
                                        Your invaluable information. This privacy policy contains
                                        information about an online Website/Application called
                                        www.tummoc.com (hereinafter referred to as the
                                        “Website/Application”). In order to provide You with Our
                                        uninterrupted use of services, We may collect and, in some
                                        circumstances, disclose information about You. To ensure
                                        better protection of Your privacy, We provide this notice
                                        explaining Our information collection and disclosure policies,
                                        and the choices You make about the way Your information is
                                        collected and used.
                                    </p>
                                    <p className="terms_text">
                                        {" "}
                                        ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING
                                        ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING
                                        USED HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS
                                        PROVISIONS OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER
                                        NOR THE CREATORS OF THIS PRIVACY POLICY MAY USE THE HEADING TO
                                        INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.
                                    </p>
                                    <ul className="terms_text">
                                        <li>
                                            <b>DEFINITIONS</b>
                                            <ul>
                                                <li>
                                                    “<b>We </b>”, “<b>Our</b>”, and “<b>Us</b>” shall mean
                                                    and refer to the creators of this privacy policy.
                                                </li>
                                                <li>
                                                    “<b>You</b>”, “<b>Your</b>”, “<b>Yourself</b>” and “
                                                    <b>User</b>” shall mean and refer to natural and legal
                                                    individuals including Buyers who use the
                                                    Website/Application.
                                                </li>
                                                <li>
                                                    “<b>Personal Information</b>” shall mean and refer to
                                                    any personally identifiable information that We may
                                                    collect from You. For removal of any doubts, please
                                                    refer to Clause 2.
                                                </li>
                                                <li>
                                                    “<b>Third Parties</b>” refer to any Website/Application,
                                                    Firm or individual apart from the User and the creator
                                                    of this Website/Application.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul className="terms_text">
                                        <li>
                                            <b>OVERVIEW: </b>
                                            <ul>
                                                <li>
                                                    We commit to respecting Your online privacy data. We
                                                    further recognize Your need for appropriate protection
                                                    and management of any personally identifiable
                                                    information<b> (“Personal Information“) </b>You share
                                                    with us. Information that is considered personal about
                                                    You by us includes, but is not limited to, Your name,
                                                    address, email address, phone number or other contact
                                                    information. In order to avail any services through the
                                                    Website/Application, You are required to provide the
                                                    following information which includes, but is not limited
                                                    to:
                                                </li>
                                                <li>
                                                    Basic information such as device information, operating
                                                    system, and operating system provided advertising id, IP
                                                    address, date and time of every user request.
                                                </li>
                                                <li>
                                                    We may use ‘aggregate’ level user information to build
                                                    marketing profiles, develop technology that can improve
                                                    user experience, analyse usage of Our
                                                    Website/Application
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            This privacy policy also applies to data we collect from
                                            users who are not registered as members of this site,
                                            including, but not limited to, browsing behaviour, pages
                                            viewed etc.
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <b>EXTERNAL LINKS ON THE WEBSITE/APPLICATION: </b>
                                            The Website/Application may include advertisements hyperlinks to
                                            other Website/Application, content or resources. We have no
                                            control over such external links present in the
                                            Website/Application, which are provided by persons or
                                            companies other than Us.
                                            <p>
                                                You acknowledge and agree that We are not responsible for
                                                any collection or disclosure of Your Personal Information
                                                by any external Website/Application, companies or persons,
                                                nor do We endorse any advertising, products or other
                                                material on or available from such external
                                                Website/Applications or resources.
                                            </p>
                                            <p>
                                                You further acknowledge and agree that We are not liable
                                                for any loss or damage which may be incurred by You as a
                                                result of the collection and/or disclosure of Your
                                                personal information by external Website/Application,
                                                sites or resources, or as a result of any reliance placed
                                                by You on the completeness, accuracy or existence of any
                                                advertising, products or other materials on, or available
                                                from such Website/Application, Website/Application or
                                                resources. This external Website/Application and resource
                                                providers may have their own privacy policies governing
                                                the collection, storage, retention and disclosure of Your
                                                Personal Information that You may be subject to. We
                                                recommend that You enter the external Website/Application
                                                and review their privacy policy.
                                            </p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <b>OUR USE OF YOUR INFORMATION: </b>
                                            The information provided by You at the time of registration
                                            shall be used to contact You when necessary. For more
                                            details about the nature of such communications, please
                                            refer to our Terms of Service. Further, Your personal data
                                            and Sensitive Personal data may be collected and stored by
                                            Us for internal record. We use Your tracking information
                                            such as IP addresses, and or Device ID to help identify You
                                            and to gather broad demographic information. In case We are
                                            acquired by or merged with another Firm, We shall transfer
                                            information disclosed by You and information about You to
                                            the Firm we are acquired by or merged with. In the event of
                                            a merger or acquisition, We shall notify You by email/by
                                            putting a prominent notice on the Website/Application before
                                            Your Personal Information is transferred and becomes subject
                                            to a different privacy policy.
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <b>ADVERTISING: </b>The Company uses some Third Parties to
                                            administer a limited set of advertisements on Our
                                            Website/Application and portals. During this process, no
                                            personal information is leaked. However, aggregate profile
                                            information, such as user community, may be used in the
                                            selection of advertising to make sure that it has relevance
                                            to the User. On some banner ads, an embedded pixel may be
                                            present, and while it does not associate with a cookie or
                                            other personal profile information, it may return session
                                            connection information that allows advertisers to better
                                            determine how many individual Users have clicked on the ad
                                            banner.
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <b>COOKIES</b>
                                        </li>

                                        <ul>
                                            <li>
                                                We use data collection devices such as “cookies” on
                                                certain pages of our Website/Application. “Cookies” are
                                                small files sited on Your hard drive that assist us in
                                                providing customized services. We also offer certain
                                                features that are only available through the use of a
                                                “cookie”. Cookies can also help Us provide information,
                                                which is targeted to Your interests. Cookies may be used
                                                to identify logged in or registered users.
                                            </li>
                                            <li>
                                                Third party vendors including www.google.com (“Google”)
                                                may use cookies to serve ads based on Your visits to this
                                                Website/Application. You may visit the Website/Application
                                                of the third party and choose to opt out of the use of
                                                cookies for interest-based advertising, if the third party
                                                offers such an option. You may choose to opt-out of the
                                                DoubleClick cookie that Google and its partners use for
                                                interest-based advertising by visiting Ads Settings.
                                                (Alternatively, you can direct users to opt out of a
                                                third-party vendor’s use of cookies for interest based
                                                advertising by visiting aboutads.info.)
                                            </li>
                                            <li>
                                                The Website/Application uses HTML5 local storage to
                                                create, maintain user session across logins within a
                                                browser.
                                            </li>
                                            <li>
                                                The Website/Application also has enabled the Google
                                                Analytics/other analytics suites such as localytics,
                                                mixpanel, which allows Google/Third parties to collect
                                                anonymous data about users on our Website/Application for
                                                analytics and Website/Application improvement purpose, in
                                                addition to Google advertising cookies and anonymous
                                                identifiers. You may choose to opt out of Google Analytics
                                                by downloading and installing the Google Analytics opt-out
                                                add-on here https://tools.google.com/dlpage/gaoptout/.
                                            </li>
                                        </ul>

                                        <li>
                                            <b>CONFIDENTIALITY: </b>Your information is regarded as
                                            confidential and therefore shall not be divulged to any
                                            third party, unless if legally required to do so to the
                                            appropriate authorities, or if necessary to ensure Users may
                                            fully avail of the services of the Website/Application. We
                                            shall not sell, share, or rent Your personal information to
                                            any marketing agencies or any other such companies that
                                            indulge in unsolicited communications. Any communication by
                                            Us to You shall be undertaken in accordance with Our Terms
                                            of Service and Privacy Policy.
                                        </li>
                                        <li>
                                            <b>DISCLOSURE OF YOUR INFORMATION TO THIRD PARTIES: </b>Due to
                                            the existing regulatory environment, We cannot ensure that
                                            all of Your Personal Information shall never be disclosed in
                                            ways other than those described in this Privacy Policy.
                                            Although We use industry standard practices to protect Your
                                            privacy, We do not promise, and You should not expect, that
                                            Your personally identifiable information or private
                                            communications would always remain private.
                                        </li>
                                        <ul>
                                            <li>
                                                <b>External Service Providers: </b>There may be a number
                                                of services offered by external service providers that
                                                help You use Our Website/Application. If You choose to use
                                                these optional services, and in the course of doing so,
                                                disclose information to the external service providers,
                                                and/ or grant them permission to collect information about
                                                You, then their use of Your information is governed by
                                                their privacy policy.
                                            </li>
                                            <li>
                                                <b>Other Corporate Entities: </b> We share much of our
                                                data, including Your Personal Information, with Our parent
                                                and/ or subsidiaries that are committed to serving Your
                                                needs through use of Our Website/Application and related
                                                services, throughout the world. Such data shall be shared
                                                for the sole purpose of enhancing Your experience of using
                                                the Website/Application. To the extent that these entities
                                                have access to Your information, they shall treat it at
                                                least as protectively as they treat information they
                                                obtain from their other members. It is possible that We
                                                and/or its subsidiaries, or any combination of such, could
                                                merge with or be acquired by another business entity.
                                                Should such a combination occur, You should expect that we
                                                would share some or all of Your information in order to
                                                continue to provide the service. You shall receive notice
                                                of such event (to the extent it occurs).
                                            </li>
                                            <li>
                                                <b>Law and Order: </b> We cooperate with law enforcement
                                                inquiries, as well as other third parties to enforce laws,
                                                such as: intellectual property rights, fraud and other
                                                rights. We can, and You so authorise Us, disclose Your
                                                Personal Information to law enforcement and other
                                                government officials as We, in Our sole discretion,
                                                believe necessary or appropriate, in connection with an
                                                investigation of fraud, intellectual property
                                                infringements, or other activity that is illegal or may
                                                expose Us/ Us or You to any legal liability. Any
                                                information that You make publically available on the site
                                                may be potentially viewed by any party, and by posting
                                                such material it is deemed that You consent to share such
                                                information with such parties.
                                            </li>
                                        </ul>
                                        <li>
                                            <b>ACCESSING, REVIEWING AND CHANGING YOUR PROFILE: </b>
                                            Following registration, You can review and change the
                                            information You submitted at the stage of registration. An
                                            option for facilitating such change shall be present on the
                                            Website/Application. If You change any information, We may
                                            keep track of Your old information. If You want to terminate
                                            the use of the Application You can uninstall the
                                            Application. We shall remove Your profile as early as
                                            possible. We shall retain in Our files, information You have
                                            requested to remove for certain circumstances, such as to
                                            resolve disputes, troubleshoot problems and enforce Our
                                            terms and conditions. Further, such prior information is
                                            never completely removed from Our databases due to technical
                                            and legal constraints, including stored ‘back up’ systems.
                                            Therefore, You should not expect that all of Your personally
                                            identifiable information shall be completely removed from
                                            our databases in response to Your requests.
                                        </li>
                                        <li>
                                            <b>SECURITY: </b>We treat data as an asset that must be
                                            protected against loss and unauthorised access. We employ
                                            many different security techniques to protect such data from
                                            unauthorised access by members inside and outside the Firm.
                                            We follow generally accepted industry standards to protect
                                            the Personal Information submitted to Us and information
                                            that we have accessed. However, “perfect security” does not
                                            exist on the Internet. You therefore agree that any security
                                            breaches beyond the control of Our standard security
                                            procedures are at Your sole risk and discretion.
                                        </li>
                                        <li>
                                            <b>INDEMNITY: </b>You agree and undertake to indemnify us in
                                            any suit or dispute by any Third Party arising out of
                                            disclosure of Personal Information by You to Third Parties
                                            either through Our Website/Application or otherwise and Your
                                            use and access of Website/Application and resources of Third
                                            Parties. We assume no liability for any actions of Third
                                            Parties with regard to Your Personal Information, which You
                                            may have disclosed to such Third Parties.
                                        </li>
                                        <li>
                                            <b>SEVERABILITY: </b>Each paragraph of this privacy policy
                                            shall be and remain separate from and independent of and
                                            severable from all and any other paragraphs herein except
                                            where otherwise expressly indicated or indicated by the
                                            context of the agreement. The decision or declaration that
                                            one or more of the paragraphs are null and void shall have
                                            no effect on the remaining paragraphs of this privacy
                                            policy.
                                        </li>
                                        <li>
                                            <b>AMENDMENT: </b>
                                            <ul>
                                                <li>
                                                    Our Privacy Policy may change from time to time. Hence
                                                    we shall post any privacy policy changes on the homepage
                                                    of the Website/Application or notify You by email.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <b>DISCLAIMER: </b> We cannot ensure that all of Your private
                                            communications and other personal information (including
                                            sensitive information like credit card information and bank
                                            account number) will never be disclosed in ways not
                                            otherwise described in this Privacy Policy. Therefore,
                                            although We are committed to protecting Your privacy, We do
                                            not promise, and You should not expect, that Your personal
                                            information will always remain private. As a User of the
                                            Site, You understand and agree that You assume all
                                            responsibility and risk for Your use of the Site, the
                                            internet generally, and the documents You post or access and
                                            for Your conduct on and off the Site
                                        </li>
                                        <li>
                                            <b>QUESTIONS AND SUGGESTIONS: </b>If You have any questions
                                            or concerns regarding this privacy policy, You should
                                            contact Us by sending an e-mail to support
                                            <span>@tummoc.com</span>
                                        </li>


                                        {/* use of data */}
                                        <br /><br />
                                        <li>
                                            <b>USE OF DATA</b>
                                        </li>
                                        <p>Information We Collect</p>
                                        <b>1.1 Personal Information</b>
                                        <p>We may collect various types of personal information, including but not limited to:</p>
                                        <ul>
                                            <li>Contact information (e.g., name, email address, phone number)</li>
                                            <li>Demographic information (e.g., age, gender, location)</li>
                                            <li>Account credentials (e.g., username, password)</li>
                                            <li>Usage data (e.g., log files, device information, IP address)</li>
                                            <li>Other information you provide to us voluntarily</li>
                                        </ul>

                                        <br />
                                        <b>1.2 Cookies and Similar Technologies</b>
                                        <p>We may use cookies, web beacons, and similar technologies to collect information about your usage of our Services. This helps us analyze trends, administer the website, track users' movements, and gather demographic information.</p>

                                        <p>Use of Information</p>
                                        <p>We use the collected information for various purposes, including:</p>
                                        <ul>
                                            <li>Providing and improving our Services</li>
                                            <li>Personalizing your experience</li>
                                            <li>Responding to your inquiries and providing customer support</li>
                                            <li>Processing transactions and delivering requested products or services</li>
                                            <li>Analyzing usage trends and optimizing our Services</li>
                                            <li>Communicating with you about updates, promotions, and other relevant information</li>
                                        </ul>

                                        <br />
                                        <b>1.3 Disclosure of Information</b>
                                        <p>We may disclose your personal information to third parties in the following circumstances:</p>
                                        <ul>
                                            <li>Service Providers: We may share your information with trusted third-party service providers who assist us in delivering our Services and conducting our business activities.</li>
                                            <li>Legal Compliance: We may disclose your information if required by law, governmental request, court order, or to protect our rights, property, or safety, as well as that of our users or others.</li>
                                            <li>Business Transfers: If our business undergoes a merger, acquisition, or sale of assets, your personal information may be transferred or disclosed as part of the transaction.</li>
                                        </ul>

                                        <br />
                                        <b>1.4 Data Security</b>
                                        <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

                                        <br />
                                        <b>1.5 Your Rights</b>
                                        <p>You have certain rights regarding your personal information, including:</p>
                                        <ul>
                                            <li><b>Access:</b> You can request access to the personal information we hold about you.</li>
                                            <li><b>Correction:</b> You may request corrections or updates to your personal information.</li>
                                            <li><b>Erasure:</b> You can request the deletion of your personal information by writing an email to us at support@tummoc.com, subject to certain legal obligations and legitimate business purposes. The process might take 7-14 days.</li>
                                            <li><b>Object:</b> You can object to the processing of your personal information in certain circumstances.</li>
                                        </ul>

                                        <br />
                                        <b>1.6 Children's Privacy</b>
                                        <p>Our Services are not intended for individuals under the age of 13 and above. We do not knowingly collect personal information from children. If you believe we have inadvertently collected personal information from a child, please contact us, and we will take appropriate steps to delete such information.</p>

                                        <br />
                                        <b>1.7 Changes to this Privacy Policy</b>
                                        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other communication channels.</p>

                                        <br />
                                        <b>Contact Us</b>
                                        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us using the information below:</p>
                                        <p>
                                            Transhelp Technologies Pvt Ltd<br />
                                            No. 5 Maruthi Complex, GF, 9th 'B Main,<br />
                                            Dodda Banaswadi Main Rd, Kalyan Nagar,<br />
                                            Bengaluru, Karnataka 560043<br />
                                            <b>Email:</b> support@tummoc.com
                                        </p>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </section>

                    <section style={{ position: "relative" }}>

                        <section className="vote-section" style={{ position: 'absolute', top: '-10rem', width: '100%' }}>
                            <div style={{ background: '#fff', borderRadius: '2rem' }}>
                                <div className="link-section">
                                    <Applink />
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </section>
                </div>
            </>
        )
    }
}