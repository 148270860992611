import React from "react";
import Footer from "./footer";
import Applink from "./applink";
import Header from "./header";
import { Helmet } from "react-helmet";

export default class DtcRefund extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Tummoc | DTC Cancellation & Refund Policy</title>
                    <link rel="canonical" href="https://tummoc.com/cancellation-and-refund-policy" />
                </Helmet>
                <div>
                    <Header />

                    <section className="t-pd">
                        <div className="container-fluid  privacy">
                            <div className="col-md-12">
                                <h2 class="text-center" style={{ marginBottom: '1rem' }}>
                                    <strong>DTC Pass Cancellation & Refund</strong>
                                </h2>
                                <div>
                                    <ul className="terms_text">

                                        <li>
                                            {/* <b>Can I cancel my DTC Bus Pass? What is the refund process?</b> <br /> */}
                                            <ol>
                                                <li>
                                                    All passes can be cancelled, except daily passes.
                                                </li>
                                                <li>
                                                    Irrespective of the pass type, the refund is calculated as per the per-week cost as determined by the corresponding price of the one-month pass.
                                                </li>
                                                <li>
                                                    As a standard, the per-week price is calculated under the assumption that there are four weeks in the month.
                                                </li>
                                                <li>
                                                    You can only cancel your pass if the value remaining value on your pass is equivalent to or more than the cost for one week as per the one-month price.
                                                </li>
                                                <li>
                                                    The convenience fee charged at the time of pass purchase will not be eligible for refund.

                                                </li>
                                                <li>
                                                    Additionally, there is a standard Rs 10 cancellation fee on all pass cancellations.
                                                </li>
                                                <br/>
                                                <i style={{color:"#777"}}>For example, If your pass costs Rs 8640 for 1 year, and the same pass costs Rs 800 for a month, the per-week value calculated at the time of refund would be Rs 200 (800/4). If you have utilized the pass for 10 months, and want a refund in the 1st week of the 11th month, you have already utilized Rs 8000 of your pass value (800x10) and will be eligible for Rs Rs 630 (8640 - 8000 - 10). However, you won’t be eligible for a refund after the third week of the 11th month, as your entire pass value of Rs 8640 will have been utilized.</i>
                                            </ol>
                                            {/* As a genseral rule users shall not be entitled to cancel orders once placed. However, subject to the user's reasoning, Tummoc reserves the right to deny any refund to user pursuant to a cancellation initiated by the user. If tickets/pass/recharge is not successful and the buyer provides sufficient proof, the money may be refunded within 7 days, as may be necessary at the sole discretion of Tummoc. */}
                                        </li>
                                        <br />
                                        {/* <li>
                                            <b>BMTC pass, ticket cancellation policy </b> <br />
                                            1. Tickets/pass once booked is non-refundable and non-transferrable <br />
                                            2. Buyer should use the same mobile to register and make purchases on the Tummoc app <br />
                                            3. In case of any fraud detected, Tummoc reserves the right to block the user from further using the app and no money will be refunded
                                        </li> */}
                                        {/* <br /> */}
                                        {/* <li>
                                            <b>Platform fee</b>  <br />
                                            Currently, there are no platform fees charged by Tummoc for any BMTC purchase, The charges paid by the users will be completely transferred to the BMTC account without any commission charged.
                                        </li>
                                        <br /> */}
                                        <li>
                                            <b>Tummoc Money</b>  <br />
                                            Any incentives provided to the user by the company in the form of cashback or referrals will expire by the end of the financial year. Any balance that has not been utilised or redeemed will expire.
                                        </li>
                                        <br />
                                        <p>
                                            For any support or query write us at <a href="mailto:support@tummoc.com"> support@tummoc.com </a>
                                        </p>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </section>

                    <section style={{ position: "relative" }}>

                        <section className="vote-section" style={{ position: 'absolute', top: '-10rem', width: '100%' }}>
                            <div style={{ background: '#fff', borderRadius: '2rem' }}>
                                <div className="link-section">
                                    <Applink />
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </section>
                </div>
            </>
        )
    }
}