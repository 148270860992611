import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../header'
import Step1 from "../../assets/1.png"
import Step2 from "../../assets/2.png"
import Step3 from "../../assets/3.png"
import Step4 from "../../assets/4.png"
import ThinkTummoc from "../../assets/chiya_think_tummoc.png"


import "./dtcTicketHowToUse.css"
import Idea from "../../assets/idea.png"
function dtcTicketHowToUse() {
    return (
        <>
            <Helmet>
                <title>Tummoc | How to Use DTC Bus Ticket</title>
                <link rel="canonical" href="https://tummoc.com/dtc-ticket-how-to-use" />
            </Helmet>
            <div className='how_to_use_parent_div' >
                <section className='how_to_use_inner_div'>
                    <div className='step_text_div'>
                        <span className='step_text'>Step 1: Activate Ticket</span>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                        <img src={Step1} style={{ width: "300px", height: "320px" }} />
                    </div>

                    <div style={{ alignSelf: "center", marginTop: "-1.5rem" }}>
                        <span className='tap_text'> Tap on "Activate Ticket"</span>
                    </div>

                    <div className='info_div'>
                        <div >
                            <img src={Idea} style={{ width: "17px", height: "17px" }} />
                        </div>
                        <div >
                            <span className='info_text'>Your ticket will be valid till the end of business day</span>
                        </div>
                    </div>


                </section>

                <section className='how_to_use_inner_div'>
                    <div className='step_text_div'>
                        <span className='step_text'>Step 2: Validate Ticket</span>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                        <img src={Step2} style={{ width: "300px", height: "320px" }} />
                    </div>

                    <div style={{ alignSelf: "center", marginTop: "-1.5rem" }}>
                        <span className='tap_text'> Scan the QR Sticker Pasted in the Bus</span>
                    </div>

                    <div className='info_div'>
                        <div >
                            <img src={Idea} style={{ width: "17px", height: "17px" }} />
                        </div>
                        <div >
                            <span className='info_text'>Validate Your Ticket Within 30 Minutes of Activating It</span>
                        </div>
                    </div>


                </section>

                <section className='how_to_use_inner_div'>
                    <div className='step_text_div'>
                        <span className='step_text'>How to Check Validation History</span>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                        <img src={Step3} style={{ width: "300px", height: "355px" }} />
                    </div>

                    <div style={{ alignSelf: "center", textAlign: "center" }}>
                        <span className='tap_text'> You Can Find Your Ticket Validation History Below the Ticket</span>
                    </div>

                    <div className='info_div'>
                        <div >
                            <img src={Idea} style={{ width: "17px", height: "17px" }} />
                        </div>
                        <div >
                            <span className='info_text'>You Can Show Your Validation History as Proof to the Bus Conductor</span>
                        </div>
                    </div>


                </section>

                <section className='how_to_use_inner_div'>
                    <div className='step_text_div'>
                        <span className='step_text'>Ticket expired</span>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                        <img src={Step4} style={{ width: "300px", height: "275px" }} />
                    </div>

                    <div style={{ alignSelf: "center", textAlign: "center" }}>
                        <span className='tap_text'> Once validated, tickets expire after 2 hours.</span>
                    </div>
                </section>
                <div>
                    <img src={ThinkTummoc} style={{width:"200px",height:"195px",marginLeft:"46px",margin:"32px 46px"}}/>
                </div>




            </div>
        </>
    )
}

export default dtcTicketHowToUse