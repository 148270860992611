import React from "react";
import Footer from "../footer";
import Applink from "../applink";
import Header from "../header";
import { Helmet } from "react-helmet";
import "./vote.css";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { CITYTOKEN } from "../../config/tokens";
import { API_URL } from "../../config/contants";
import ReCAPTCHA from "react-google-recaptcha";

export default class Vote extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openModal: false,
      modalValue: { name: "", city_picture: "" },
      successMsg: '',
      activeCities: [],
      upcomingCities: [],
      captchaCode: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleContact = this.handleContact.bind(this);
  }

  componentDidMount() {
    fetch(`${API_URL}/city/getAllCities`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": CITYTOKEN },
      body: JSON.stringify({ query: { status: "active" } }),
    }).then((response) => response.json())
      .then((result) => {
        this.setState({ activeCities: result.response });
      })

    fetch(`${API_URL}/city/getAllCities`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": CITYTOKEN },
      body: JSON.stringify({ query: { status: "upcoming" } }),
    }).then((response) => response.json())
      .then((result) => {
        this.setState({ upcomingCities: result.response });
      })
  }

  handleClick() {
    // window.scrollTo(0,2800);
    this.setState(state => ({
      open: !state.open
    }));
  }

  selectCity = value => {
    this.setState(state => ({
      modalValue: value
    }));
  }

  onOpenCloseModal = value => {
    this.setState(state => ({
      openModal: !state.openModal
    }));
  };


  handleContact(e) {
    e.preventDefault();
    if (!this.state.captchaCode) {
      // throw new Error("Check your captcha code");
      this.setState({
        successMsg: "Check your captcha code"
      })
      return
    }
    const data = Object.fromEntries(new FormData(e.target).entries());
    data.city = this.state.modalValue.name;
    e.target.reset();

    console.log(data);

    fetch(`${API_URL}/vote/addVote`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result.message);
        this.setState({ successMsg: result.message });

        setTimeout(() => {
          this.setState({ successMsg: '', modalValue: { name: "", city_picture: "" } });
          this.onOpenCloseModal();
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
        this.setState({ successMsg: "Something went wrong!" });

        setTimeout(() => {
          this.setState({ successMsg: '', modalValue: { name: "", city_picture: "" } });
          this.onOpenCloseModal();
        }, 3000);
      });

  };

  render() {

    const cities = [
      { name: 'Guwahati', image: 'Guwahati.jpg' },
      { name: 'Jabalpur', image: 'Jabalpur.jpg' },
      { name: 'Kanpur', image: 'Kanpur.jpg' },
      { name: 'Mangalore', image: 'Mangalore.jpg' },
      { name: 'Udupi', image: 'Udupi.jpg' },
      { name: 'Hubballi', image: 'hubbali.jpg' },
    ];


    const current_cities = [
      { name: 'Bangalore', image: 'bangalore.png' },
      { name: 'Kolkata', image: 'kolkata.png' },
      { name: 'Hyderabad', image: 'hyderabad.png' },
      { name: 'Mumbai', image: 'mumbai.png' },
      { name: 'Chennai', image: 'chennai.png' },
      { name: 'Delhi', image: 'delhi.png' },
      { name: 'Ahmedabad', image: 'ahmedabad.png' },
      { name: 'Kochi', image: 'kochi.png' },
      { name: 'Lucknow', image: 'lucknow.png' },
      { name: 'Jaipur', image: 'jaipur.png' },
      { name: 'Patna', image: 'Patna.jpg' },
      { name: 'Bhopal', image: 'Bhopal.jpg' },
      { name: 'Indore', image: 'Indore.jpg' },
      { name: 'Agra', image: 'Agra.jpg' },
      { name: 'Pune', image: 'pune.png' },
      { name: 'Bhubaneshwar', image: 'Bhubaneshwar.jpg' },
      { name: 'Vadodara', image: 'vadodara.jpg' },

    ]

    const modalCss = {
      modal: {
        maxWidth: "800px",
        position: "relative",
        padding: "0.5rem 2rem 2rem 2rem",
        background: "#fccc08",
        backgroundClip: "padding-box",
        boxShadow: "0 12px 15px 0 rgb(0 0 0 / 25%)",
        borderRadius: "1rem",
        // height: '55vh',
        height: "auto",
        maxHeight: "70vh",
        // width: '50vh',
        // backgroundImage: 'radial-gradient(circle 70vh at 50% 100%, #ff9b3d, #ff7b00, #ff9027)',
        backgroundSize: "100% 40%",
        backgroundRepeat: "no-repeat"
      },

      closeButton: {
        display: "none"
      }
    };


    return (
      <>
        <Helmet>
          <title>Travel Easy in Your City with Tummoc: Available in 20+ Cities</title>
          <link rel="canonical" href="https://tummoc.com/vote-for-city" />
          <meta name="title" content="Travel Easy in Your City with Tummoc: Available in 20+ Cities" />
          <meta name="description" content="Tummoc is a daily travel app that helps you book bus tickets and passes online, book autos, cabs and bike taxis, and plan your daily travel with ease." />
        </Helmet>
        <div>
          <Header />

          <div className="top">

            <section>
              <div className="sectionOne bg1">
                <div className="row v-sec" style={{ margin: 0 }}>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 headtext v-sec-1">
                    <p className="head1 headb">Where we are, <br /> Where we're going</p>
                    <p className="head2">
                      Do you want Tummoc in your city? Check which cities can already #TummocIt and vote for your city below!
                    </p>
                    {/* <button className="btn my-2 my-sm-0 voteme-btn" type="button">
                            VOTE NOW
                          </button> */}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 v-sec-2" style={{ textAlign: 'center' }}>
                    <img src="/images/votebird.png" alt="top header logo" className="header1-img" />
                  </div>
                </div>
              </div>
            </section>


            <section>
              <div className="sectionSix" style={{ height: 'auto', paddingBottom: '5rem' }}>
                <div className="row" style={{ margin: 0 }}>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 testimonial">
                    <p className="news-head headb" style={{ marginTop: '5rem', lineHeight: 1 }}>Cities we are in</p>
                    <p className="news-txt">
                      These are the cities that currently have access to the smart commute with Tummoc!
                    </p>
                  </div>


                  <div className="col-lg-8 col-md-6 col-sm-12 col-12 mt-lg-5 mt-md-5">

                    <div className="row">

                      {this.state.activeCities && this.state.activeCities.map((item, i) => {
                        return <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                          <div class=" card team-card ccard">
                            <img
                              // src={`images/${item.image}`} 
                              src={item.city_picture}
                              class="card-img team-img vt-img" alt="cityimage" />

                            <div class="card-body" style={{ padding: '1rem' }}>
                              <h6 class="card-title"> {item.name} </h6>
                            </div>
                          </div>
                        </div>
                      }
                      )}

                    </div>
                  </div>

                </div>
              </div>
            </section>


            <section>
              <div className="sectionSix" style={{ height: 'auto', background: '#0e2d39' }}>
                <div className="row" style={{ margin: 0 }}>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 testimonial" style={{ color: '#fff' }}>
                    <p className="news-head headb" style={{ marginTop: '5rem', lineHeight: 1, color: '#fff' }}>Vote for our next city</p>
                    <p className="news-txt" style={{ color: '#fff' }}>
                      Eventually, Tummoc is going to be available across India. For now, vote for the city you’d like us to come to next!

                      <br />

                      <span>
                        <button className="btn my-3 voteme-btn" style={{ marginTop: '2rem' }}
                          type="button" onClick={() => {
                            if (this.state.modalValue.name) this.onOpenCloseModal();
                            else alert('Please select city!')
                          }}>
                          VOTE
                        </button>
                      </span>
                    </p>
                  </div>

                  <div className="col-lg-8 col-md-6 col-sm-12 col-12 mt-lg-5 mb-5 pb-5">

                    <div className="row pb-5">

                      {this.state.upcomingCities && this.state.upcomingCities.map((item, i) => {
                        return <div className="col-lg-4 col-md-6 col-sm-12 col-12"
                          value={i} onClick={() => this.selectCity(item)}>
                          <div className={(item.name === this.state.modalValue.name) ? "card team-card ccard card-highlight" : "card team-card ccard"} style={{ position: 'relative' }}>
                            <img
                              // src={`images/${item.image}`} 
                              src={item.city_picture}
                              class="card-img team-img vt-img" alt="cityimage" />

                            <div class="card-body cb">
                              <h6 class="card-title"> {item.name} </h6>
                            </div>

                            {
                              (item.name === this.state.modalValue.name) &&
                              <div>
                                <div className="vote-select"></div>
                                <img src="images/tick.png" alt="newsimage" className="vote-tick" />
                              </div>
                            }
                          </div>
                        </div>
                      }
                      )}

                    </div>
                  </div>

                </div>
              </div>

              <Modal styles={modalCss} open={this.state.openModal}
                onClose={() => this.onOpenCloseModal("")} center >
                <button
                  onClick={() => this.onOpenCloseModal("")}
                  style={{ background: "transparent", border: "none", outline: "none" }}
                >
                  <i className="fab fa-times"
                    style={{
                      position: "absolute", top: "14px",
                      right: "14px", border: "none",
                      padding: "0", cursor: "pointer"
                    }}
                  ></i>
                </button>

                <div
                  className="row v-sec"
                  style={{ margin: 0, textAlign: "left" }}
                >
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 v-sec-1">
                    <h4 className="text-white">Confirm your Vote</h4>
                    <form
                      method="post"
                      data-form-title="VOTE CITY"
                      onSubmit={e => this.handleContact(e)}
                    >
                      <input type="text" required placeholder="Name" name="name" className="input-box msg-inp inp" />
                      <input type="email" required placeholder="Email" name="email" className="input-box msg-inp inp" />
                      <textarea required type="text" rows="4" placeholder="Why I think Tummoc is good for this city?" name="message" className="input-box msg-txtb" />
                      <select name="type" className="input-box msg-inp inp">
                        <option value="local">I am a local</option>
                      </select>
                      {this.state.successMsg && (
                        <p style={{ fontSize: '0.8rem', color: '#0e2d39' }}>{this.state.successMsg}</p>
                      )}
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA}
                        style={{ margin: "1rem 0" }}
                        onChange={(value) => {
                          this.setState({
                            captchaCode: value
                          })
                        }}
                        onExpired={(_) => {
                          this.setState({
                            captchaCode: undefined
                          })
                        }}
                      />
                      <button className="btn my-2 my-sm-0 nav-btn vote-btn vote-now nlink s-btn" style={{ width: '100%', }} type="submit">
                        SUBMIT
                      </button>
                    </form>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 v-sec-2">

                    <div class=" card team-card ccard mx-md-5">
                      <img
                        // src={`images/${this.state.modalValue.image}`} 
                        src={this.state.modalValue.city_picture}
                        class="card-img team-img vt-img" alt="newsimage" />

                      <div class="card-body cb text-center">
                        <p style={{ fontSize: '0.9rem' }}>Tummoc should come to</p>
                        <h6 class="card-title mb-0"> {this.state.modalValue.name} </h6>
                      </div>
                    </div>

                  </div>
                </div>
              </Modal>
            </section>


            <section style={{ position: "relative" }}>

              <section className="vote-section" style={{ position: 'absolute', top: '-10rem', width: '100%' }}>
                <div style={{ background: '#fff', borderRadius: '2rem' }}>
                  <div className="link-section">
                    <Applink />
                  </div>
                </div>
              </section>

              <Footer />
            </section>

          </div>

        </div>
      </>
    );
  }

}
