import React from "react";
import Footer from "../footer";
import Header from "../header";
import Timeline from "../timeline/timeline";
import VerticalTimeline from "../verticalTimeline/verticalTimeline";
import { Helmet } from "react-helmet";
import { isBrowser, isMobile } from "react-device-detect";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import Applink from "../applink";
import "./about.css";
import { TEAMTOKEN } from "../../config/tokens";
import { API_URL } from "../../config/contants";

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openModal: false,
      modalValue: { name: "", designation: "", about: "", profile_picture: "" },
      team:[],

    };
    this.handleClick = this.handleClick.bind(this);
  }

  // const [size, setSize] = useState([0, 0]);
  // useLayoutEffect(() => {
  //   function updateSize() {
  //     setSize([window.innerWidth, window.innerHeight]);
  //   }
  //   window.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);
  // return size;
  
  componentDidMount() {
    fetch(`${API_URL}/team/getAllTeam`, {
      method: "GET",
      headers: { "Content-Type": "application/json","Authorization":TEAMTOKEN }, 
    }).then((response) => response.json())
    .then((result) => {
      this.setState({ team: result.response });
    })
   }

  handleClick() {
    // window.scrollTo(0,2800);
    this.setState(state => ({
      open: !state.open
    }));
  }

  onOpenCloseModal = value => {
    
    this.setState(state => ({
      openModal: !state.openModal,
      modalValue: value
    }));
  };

  render() {
    const {team} = this.state
    const items = [
      {
        name: "2016",
        description:
          <React.Fragment>
            <strong>April</strong> 2016 - Foundation of BYKERR, <br />
            <b>Oct</b> 2016 - Formation of Founding Team
          </React.Fragment>,
        show: false
      },
      {
        name: "2017",
        description: <React.Fragment> <strong>June</strong> 2017 - Alpha Launch - Banglore</React.Fragment>,
        show: false
      },
      {
        name: "2018",
        description:
          <React.Fragment>
            <strong>Jan</strong> 2018 - Beta Launch - Bangalore, <br />
            <strong>April</strong> 2018 - 1000 Users, <br />
            <strong>Aug</strong> 2018 - KIAL Pilot Launch, <br />
            <strong>Aug</strong> 2018 - STAMP Grant Win
          </React.Fragment>,
        show: true
      },
      {
        name: "2019",
        description:
          <React.Fragment>
            <strong>March</strong> 2019 - Hyderabad Launch, <br />
            <strong>May</strong> 2019 - 1000 User @Hyderabad, <br />
            <strong>Dec</strong> 2019 - Reached 20000 Rides
          </React.Fragment>,
        show: false
      },
      {
        name: "2020",
        description:
          <React.Fragment>
            <strong>July</strong> 2020 - Rebranded to TUMMOC Angel Round Funding
          </React.Fragment>,
        show: false
      },
      {
        name: "2021",
        description:
          <React.Fragment>
            <strong>January</strong> - Tummoc went LIVE!, <br />
            <strong>June</strong> - Crossed 50K Users, <br />
            <strong>August</strong> - 100K+ Users, <br />
            <strong>September</strong> - Our First Patent was Approved ✅, <br />
            <strong>October</strong> - Tummoc Got a NEW LOOK!, <br />
            <strong>November</strong> - 250K+ Users, <br />
            <strong>December</strong> - Tummoc Announces Collaboration with Rapido
          </React.Fragment>,
        show: false
      },
      {
        name: "2022",
        description:
          <React.Fragment>
            <strong>April</strong> - Tummoc Launched Digital BMTC Passes & Crossed 500K Users, <br />
            <strong>June</strong> - Reached Our First MILLION Users!
          </React.Fragment>,
        show: false
      },
      {
        name: "2023",
        description:
          <React.Fragment>
            <strong>January</strong> - Crossed 2 Million Users!
          </React.Fragment>,
        show: false
      }
    ];

    const teamOld = [
      {
        name: "Hiranmay Mallick",
        designation: "CO-FOUNDER & CEO",
        linkedin: "https://www.linkedin.com/in/hiranmay-mallick-09298124/",
        bio: `Hiranmay handles the overall Business Operations & Strategy, as well as Investment & Investor Relations for Tummoc. He grew up in Orissa. After completing his Engineering there, he moved to Mysore for his MBA. Here, he got placed at HCL as a Management Trainee. 
          He spent 7 years at HCL, moving right from a Management Trainee to the Area Sales Manager level. Here, he took on a product that was brand new to the market, and build it to a multi-crore revenue stream, establishing a healthy ecosystem for it to thrive in for years to come. In the time he spent at HCL, as a leader he was exposed to work in operations, people and process management, P&L, BD and so much more. 
          
          After HCL, he joined the TimesJobs.com division of Times Group as a Team Sales Manager, heading the SME-IT vertical. This is where he met his fellow co-founder, Narayan. 
          
          Since 2016, he’s been an Entrepreneur, loving all the challenges, perks and learning that comes with the role.`,
        profile: "hiranmay.png"
      },
      {
        name: "Monalisha Thakur",
        designation: "CO-FOUNDER & CMO",
        linkedin: "https://www.linkedin.com/in/monalisha-thakur-0348b91b/",
        bio: `Monalisha looks after Marketing, Growth & Strategy at Tummoc. She has over 11 years of experience in sales, focussing mostly on audio/visual sales. 
              She grew up in a family of Doctors & Engineers. She studied Food Engineering, and then went on to do her MBA. Before she knew it, she had a job in sales at HCL. This is where she also happened to meet her husband and fellow co-founder, Hiranmay. 
              She spent a little over a year at HCL and then moved on to HARMAN International. She spent five whole years here, rising up to the role of Senior Regional Sales Manager for South India and Sri Lanka. 
              Next, she spent two years at Crestron Electronics as a Regional Sales Manager. And finally, before beginner her journey into Entrepreneurship at Tummoc, she worked at Christie Digital Systems for over a year.
        `,
        profile: "monalisha.png"
      },
      {
        name: "Narayan Mishra",
        designation: "CO-FOUNDER & CTO",
        linkedin: "https://www.linkedin.com/in/narayan-mishra/",
        bio: `Narayan is Tummoc’s Head of Technology. He began his career at PharmARC Analytic Solutions, as a Software Developer. After spending a year there, he moved on to work at Thomson Reuters as an Analyst. Here, the quality of his research benchmarked at around 99%. Here, he worked with leaders from across the globe. Next, he joined a Tech startup that looked into end-to-end product delivery. He conceptualised a profitable solution for the company which went on to become their flagship product & helped enterprises save millions of dollars. Having grown up in the buzzing city of Mumbai, he’s spent a lot of time on the city’s local trains. That’s exactly why the idea of Tummoc (Bykerr then) resonated with him.`,
        profile: "narayan.png"
      },
      {
        name: "Suraj Nayak",
        designation: "Senior Software Engineer",
        linkedin: "https://www.linkedin.com/in/suraj-nayak-software-developer/",
        bio: `Suraj takes care of software development & architect for Tummoc. He is the architect behind developing the high-quality softwares and applications. He loves to do sketching, painting, cooking and keeps interest in tourist places as well.`,
        profile: "suraj.jpeg"
      },
      {
        name: "Abha Chaturvedi",
        designation: "Senior Software Engineer",
        linkedin: "https://www.linkedin.com/in/abhachaturvedi",
        bio: `Abha is a senior software engineer who leads mobile application development. She enjoys building great user experience through critical thinking combined with her creative acumen. Outside of work, you would find her expressing creativity through sketching and painting. Prior to joining Tummoc, she spent time architecting and developing scalable applications for companies like Panasonic, Rebel Foods (formerly Faasos) and Dukaan.`,
        profile: "abha.png"
      },
      {
        name: "Ravikant Kumar",
        designation: "Senior Software Developer",
        linkedin: "https://www.linkedin.com/in/ravikant-kumar-b1a657177/",
        bio: `Ravikant is enthusiastic about softwares and looks after software development for Tummoc. He’s hard working with very positive vibe ! He loves to explore new techs around,  his hobbies is exploring new places and foods.`,
        profile: "ravikant.png"
      },
      {
        name: "Vrushali Chetan W",
        designation: "HR & Recruitment Manager",
        linkedin: "https://www.linkedin.com/in/vrushali-wagh-a3092a212/",
        bio: `Vrushali looks after end to end recruitment, staff management, employee documentation and background verification at Tummoc. She’s happy-go-lucky and hard working. In her free time, she enjoys traveling, watching movies, cooking & shopping.`,
        profile: "vrushali.png"
      },
      {
        name: "Vijay Ranjan",
        designation: "Business Analyst",
        linkedin: "https://www.linkedin.com/in/vijay-ranjan-8b68a21bb/",
        bio: `From transit data sourcing, analysis, refinement and structuring — Vijay looks after it all for Tummoc. He’s also a part of the Quality Assurance team. When he’s taking a break from work, he enjoys playing cricket or reading his favourite novels. `,
        profile: "vijay.png"
      },
      {
        name: "Aashi Soni",
        designation: "QA Engineer",
        linkedin: "https://www.linkedin.com/in/aashi-soni-452a1b142",
        bio: `Aashi is a Quality Assurance Engineer for Tummoc. She looks after end-to-end testing of our app. Outside of work, she enjoys being challenged and dabbles in various hobbies and interests such as art, music and anything new and creative.`,
        profile: "aashi.png"
      },
      {
        name: "Dibya Ranjan Jena",
        designation: "Consultant - Transit Data",
        linkedin: "",
        bio: `Dibya is an expert with 15 years of experience when it comes to transit data. He works as a consultant for Tummoc, always aiding us with helpful insights and expertise. In his free time, he enjoys to cook, trek or travel.`,
        profile: "dibya.png"
      },
      {
        name: "Rhea Mansukhani",
        designation: "Content Marketing Manager",
        linkedin: "https://www.linkedin.com/in/rhea-mansukhani/",
        bio: `Rhea works as Tummoc's Content Marketing Manager. She looks after all of Tummoc's content requirements including social media, SEO-friendly content, internal communications, branding initiatives as well as strategy for the same. In her free time, she likes to watch anime, read, cook, and spend time with her loved ones. `,
        profile: "rhea-m.png"
      },
      // {
      //   name: "Vineeta Singh",
      //   designation: "Social Media Analyst",
      //   linkedin: "",
      //   bio: `Vineeta is passionate about all things marketing, SEO and social media. She’s extremely results-driven and dedicated to achieving goals. When she’s not thinking about work, she’s telling stories through photography. `,
      //   profile: "vineeta.png"
      // },
      // {
      //   name: "Devang Patil",
      //   designation: "Graphic Designer",
      //   linkedin: "",
      //   bio: `Devang is responsible for all things design at Tummoc. He enjoys playing with colours and elements to represent a brand and create an impact. In his free time, he enjoys gaming or going on long bike rides. `,
      //   profile: "devang.png"
      // },
      // new
      {
        name: "Abhijay Thachery",
        designation: "Product Designer",
        linkedin: "https://www.linkedin.com/in/abhijaythachery",
        bio: `Abhijay is responsible for planning and designing Tummoc's User Interface and User Experience. He's part of the team that thinks up new features for Tummoc , and making them a reality. In his free time, Abhijay enjoys cooking, gaming, watching indie drama films and working on personal design projects.`,
        profile: "abhijay.png"
      },
      {
        name: "Ashutosh Pandey",
        designation: "Android Developer",
        linkedin: "https://www.linkedin.com/in/ashutosh-pandey-a40a39161/",
        bio: `Ashutosh is part of our team of Android Developers who are working on the back end of our android app to make sure everything's working smoothly so commuters can focus on their commute. When he's not working, he loves to learn, play around with new technology, and play cricket too.`,
        profile: "asutosh.jpg"
      },
      {
        name: "Satyam Sharma",
        designation: "Android Developer",
        linkedin: "https://www.linkedin.com/in/satyam-sharma-a2084b43",
        bio: `Satyam, an android developer at Tummoc works on debugging, boosting performance, and implementing new designs on the app. In his free time, he enjoys participating in activities that help society and have a greater impact. He also enjoys being one with nature, and spending time getting to know different people.`,
        profile: "satyam.png"
      },
      {
        name: "Srijith Santhosh",
        designation: "Backend Developer",
        linkedin: "https://www.linkedin.com/in/srijith-santhosh-46b546213/",
        bio: `Srijith is a backend intern at Tummoc, who loves to code. He majorly works on Postgres SQL using GO language and Gin web framework along with data validation. Besides coding Srijith is into designing short film ideation, he also likes to read and sketch.`,
        profile: "srijit.png"
      },
      {
        name: "Md. Nazil Badami",
        designation: "Android Developer",
        linkedin: "https://www.linkedin.com/in/mohammadnazil-m-badami-553825156",
        bio: `Mohammad Nazil is an Android Developers at Tummoc that primarily works on the development of the BMTC ticketing app, along with the Tummoc customer app. Outside of work, he enjoys playing cricket, travelling, and exploring new technology to play around with.`,
        profile: "nazil.png"
      },
      {
        name: "Rishav Agarwal",
        designation: "Growth Head",
        linkedin: "https://www.linkedin.com/in/rishav-agarwal/",
        bio: `Rishav looks after Growth & Strategy at Tummoc, and he's always thinking up new ways to take us to the next level. Being quite the startup enthusiast, he interacts with several other startups in his free time, and is always coming up with new content and interesting market observations.`,
        profile: "rishav.png"
      },
      {
        name: "Kiran Nayak",
        designation: "Android Developer",
        linkedin: "https://www.linkedin.com/in/kiran-kumar-nayak-174048196/",
        bio: `Kiran is one of the Android Developers in our tech team. He is responsible for implementing new features on Tummoc's apps. When he's not working on India's smart commute revolution, he's travelling, watching films and listening to his favourite music.`,
        profile: "kiran.png"
      },
      {
        name: "Nithin L",
        designation: "Data Operations & DSE",
        linkedin: "https://www.linkedin.com/in/nithin-l-b38271216",
        bio: `Nithin works in Data Operations and as a Desktop Support Executive at Tummoc. He works on sourcing, analysing and compiling transit data from different locations.`,
        profile: "nitin.png"
      },
      {
        name: "Srikanth V",
        designation: "Customer Service Associate",
        linkedin: "https://www.linkedin.com/in/srikanth-v-810076239/",
        bio: `Srikanth is a Customer Service Associate at Tummoc who interacts with commuters to effectively solve their queries and maintain records of all such interactions. His hobbies are playing cricket and travelling.`,
        profile: "srikant.jpg"
      },
      {
        name: "Naveen",
        designation: "Motion Graphics Artist",
        linkedin: "https://www.linkedin.com/in/naveen-sampath-b740861aa/",
        bio: `Naveen is a Motion Graphics Artist in our Marketing and Growth team. He creates dynamic and static visuals for Social Media Marketing as well as offline marketing. His primary focus is to create visuals that will gain more reach and that our audience can relate to. When he's not busy creating, he enjoys spending time with friends, upskilling or watching films and television series.`,
        profile: "naveen.jpg"
      },
      {
        name: "Iqra Islam",
        designation: "Strategy & PR Analyst",
        linkedin: "https://www.linkedin.com/in/iqra-islam-62652a159/",
        bio: `I am responsible for the company's strategic growth collaborations, PR, and influencer marketing, which involves determining what a company needs and developing plans that will determine the path it may follow to meet its goals.  Additionally, it enables us to expand the business and communicate with our stakeholders/commuters in various ways via digital channels and helps us to establish a good brand presence among the audience. 

        In my leisure time, I binge-watch sci-fi shows, read books, and sometimes consult D2C brands for marketing and branding, which allows me to explore different industries.`,
        profile: "iqra.jpeg"
      },
      {
        name: "Gautham Makam",
        designation: "Zonal Manager - Transit Solutions",
        linkedin: "https://www.linkedin.com/in/gautham-makam-a1802b17",
        bio: `Gautham is a Zonal Manager for Tummoc, looking after transit solutions. He is responsible for generating revenue through B2B channels. He does this by onboarding clients for ticketing and ETM solutions. He handles vendor relationships as well. In his free time, he likes to watch football and listen to music.`,
        profile: "gautam.png"
      },
      {
        name: "Alfi Jose",
        designation: "System Engineer - Operations",
        linkedin: "https://www.linkedin.com/in/alfijose/",
        bio: `Alfi is one of our Systems Engineer who looks into operations in Kerala. He's responsble for managing ground operations for Tummoc and KSRTC. He is responsible for coordination and management of schedules and trips. Alfi loves to travel on his motorcycle. He's quite the adventurer, a gamer, and is also interested in science fiction.`,
        profile: "alif.jpg"
      },
      {
        name: "Sharath GD",
        designation: "Trainee Engineer",
        linkedin: "https://www.linkedin.com/in/sharath-gd-422020246/",
        bio: `Sharath is a Trainee Engineer at Tummoc. He is responsible for Quality Assurance (QA), for which he has to determine whether a product or feature meets specified requirements  in addition to maintaining certain standards for upcoming products or features. He also looks into improving software development processes and preventing defects in the production process. He's a curious individual who is always up to learning something new and expanding his horizons.`,
        profile: "sarath.png"
      },
      {
        name: "Abhilasha Panchal",
        designation: "Backend Developer",
        linkedin: "https://www.linkedin.com/in/abhipanchal2812/",
        bio: `Abhilasha is a software engineer who works with Tummoc. She works as a backend developer for the app. She's a motivated and enthusiastic person who's always looking for ways to learn and grow, both personally and professionally. In her free time, she likes to listen to music, sing and travel. `,
        profile: "abhilasha.png"
      },
      {
        name: "Bindya S",
        designation: "HR Trainee",
        linkedin: "https://www.linkedin.com/in/bindya-s-45b63821a/",
        bio: `Bindya is responsible for assisting our HR & Recruitment Manager with recruitment, job postings, looking for the right candidates for various job openings, coordinating with applicants and other tasks such as keeping track of employee details, etc.`,
        profile: "bindya.png"
      },
      {
        name: "Jayapal Reddy",
        designation: "Senior Graphic Designer",
        linkedin: "https://www.linkedin.com/in/jayapalready/",
        bio: `Jayapal is one of the designers who is responsible for designing graphics, illustrations and infographics for Tummoc's social media, marketing collaterals, etc. He also contributes ideas to the team for new campaigns and posts. He's creative and is great at thinking outside the box.`,
        profile: "jayapal.png"
      },
      // {
      //   name: "Upasana Baruah",
      //   designation: "Senior Graphic Designer",
      //   linkedin: "https://www.linkedin.com/in/upasana-baruah-4b90a6129/",
      //   bio: `Upasana is a designer at Tummoc who looks after visuals and illustrations for online and offline collaterals, in addition to working with the team on fun new concepts. She's a creative individual and the same can be seen in her work. Upasana absolutely loves KPop and KDramas. `,
      //   profile: "upasana.png"
      // },
      {
        name: "Riddhi Panchal",
        designation: "Social Media Analyst",
        linkedin: "https://www.linkedin.com/in/riddhi-panchal-7950aa178/",
        bio: `Riddhi is a social media analyst, whose bestrfiend is a tripod. She works with the team to deliver all of the content you see on our social media. She also loves to travel, sing and has a passion for learning new languages.`,
        profile: "riddhi.png"
      },
      {
        name: "Srusti S Pradhan",
        designation: "Trainee Software Developer",
        linkedin: "https://www.linkedin.com/in/srusti-samprada-pradhan-5274b420a/",
        bio: `Srusti is a Software Development Trainee at Tummoc. She's a Computer Science Engineer who is skilled in Java, J2EE, and MySQL. She's an eager learner and loves to cook and dance. `,
        profile: "srusti.png"
      },
      {
        name: "Bharani C",
        designation: "Trainee - Customer Support",
        linkedin: "https://www.linkedin.com/in/bharani-c-48619521b",
        bio: `Bharani is a Customer Support Trainee at Tummoc. She is responsible for interacting with users, solving their queries and maintaining records of customer interactions. She  also collects feedback from users so we can keep improving the app. In her free time, she spends time playing Free Fire and cooking.`,
        profile: "bharani.png"
      },
      {
        name: "Akash Sabu",
        designation: "System Engineer",
        linkedin: "https://www.linkedin.com/in/akash-sabu-06395417b",
        bio: `Akash is a System Engineer at Tummoc. He is responsible for designing, deploying and managing systems using hardware, software and networks. Additionally, he is the one who ensures that systems run efficiently and securely for Tummoc and its partners. He enjoys listening to music, doing courses online and recently he has also been trying to build a habit of reading books.`,
        profile: "akash.png"
      },
      // {
      //   name: "Koushik NG",
      //   designation: "Data Operations Executive",
      //   linkedin: "https://www.linkedin.com/in/koushik-n-g-0a7581254/",
      //   bio: `Koushik is a Data Operations Executive at Tummoc. He's responsible for collecting and analysing data related to company operations, as well as for identifying patterns and trend in data. He also works on sourcing, preparing and analysing transit data for different cities. In his free time, he enjoys playing sudoku and solving other types of puzzles.`,
      //   profile: "koushik.png"
      // },
    ];

    const investor = [
      // {
      //   name: "Vineet Singh",
      //   linkedin: "https://www.linkedin.com/in/vineet-singh-pmp-pmi-659ba931/",
      //   profile: "vineet.jpeg"
      // },
      // {
      //   name: "Deependra Singh Bisht",
      //   linkedin: "https://www.linkedin.com/in/deependrabisht/",
      //   profile: "deependra.jpeg"
      // },
      {
        name: "Krishna Dhakshinamoorthy",
        linkedin: "https://www.linkedin.com/in/krishna-d-ba26191b/",
        profile: "krishna.jpg"
      },
      {
        name: "Alan Aim",
        // linkedin: "https://www.linkedin.com/in/krishna-d-ba26191b/",
        profile: "alan.jpg"
      },
      {
        name: "We Founder Circle",
        website: "https://www.wefoundercircle.com/",
        profile: "wfc.png"
      },
      // {
      //   name: "Anil Muthyala",
      //   linkedin: "https://www.linkedin.com/in/anil-muthyala-5499bb9/",
      //   profile: "anilmuthyala.png"
      // },
      // {
      //   name: "Bijay Mishra",
      //   linkedin: "https://www.linkedin.com/in/bijay-mishra-034a1116/",
      //   profile: "bijaymishra.png"
      // },
      
    ]

    const advisor = [
      {
        name: "Vineet Singh",
        linkedin: "https://www.linkedin.com/in/vineet-singh-pmp-pmi-659ba931/",
        profile: "vineet.jpeg"
      },
      {
        name: "Deependra Singh Bisht",
        linkedin: "https://www.linkedin.com/in/deependrabisht/",
        profile: "deependra.jpeg"
      },
      {
        name: "Anil Muthyala",
        linkedin: "https://www.linkedin.com/in/anil-muthyala-5499bb9/",
        profile: "anilmuthyala.png"
      },
      {
        name: "Bijay Mishra",
        linkedin: "https://www.linkedin.com/in/bijay-mishra-034a1116/",
        profile: "bijaymishra.png"
      },
    ]

    const modalCss = {
      modal: {
        maxWidth: "800px",
        position: "relative",
        padding: "0.5rem 2rem 2rem 2rem",
        background: "#ffffff",
        backgroundClip: "padding-box",
        boxShadow: "0 12px 15px 0 rgb(0 0 0 / 25%)",
        borderRadius: "1rem",
        // height: '55vh',
        height: "auto",
        maxHeight: "70vh",
        // width: '50vh',
        // backgroundImage: 'radial-gradient(circle 70vh at 50% 100%, #ff9b3d, #ff7b00, #ff9027)',
        backgroundSize: "100% 40%",
        backgroundRepeat: "no-repeat"
      },

      closeButton: {
        display: "none"
      }
    };

    return (
      <>
        <Helmet>
          <title>Book Bus & Metro Tickets in Delhi & Bengaluru, Book Private Ride and More|Tummoc App</title>
          <link rel="canonical" href="https://tummoc.com/about" />
          <meta name="title" content="Book Bus & Metro Tickets in Delhi & Bengaluru, Book Private Ride and More|Tummoc App" />
          <meta name="description" content="Tummoc Is a Daily Travel App That Allows You to Book Private Rides, Buy Bmtc Bus Passes, Book Dtc Tickets Online, Live Track Dtc Buses and Plan Your Daily Travel." />
        </Helmet>
        <div>
          <Header />

          <div className="top">
            <section>
              {/* <div className="aboutSectionOne">
                <div className="row abtsecimageheight">
                  <div className="col-lg-5 col-md-5 col-sm-5 col-5 headtext th-c">
                    <p className="head1 th-1" style={{position: 'absolute'}}>
                      We are Tummoc. We get you there.
                    </p>
                    <p className="head2 th-2">
                      Tummoc is an end-to-end, intra city commute solution
                      provider. We are the only app that connects all public
                      transport and ride share options to craft the best suited
                      route for everyone no matter how diverse their needs.
                    </p>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-7 col-7"></div>
                </div>
              </div> */}

              <div style={{ position: 'relative' }}>
                <img src="/images/team.jpg" alt="founders" style={{ width: '100%' }} />
                <div style={{ position: 'absolute', width: '100%', height: '100%', top: '0', background: '#00000052', opacity: '0.5' }}></div>
                <p className="head1 th-1" style={{ position: 'absolute', left: '1rem' }}>
                  We are Tummoc. <br /> We get you there.
                </p>
              </div>

              <div className="aboutSectionTwo">
                <div className="row" style={{ margin: 0 }}>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p className="asthead gradientText headb">
                      The absurdity of a 20 minute wait for a 10 minute ride in
                      the metro or a bus got to us.
                    </p>
                  </div>
                </div>
                <div className="row pt-5 pb-5" style={{ margin: 0 }}>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <p className="astos headb">Our Story</p>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <p className="head2">
                      Tummoc was the answer to the hassle of regular transportation options and the
                      struggle to find reasonably priced short term rides in Bangalore. The absurdity of a
                      20-minute wait for a 10-minute ride in the metro or a bus, and the difficulty fulfilling
                      the first and last mile requirements when using public transport, formed the base of the Tummoc
                      experience.<br></br><br></br>The core of our motivation to create Tummoc as an offering was that
                      while India has an extensive network of public transport, which are priced affordably as well,
                      we still don’t see public transport as a legitimate commute option. It’s only ever chosen out of
                      desperation, and never by choice. What motivated us further was the preference public transport
                      gets in first world countries because of the customer-first approach making it an accessible and
                      convenient option. We wanted to give everyone access to the widespread intricate network of
                      public transport this country already has and make public transport a legitimate choice of
                      intracity commute.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="midsection">
              <div className="vision">
                <div className="row" style={{ margin: 0, padding: '1rem' }}>
                  <div className="col-lg-7">
                    <h1 className="headb" style={{ fontWeight: '700', paddingTop: '1rem' }}>Our Vision</h1>

                    <p style={{ marginTop: '1rem' }}>
                      Our vision is to make public transport more accessible to you by
                      creating a data-driven, all-in-one transit
                      platform. Thus, solving the problems faced by millions of
                      commuters through end-to-end connectivity and an efficient
                      commute.
                    </p>
                  </div>

                  <div className="col-lg-5 text-center">
                    <img
                      src="/images/vision.png"
                      alt="newsimage"
                      className="vis-img"
                    />
                  </div>
                </div>
              </div>

              <div className="mission">
                <div className="row" style={{ margin: 0, padding: "1rem" }}>
                  <div className="col-lg-5 text-center mis-1">
                    <img
                      src="/images/mission.png"
                      alt="newsimage"
                      className="mis-img"
                    />
                  </div>

                  <div className="col-lg-7">
                    <h1 className="headb" style={{ fontWeight: "700", paddingTop: "1rem" }}>
                      Our Mission
                    </h1>

                    <p style={{ fontSize: "1.3rem" }}>
                      Tummoc is on a mission to be the pioneer of a smart,
                      cashless commute in India by:
                    </p>

                    <ul className="m-ul" style={{ listStyleType: "square" }}>
                      <li className="m-li">
                        Providing actionable information on the best available
                        public transport options.
                      </li>
                      <li className="m-li">
                        Offering easy and flexible contactless ticketing options
                        for all public as well as private modes of transport.
                      </li>
                      <li className="m-li">
                        Integrating all MaaS (Mobility as a Service) providers
                        to offer first and last-mile options such as electric
                        vehicles, cycles, autos, bikes, cabs.
                      </li>
                      <li className="m-li">
                        Connecting with urban commuters to acquire significant
                        mindshare.
                      </li>
                      <li className="m-li">
                        Creating employment opportunities for last-mile partners
                        while ensuring the healthy growth of the organization
                        and investors alike.
                      </li>
                      <li className="m-li">
                        Enabling proper asset distribution planning by MaaS
                        providers based on existing and projected demand.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section className="tm-sec">
              <div
                style={{
                  color: "#0e2d39",
                  textAlign: "center",
                  marginBottom: "5rem"
                }}
              >
                <h1 className="headb" style={{ fontWeight: "800" }}>The Tummoc Timeline</h1>
              </div>

              <div className="tm-c">
                {isBrowser && <Timeline items={items} />}

                {isMobile && <VerticalTimeline items={items} />}
              </div>

              <img
                src="/images/timeline.png"
                className="timeline-img"
                alt="newsimage"
              />
            </section>

            <section>
              <div
                className="sectionSix px-5 pt-5"
                style={{ height: "auto" }}
              >
                <div className="row" style={{ margin: 0 }}>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 testimonial mt-md-5">
                    <p className="news-head headb">Meet the Team</p>
                    <p className="news-txt">
                      These are the humans behind India’s Smart Commute Revolution.
                    </p>
                  </div>

                  <div
                    className="col-lg-8 col-md-8 col-sm-8 col-12 mt-md-5"
                  >
                    <div className="row">
                      {team &&
                        team.map((item, i) => {
                          return (
                            i <= 5 && (
                              <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div
                                  className="card team-card"
                                  onClick={() => this.onOpenCloseModal(item)}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      // src={`images/${item.profile}`}
                                      src={item.profile_picture}
                                      className="card-img team-img"
                                      style={{ borderRadius: "1rem" }}
                                      alt="newsimage"
                                    />
                                    <a href={`${item.linkedin_url}`} target="_blank"><i className="fab fa-linkedin in-icon"></i></a>
                                  </div>

                                  <div
                                    className="card-body"
                                    style={{ padding: "1rem" }}
                                  >
                                    <p className="card-text team-name txt-grad">
                                      {item.name}
                                    </p>
                                    <h6 className="card-title">
                                      {" "}
                                      {item.designation}{" "}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            )
                          );
                        })}

                      {!this.state.open && (
                        <div
                          className="col-lg-12 exp-txt"
                          style={{ padding: 0 }}
                        >
                          <p
                            onClick={this.handleClick}
                            style={{ cursor: "pointer" }}
                          >
                            Expand <i className="fa fa-chevron-down"></i>
                          </p>
                        </div>
                      )}

                      {this.state.open &&
                        team &&
                        team.map((item, i) => {
                          return (
                            i > 5 && (
                              <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div
                                  className="card team-card"
                                  value={i}
                                  onClick={() => this.onOpenCloseModal(item)}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      // src={`images/${item.profile}`}
                                      src={item.profile_picture}
                                      className="card-img team-img"
                                      style={{ borderRadius: "1rem" }}
                                      alt="newsimage"
                                    />

                                    <a href={`${item.linkedin_url}`} target="_blank"><i className="fab fa-linkedin in-icon"></i></a>
                                  </div>

                                  <div
                                    className="card-body"
                                    style={{ padding: "1rem" }}
                                  >
                                    <p className="card-text team-name txt-grad">
                                      {item.name}
                                    </p>
                                    <h6 className="card-title">
                                      {" "}
                                      {item.designation}{" "}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            )
                          );
                        })}

                      {this.state.open && (
                        <div
                          className="col-lg-12 exp-txt"
                          style={{ padding: 0 }}
                        >
                          <p
                            onClick={this.handleClick}
                            style={{ cursor: "pointer" }}
                          >
                            Collapse <i className="fa fa-chevron-up"></i>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>


              <div className="sectionSix px-5" style={{ height: "auto" }}>
                <div className="row" style={{ margin: 0 }}>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 testimonial">
                    <p className="news-head headb">The Advisors & Investors</p>
                  </div>

                  <div className="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div className="row">
                      {advisor &&
                        advisor.map((item, i) => {
                          return <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="card team-card">
                              <div style={{ position: 'relative' }}>
                                <img
                                  src={`images/${item.profile}`}
                                  className="card-img team-img"
                                  style={{ borderRadius: "1rem" }}
                                  alt="newsimage"
                                />
                                <a href={`${item.linkedin}`} target="_blank"><i className="fab fa-linkedin in-icon"></i></a>
                              </div>

                              <div
                                className="card-body"
                                style={{ padding: "1rem" }}
                              >
                                <p className="card-text team-name txt-grad">
                                  {item.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="sectionSix px-5" style={{ height: "auto", paddingBottom: "10rem" }}>
                <div className="row" style={{ margin: 0 }}>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 testimonial">
                    <p className="news-head headb">The Investors</p>
                  </div>

                  <div className="col-lg-8 col-md-8 col-sm-8 col-12 mb-md-5">
                    <div className="row">
                      {investor &&
                        investor.map((item, i) => {
                          return <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="card team-card">
                              <div style={{ position: 'relative' }}>
                                <img
                                  src={`images/${item.profile}`}
                                  className="card-img team-img"
                                  style={{ borderRadius: "1rem" }}
                                  alt="newsimage"
                                />
                                {item.linkedin && <a href={`${item.linkedin}`} target="_blank" rel="noreferrer"><i className="fab fa-linkedin in-icon"></i></a>}
                                {item.website && <a href={`${item.website}`} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt in-icon" style={{ padding: "0.7rem 0.7rem" }}></i></a>}

                              </div>

                              <div
                                className="card-body"
                                style={{ padding: "1rem" }}
                              >
                                <p className="card-text team-name txt-grad">
                                  {item.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                styles={modalCss}
                open={this.state.openModal}
                onClose={() => this.onOpenCloseModal("")}
                center
              >
                <button
                  onClick={() => this.onOpenCloseModal("")}
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none"
                  }}
                >
                  <i
                    className="fab fa-times"
                    style={{
                      position: "absolute",
                      top: "14px",
                      right: "14px",
                      border: "none",
                      padding: "0",
                      cursor: "pointer"
                    }}
                  ></i>
                </button>

                <div
                  className="row v-sec"
                  style={{ margin: 0, textAlign: "left" }}
                >
                  <div className="col-lg-7 col-md-7 col-sm-12 col-12 v-sec-1">
                    <h4 className="txt-grad p3">
                      {this.state.modalValue.name}
                    </h4>
                    <h6 style={{ marginTop: "0.3rem" }}>
                      {this.state.modalValue.designation}
                    </h6>
                    <p style={{ marginTop: "1rem", fontSize: "0.8rem" }}>
                      {this.state.modalValue.about}
                    </p>
                  </div>

                  <div
                    className="col-lg-5 col-md-5 col-sm-12 col-12 v-sec-2"
                    style={{ marginBottom: "1rem" }}
                  >
                    <div style={{ position: 'relative' }}>
                      <img 
                      // src={`images/${this.state.modalValue.profile}`} 
                      src={this.state.modalValue.profile_picture}
                      className="card-img team-img"
                        style={{ borderRadius: "1rem", height: "auto", width: "100%" }}
                        alt="newsimage"
                      />
                      <a href={`${this.state.modalValue.linkedin_url}`} target="_blank"><i className="fab fa-linkedin in-icon" style={{ bottom: "1rem", right: "2rem" }}></i></a>
                    </div>

                  </div>
                </div>
              </Modal>
            </section>

            <section style={{ position: "relative" }}>
              <section
                className="vote-section"
                style={{ position: "absolute", top: "-10rem", width: "100%" }}
              >
                <div style={{ background: "#fff", borderRadius: "2rem" }}>
                  <div className="link-section">
                    <Applink />
                  </div>
                </div>
              </section>

              <Footer />
            </section>
          </div>
        </div>
      </>
    );
  }
}
