import React from "react";
import Footer from "./footer";
import Applink from "./applink";
import Header from "./header";
import { Helmet } from "react-helmet";

export default class Vote extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Tummoc | Terms of Usage</title>
                    <link rel="canonical" href="https://tummoc.com/terms-of-usage" />
                </Helmet>
                <div>
                    <Header />

                    <section className="t-pd">
                        <div className="container-fluid  privacy">
                            <div className="col-md-12">
                                <h2 class="text-center" style={{ marginBottom: '1rem' }}>
                                    <strong>Terms of usage</strong>
                                </h2>
                                <div>
                                    <p className="terms_text">
                                        In terms of Information Technology statutes, this document is
                                        an electronic record. Being generated by a computer system it
                                        does not require any physical or digital signatures.
                                    </p>
                                    <p className="terms_text">
                                        This document is published in accordance with the provisions
                                        of Information Technology regulations that require publishing
                                        the rules and regulations, privacy policy and Terms of Service
                                        for access or usage of:
                                    </p>
                                    <ul className="terms_text">
                                        <li>
                                            The Website/Application www.tummoc.com(hereinafter referred
                                            to as ‘Website/Application), owned by Transhelp Technologies
                                            Pvt Ltd, a private limited company incorporated under the
                                            Companies Act 1956 /2013 having its registered office at 5th
                                            Floor, No.501-509, Tower D,No.150, Diamond District, Old
                                            Airport Road, Near Domlur Flyover, Kodihalli, Bangalore,
                                            Bengaluru (Bangalore) Urban, Karnataka, 560008, India
                                            represented by its members, where such expression shall,
                                            unless repugnant to the context thereof, be deemed to
                                            include its respective legal heirs, representatives,
                                            administrators, permitted successors and assigns;
                                        </li>
                                        <li>
                                            For the purpose of these Terms of Service, along with any
                                            amendments to the same, and wherever the context so
                                            requires:
                                        </li>
                                    </ul>
                                    <ul className="terms_text">
                                        <ul>
                                            <li>
                                                “You” or “User” “Customer” shall mean any natural or legal
                                                person who has agreed to become a user of the
                                                Website/Application by logging into the
                                                Website/Application. The Website/Application also provides
                                                certain services without registration/acceptance, and such
                                                provision of services does not absolve You of this
                                                contractual relationship.
                                            </li>
                                            <li>
                                                “We”, “Us”, “Our”, ‘Company’ shall mean Transhelp
                                                Technologies Pvt Ltd.
                                            </li>
                                            <li>
                                                “Agreement” shall mean and refer to this Terms of Service,
                                                including any amendments that may be incorporated into it.
                                            </li>
                                            <li>
                                                “Agreement Period” shall refer to the period for which the
                                                User engages the services of the Website/Application.
                                            </li>
                                            <li>
                                                “Services” shall mean the services provided by the Company
                                                on the Website/Application.
                                            </li>
                                            <li>
                                                “Third Party” shall mean and refer to any individual(s),
                                                company or entity apart from the User and the Company.
                                            </li>
                                        </ul>
                                        <li>
                                            The headings of each section in this Agreement are only for
                                            the purpose of organising the various provisions under this
                                            Agreement in an orderly manner. These headings shall not be
                                            used by either party to interpret the provisions contained
                                            under them in any manner. Further, the headings have no
                                            legal or contractual value.
                                        </li>
                                        <li>
                                            By using the Website/Application, You accept and agree to be
                                            bound by this Agreement, the Privacy Policy, as well as
                                            rules, guidelines, policies, terms, and conditions
                                            applicable to any service that is provided by the
                                            Website/Application that shall be deemed to be incorporated
                                            into this Agreement and shall be considered as part and
                                            parcel of this Agreement
                                        </li>
                                        <li>
                                            Your use of Our Website/Application is evidence that You
                                            have read and agreed to be contractually bound by these
                                            Agreement and Our Privacy Policy. Please read both
                                            carefully. The use of this Website/Application by You is
                                            governed by this policy and any policy so mentioned by terms
                                            of reference.
                                        </li>
                                        <li>
                                            If You do not agree with any of these terms, please
                                            discontinue using the Website/Application.
                                        </li>
                                        <li>
                                            We hold the sole right to modify the Agreement without prior
                                            permission from You or providing notice to You. The
                                            relationship creates on You a duty to periodically check the
                                            Agreement and stay updated on its requirements. If You
                                            continue to use the Website/Application or avail any of its
                                            Services without registration following such change, this is
                                            deemed as consent by You to the so amended policies. Your
                                            continued use of the Website/Application is conditioned upon
                                            Your compliance with the Agreement, including but not
                                            limited to compliance with the Agreement even after
                                            alterations, if any.
                                        </li>
                                        <li>
                                            <b>ABOUT US: </b>The Company is a technology driven startup
                                            based in Bangalore. We have an effortless, User friendly
                                            Website and Application for Users, which
                                            providespersonalized services through verified bikers in a
                                            particular area, Our Services includes last mile pick up and
                                            drop and tiffin delivery services.
                                        </li>
                                        <li>
                                            <b>HOW WEBSITE/APPLICATION OPERATES:</b> Users can avail Our
                                            Servicesbyfilling the request form available in the
                                            Website/Application. After which You can access the Services
                                            of the Website /Application. Your request made is intimated
                                            to Our bikers and they shall come at the location You have
                                            opted for the Service. You can track the status and position
                                            of the bikers on Our Website/Application.
                                            <br />
                                            <ul><li>
                                                <b>Tummoc Driver app:</b> The main purpose of the driver app is to provide new rides from the customers nearby their location. This app is similar to any other ola/uber driver app where based on drivers current location new rides will be assigned.
                                                <p>Background location is mandatory to know the driver's location in real time, most of the time drivers are driving cant keep accessing the app in foreground, and there moving location is required to know their live location for many functional and security purpose</p>
                                                <ol>
                                                    <li>To assign new & relevant rides based on their current location</li>
                                                    <li>To track driver’s location and share ETA to the customers</li>
                                                    <li>Track ongoing ride and calculate ETA to the destination</li>
                                                </ol>
                                            </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <b>ACCOUNT REGISTRATION:</b> Registration on the
                                            Website/Application is mandatory.The Users shall register
                                            with the Website/Application, which would help them to avail
                                            the Services of the Website/Application. A non-registered
                                            User can browse through the content of the
                                            Website/Application. The Website/Application uses Facebook
                                            and Google platforms as a medium for signing up, or signup
                                            directly. Information relating to name, mobile no, email id,
                                            gender etc. shall be collected from the User. The User data
                                            collected by the Website/Application shall be through the
                                            above mediums which will be provided by the intended User
                                            through their Facebook or Google profile. However, kindly
                                            note that the manner in which Facebook and Google uses,
                                            stores and discloses your information is governed solely by
                                            its policies, and the Website bears no
                                            liabilities/responsibility for its privacy practices and/or
                                            other actions of any third party site or service that may be
                                            enabled within the Service.Registration for this Website is
                                            available only to those above the age of 16 years, barring
                                            those “Incompetent to Contract” which inter alia include
                                            insolvents. If You are a minor and wish to use the Website,
                                            You may do so through Your legal guardian and the Firm
                                            reserves the right to terminate Your account on knowledge of
                                            You being a minor and having registered on the Website or
                                            availing any of its Services.Further, at any time during
                                            Your use of this Website, including but not limited to the
                                            time of registration, You are solely responsible for
                                            protecting the confidentiality of Your username and
                                            password, and any activity under the account shall be deemed
                                            to have been done by You. In the case that You provide Us
                                            with false and/or inaccurate details or We have reason to
                                            believe You have done so, We hold the right to permanently
                                            suspend Your account. You are responsible for safeguarding
                                            your password. You agree that you will not disclose your
                                            password to any third party and that you will take sole
                                            responsibility for any activities or actions under your
                                            account, whether or not you have authorized such activities
                                            or actions. You will immediately notify us of any
                                            unauthorized use of your account.
                                        </li>
                                        <li>
                                            <b>COMMUNICATIONS:</b> By using this Website/Application, it
                                            is deemed that You have consented to receiving telephonic
                                            calls, SMSs and/or emails from Us at any time We deem fit.
                                            Such communications shall be sent to You on the telephone
                                            number and/or email address provided by You for the use of
                                            this Website/Application which are subject to our Privacy
                                            Policy. These communications include, but are not limited to
                                            contacting You through information received from Third
                                            Parties. Such communications by Us is for purposes that
                                            inter alia include clarification calls, marketing calls and
                                            promotional calls. In case You wish to stop receiving
                                            notifications from Us with regard to marketing and
                                            promotional calls/ with regard to any communication received
                                            from Us, You may do so by sending an support
                                            <span>@tummoc.com</span>
                                        </li>
                                        <li>
                                            <b>CHARGES:</b> The membership of this Website/Application is
                                            free and this includes the browsing of the
                                            Website/Application. On rendering the Service Users shall be
                                            intimated of the charges, and shall make the payment
                                            immediately after rendering the Serviceand shall make the
                                            payment immediately after rendering the Service. The Company
                                            reserves the right to amend the fee policy for use of the
                                            Website/Applicationand the charges for any or all Services
                                            rendered. In such an event, the User will be intimated of
                                            the same when it attempts to access the Website/Application,
                                            and the User shall have the option of declining to avail the
                                            Services offered on the Website/Application. Any such
                                            change, if made, shall come into effect immediately upon
                                            such change being notified to the User, unless specified
                                            otherwise.
                                        </li>
                                        <li>
                                            <b>MODE OF PAYMENT:</b> The following payment options are
                                            available on the Website/Application:
                                        </li>
                                        <ul>
                                            <li>
                                                Domestic and international credit cards issued by banks
                                                and financial institutions that are part of the Visa,
                                                Master Card, Mastero & Amex Card networks;
                                            </li>
                                            <li>Visa, Master Card, Rupay, Mastero Debit cards;</li>
                                            <li>
                                                Net banking/Direct Debit payments from select banks.
                                            </li>
                                            <li>CC Avenue</li>
                                            <li>Paytm Wallet</li>
                                        </ul>
                                        <li>
                                            As prescribed by the financial institutions issuing the
                                            credit or debit cards affiliated with Visa / Master Card /
                                            Mastero / the User will be required to submit his/her
                                            16-digit card number, card expiry date and 3-digit CVV
                                            number (usually on the reverse of the card) while making an
                                            online transaction. The User must also have enrolled his/her
                                            card with VBV (Verified by Visa), MSC (MasterCard Secure
                                            Code) or any other applicable provider in order to complete
                                            the transaction. The User is hereby expressly made aware
                                            that his/her card statements will reflect that a payment has
                                            been made in favour of the Company. The User is further
                                            aware that in case of third party statements, including bank
                                            and credit card statements, the merchant name may appear in
                                            an abbreviated format, and the Company has no control over
                                            the same. To successfully subscribe on the
                                            Website/Application, the User is required to complete the
                                            transaction by making the payment for the services opted
                                            for. Payments could be also made via cash on the completion
                                            of the service.
                                        </li>
                                        <li>
                                            <b>TAXES:</b> The User takes full responsibility for payment
                                            of all taxes and fees that are levied or arise in the course
                                            of use of the Website/Application, including but not limited
                                            to downloading content and availing its service partner’s
                                            service.
                                        </li>
                                        <li>
                                            <b>SECURITY:</b> Transactions on the Website/Application are
                                            secure and protected. Any information entered by the User
                                            when transacting on the Website/Application is encrypted to
                                            protect the User against unintentional disclosure to third
                                            parties. The User’s credit and debit card information is not
                                            received, stored by or retained by the Company /
                                            Website/Application in any manner.
                                        </li>
                                        <li>
                                            <b>TERM / TERMINATION:</b> These Terms shall continue to form
                                            a valid and binding contract between the Parties, and shall
                                            continue to be in full force and effect until the User
                                            continues to access and use the Website/Application.You may
                                            terminate Your use of the Service at any time by ceasing to
                                            use the Service. We may terminate these Terms and close Your
                                            account at any time without notice, if we cease to provide
                                            the Services. We may suspend or terminate Your use of and
                                            access to the Website/Application at any time and for any
                                            reason, in our sole discretion. Such suspension or
                                            termination shall not limit our right to take any other
                                            action against You that we consider appropriate.
                                        </li>
                                        <li>
                                            <b>USER’S OBLIGATIONS:</b> The User undertakes to fulfil the
                                            following obligations. Failure to satisfy any of these
                                            obligations gives Us the right to permanently suspend Your
                                            account and/or claim damages for any losses that accrue to
                                            Us or additional costs that may be imposed on us.
                                        </li>
                                        <ul>
                                            <li>
                                                You hereby certify that You are at least 16 years of age.
                                            </li>
                                            <li>
                                                You agree to ensure the email address provided in Your
                                                account registration is valid at all times and shall keep
                                                Your contact information accurate and up-to-date.
                                            </li>
                                            <li>
                                                You agree to comply with all local laws and regulations
                                                governing the downloading, installation and/or use of the
                                                Website/Application, including, without limitation to, any
                                                usage rules set forth in this Agreement.
                                            </li>
                                            <li>You undertake not to:</li>
                                            <ul>
                                                <li>
                                                    Cut, copy, distribute, modify, recreate, reverse
                                                    engineer, distribute, disseminate post, publish or
                                                    create derivative works from, transfer, or sell any
                                                    information or software obtained from the
                                                    Website/Application. For the removal of doubt, it is
                                                    clarified that unlimited or wholesale reproduction,
                                                    copying of the content for commercial or non-commercial
                                                    purposes and unwarranted modification of data and
                                                    information within the content of the
                                                    Website/Application is not permitted. Should You want to
                                                    engage in one or more such actions, prior permission
                                                    from Us must be obtained;
                                                </li>
                                                <li>
                                                    access (or attempt to access) the Website/Application
                                                    and/or the materials or services by any means other than
                                                    through the interface that is provided by the
                                                    Website/Application . The use of deep-link, robot,
                                                    spider or other automatic device, program, algorithm or
                                                    methodology, or any similar or equivalent manual
                                                    process, to access, acquire, copy or monitor any portion
                                                    of the Website/Application or Content, or in any way
                                                    reproduce or circumvent the navigational structure or
                                                    presentation of the Website/Application or , materials
                                                    or any Content, to obtain or attempt to obtain any
                                                    materials, documents or information through any means
                                                    not specifically made available through the
                                                    Website/Application is prohibited. You acknowledge and
                                                    agree that by accessing or using the Website/Application
                                                    Services, You may be exposed to content from other Users
                                                    or Third Parties that You may consider offensive,
                                                    indecent or otherwise objectionable. We disclaim all
                                                    liabilities arising in relation to such offensive
                                                    content on the Website/Application. Further, You may
                                                    report such offensive content;
                                                </li>
                                                <li>
                                                    use the Website/Application or in any manner that may
                                                    impair, overburden, damage, disable or otherwise
                                                    compromise (i) Company’s Services; (ii) any other
                                                    party’s use and enjoyment of company’s Services; or
                                                    (iii) the services and products of any Third Party
                                                    (including, without limitation to, the Authorized
                                                    Device);
                                                </li>
                                                <li>
                                                    sue the Services or materials for any unlawful purposes
                                                    or to conduct any unlawful activity, including, but not
                                                    limited to, fraud, embezzlement, money laundering or
                                                    identity theft;
                                                </li>
                                                <li>
                                                    abuse, harass, threaten, defame, disillusion, erode,
                                                    abrogate, demean or otherwise violate the legal rights
                                                    of others;
                                                </li>
                                                <li>
                                                    engage in any activity that interferes with or disrupts
                                                    access to the Website/Application or the Services (or
                                                    the servers and networks which are connected to the );
                                                </li>
                                                <li>
                                                    upload or distribute files that contain viruses,
                                                    corrupted files, or any other similar software or
                                                    programs that may damage the operation of the
                                                    Website/Application or another’s mobile phone;
                                                </li>
                                                <li>
                                                    download any file posted on the Website/Application that
                                                    You know, or reasonably should know, cannot be legally
                                                    distributed in such manner;
                                                </li>
                                                <li>
                                                    probe, scan or test the vulnerability of the
                                                    Website/Application or any connected network , nor
                                                    breach the security or authentication measures on the
                                                    Website/Application. You may not reverse look-up, trace
                                                    or seek to trace any information on any other user, of
                                                    or visitor to, the Website/Application , or exploit the
                                                    Service or information made available or offered by or
                                                    through the Website/Application, in any way whether or
                                                    not the purpose is to reveal any information, including
                                                    but not limited to personal identification information,
                                                    other than Your own information, as provided for by the
                                                    ;
                                                </li>
                                                <li>
                                                    disrupt or interfere with the security of, or otherwise
                                                    cause harm to, the Website/Application, systems
                                                    resources, servers or networks connected to or
                                                    accessible through the Website/Application or any
                                                    affiliated or linked s;
                                                </li>
                                                <li>
                                                    use the Website/Application or any material or Content
                                                    for any purpose that is unlawful or prohibited by these
                                                    Agreement, or to solicit the performance of any illegal
                                                    activity or other activity which infringes the rights of
                                                    this Website/Application or other Third Parties;
                                                </li>
                                                <li>
                                                    violate any applicable laws or regulations for the time
                                                    being in force within or outside Your home country;
                                                </li>
                                                <li>
                                                    violate any code of conduct or other guidelines, which
                                                    may be applicable for or to any particular Service;
                                                </li>
                                                <li>
                                                    Publish, post, disseminate, any information which is
                                                    grossly harmful, harassing, blasphemous, defamatory,
                                                    obscene, pornographic, paedophilic, libellous, invasive
                                                    of another’s privacy, hateful, or racially, ethnically
                                                    objectionable, disparaging, relating or encouraging
                                                    money laundering or gambling, or otherwise unlawful in
                                                    any manner whatever; or unlawfully threatening or
                                                    unlawfully harassing including but not limited to
                                                    “indecent representation of women” within the meaning of
                                                    the Indecent Representation of Women (Prohibition) Act,
                                                    1986.
                                                </li>
                                                <li>
                                                    threaten the unity, integrity, defence, security or
                                                    sovereignty of Your home country, friendly relations
                                                    with foreign states, or public order or causes
                                                    incitement to the commission of any cognizable offence
                                                    or prevents investigation of any offence or is insulting
                                                    any other nation;
                                                </li>
                                                <li>
                                                    disseminate information through the Website/Application
                                                    that is false, inaccurate or misleading, or violate any
                                                    applicable laws or regulations for the time being in
                                                    force in or outside Your home country.
                                                </li>
                                            </ul>
                                        </ul>
                                        <li>
                                            <b>COPYRIGHT</b>
                                        </li>
                                        <ul>
                                            <li>
                                                All information, content, services and software displayed
                                                on, transmitted through, or used in connection with the
                                                Website/Application, including for example news articles,
                                                reviews, directories, guides, text, photographs, images,
                                                illustrations, audio clips, video, html, source and object
                                                code, trademarks, logos, and the like (collectively and
                                                hereinafter referred to as the “Content”), as well as its
                                                selection and arrangement, is owned by Us. You may use the
                                                Content only through the Website/Application, and solely
                                                for Your personal, non-commercial use.
                                            </li>
                                            <li>
                                                You may not, republish any portion of the Content on any
                                                Internet, Intranet or extranet site or incorporate the
                                                Content in any database, compilation, archive or cache.
                                                You may not distribute any Content to others, whether or
                                                not for payment or other consideration, and You may not
                                                modify, copy, frame, cache, reproduce, sell, publish,
                                                transmit, display or otherwise use any portion of the
                                                Content. You may not scrape or otherwise copy our Content
                                                without permission. You agree not to decompile, reverse
                                                engineer or disassemble any software or other products or
                                                processes accessible through the Website/Application, not
                                                to insert any code or product or manipulate the content of
                                                the Website/Application in any way that affects the user’s
                                                experience, and not to use any data mining, data gathering
                                                or extraction method.
                                            </li>
                                            <li>
                                                <b>COPYRIGHT COMPLAINTS:</b> We respect the intellectual
                                                property of others. If You believe Your work has been
                                                copied in a way that constitutes copyright infringement or
                                                are aware of any infringing material on the
                                                Website/Application, please contact Us at
                                                support@tummoc.com. Information and Content provided by
                                                the User by providing information to, communicating with,
                                                and/or placing material on, the Website/Application,
                                                including for example but not limited to, communication
                                                during any registration, You represent and warrant:
                                            </li>
                                            <ul>
                                                <li>
                                                    You own or otherwise have all necessary rights to the
                                                    content You provide and the rights to use it as provided
                                                    in this Agreement;
                                                </li>
                                                <li>
                                                    all information You provide is true, accurate, current
                                                    and complete, and does not violate these Agreement; and
                                                </li>
                                                <li>
                                                    the information and Content shall not cause injury to
                                                    any person or entity. Using a name other than Your own
                                                    legal name is prohibited.
                                                </li>
                                            </ul>
                                            <li>
                                                You authorize us to share the information across all our
                                                affiliated Website/Applications, to include the
                                                information in a searchable format accessible by users of
                                                the Website/Application, and to use Your name and any
                                                other information in connection with its use of the
                                                material You provide. You also grant the right to use any
                                                material, information, contained in any communication You
                                                send to us for any purpose whatsoever, including but not
                                                limited to developing, manufacturing and marketing
                                                products using such information. All rights in this
                                                paragraph are granted without the need for additional
                                                compensation of any sort to You.
                                            </li>
                                            <li>
                                                <b>AMENDMENT</b>
                                            </li>
                                            <ul>
                                                <li>
                                                    We may modify, replace, refuse access to, suspend or
                                                    discontinue the Services, partially or entirely, or add,
                                                    change and modify prices for all or part of the Services
                                                    for You or for all Users at any time and in Our sole
                                                    discretion. These changes shall become effective upon
                                                    providing a notice of the same to You via email/ the
                                                    home screen of the Website/Application. We further
                                                    reserve the right to withhold, remove and or discard any
                                                    content available as part of Your account, with or
                                                    without notice, if deemed by Us to be contrary to this
                                                    Agreement.
                                                </li>
                                                <li>
                                                    The Website/Application has no obligation to provide You
                                                    with a copy of the information You or any other User
                                                    provides on the Website/Application or that the
                                                    Website/Application has accessed.
                                                </li>
                                                <li>
                                                    Further, We reserve the right, in Our sole discretion,
                                                    to modify or replace any part of this Agreement at any
                                                    time, effective upon the date of giving Users notice of
                                                    the same. Notice shall be provided of such change via
                                                    email to the Users and/ or by posting a notice on the
                                                    home screen of the Website/Application. You and We both
                                                    understand that there may be instances of difficulty in
                                                    accessing or receiving email communication. We are not
                                                    responsible if any email notice gets caught by Your SPAM
                                                    folder, or if You do not see the email, or if You have
                                                    given us an incorrect email id or if for any other
                                                    reason You do not receive the email notice. Therefore,
                                                    we encourage You to frequently open the
                                                    Website/Application to monitor any changes. Your
                                                    continued use of or access to the Services following the
                                                    posting of any changes to this Agreement constitutes
                                                    acceptance of those changes. We may also, in the future,
                                                    offer new Services and/ or features through the
                                                    Website/Application. Such new features and/ or services
                                                    shall be subject to the terms and conditions of this
                                                    Agreement.
                                                </li>
                                            </ul>
                                            <li>
                                                <b>INDEMNIFICATION</b>
                                            </li>
                                            <ul>
                                                <li>
                                                    You agree to indemnify, hold harmless, and defend Us
                                                    from and against any and all liability, loss, claim,
                                                    damages, expense, or costs (including but not limited to
                                                    attorneys’ fees), incurred by or made Us in connection
                                                    with any claim arising from or related to :
                                                </li>
                                                <ul>
                                                    <li>
                                                        Your use or any Third Party’s use via Your account of
                                                        the Service provided by the Website/Application and
                                                        its Content;
                                                    </li>
                                                    <li>
                                                        Your disclosure of information to any Third Party,
                                                        either through the Website/Application or otherwise.
                                                        (Please refer to our Privacy Policy for more details
                                                        in this regard);
                                                    </li>
                                                    <li>
                                                        Any breach or violation of this Agreement, including
                                                        any amendment, or of any statute or regulation by You,
                                                        or any Third Party through Your account.
                                                    </li>
                                                </ul>
                                                <li>
                                                    You agree to fully cooperate in indemnifying Us at Your
                                                    expense. You also agree not to reach a settlement with
                                                    any party without Our consent.
                                                </li>
                                            </ul>
                                            <li>
                                                <b>GEOGRAPHICAL EXTENT:</b> The Website/Application
                                                currently provides its Service in Bangalore. We make no
                                                representation that the content available through Our
                                                Website/Application is appropriate or available for all
                                                states/countries. If You access or use the
                                                Website/Application in any countries where the
                                                Website/Application is prohibited, You are solely
                                                responsible for compliance with necessary laws and
                                                regulations for use of the Website/Application.
                                            </li>
                                            <li>DISCLAIMER OF WARRANTIES AND LIABILITIES</li>
                                            <ul>
                                                <li>
                                                    Except as otherwise expressly stated on the
                                                    Website/Application, all services offered on the
                                                    Website/Application are offered on an “as is” basis
                                                    without any warranty whatsoever, either express or
                                                    implied.
                                                </li>
                                                <li>
                                                    The User agrees and undertakes that he is accessing the
                                                    Website/Application and transacting at his/her sole risk
                                                    and that he is using his/her best and prudent judgment
                                                    before availing any service listed on the
                                                    Website/Application, or accessing/using any information
                                                    displayed thereon.
                                                </li>
                                                <li>
                                                    The User agrees that any kind of information, resources,
                                                    activities, recommendations obtained/availed from
                                                    Website/Application, written or oral, will not create
                                                    any warranty and the Website/Application disclaims all
                                                    liabilities resulting from these. You are solely
                                                    responsible for your communication/interaction with
                                                    other Users on the Website/Application and the
                                                    Website/Application doesn’t make any warranty about the
                                                    conduct of Users on the Website/Application.
                                                </li>
                                                <li>
                                                    The Website/Application does not guarantee that the
                                                    functions and services contained in the
                                                    Website/Application will be uninterrupted or error-free,
                                                    or that the Website/Application or its server will be
                                                    free of viruses or other harmful components, and the
                                                    User hereby expressly accepts any and all associated
                                                    risks involved with the User’s use of the
                                                    Website/Application.
                                                </li>
                                                <li>
                                                    The Website/Application may avail services from third
                                                    party or may provide links for sharing Our content on
                                                    Facebook, Twitter or other such third party
                                                    Website/Applications, this is done to serve you better
                                                    and these services will be provided on “as is” basis and
                                                    the Website/Application disclaims any liabilities
                                                    resulting from these third party services. The
                                                    Website/Application will not be responsible for any
                                                    internet delays and damages caused by such problems.We
                                                    also disclaim all liabilities and take no responsibility
                                                    for the content that may be posted on such third party
                                                    Website/Applications by the users of such
                                                    Website/Applications in their personal capacity on any
                                                    of the above mentioned links for sharing and/or listing
                                                    purposes as well as any content and/or comments that may
                                                    be posted by such user in their personal capacity on any
                                                    official webpage of <a href="#">www.tummoc.com </a>on
                                                    any social networking platform.
                                                </li>
                                                <li>
                                                    It is further agreed to by the Parties that the contents
                                                    of this Section shall survive even after the termination
                                                    or expiry of the Terms and/or Policy.
                                                </li>
                                            </ul>
                                            <li>
                                                <b>NOTICES:</b> Any and all communication relating to any
                                                dispute or grievance experienced by the User may be
                                                communicated to the Company by the User reducing the same
                                                to writing, and sending the same to the registered office
                                                of the Company by Registered Post, or the email address
                                                support@tummoc.com.
                                            </li>
                                            <li>
                                                <b>DISPUTES ARISING OUT OF THIS AGREEMENT: </b>All disputes
                                                involving but not limited to rights conferred,
                                                compensation, refunds, and other claims will be resolved
                                                through a two-step Alternate Dispute Resolution mechanism.
                                            </li>
                                            <ul>
                                                <li>
                                                    <b>Stage 1: Mediation.</b> In case of a dispute, the
                                                    matter will first be attempted to be resolved by a sole
                                                    mediator who is a neutral third party and will be
                                                    selected at the mutual acceptance of a proposed mediator
                                                    by both parties. Both parties may raise a name for the
                                                    sole mediator and in the case both parties accept the
                                                    proposed name, the said person shall be appointed as
                                                    sole mediator. In case the parties are not able to reach
                                                    a consensus within two proposed mediators, the Company
                                                    reserves the right to decide the final mediator. The
                                                    decision of the mediator is binding on both parties.
                                                </li>
                                                <li>
                                                    <b>Stage 2: Arbitration.</b> In case that mediation does
                                                    not yield a suitable result or preferred by any one of
                                                    the parties, arbitration may follow, the award of which
                                                    is binding on both parties. The Arbitration Board shall
                                                    comprise three members – one appointed by each party and
                                                    the third member to be nominated by the two appointed
                                                    members by mutual consent. Arbitration shall be held at
                                                    Bangalore, India. The proceedings of arbitration shall
                                                    be in the English language. The arbitrator’s award shall
                                                    be final and binding on the Parties.The formation,
                                                    interpretation and performance of this Agreement and any
                                                    disputes arising out of it shall be governed by the law,
                                                    rules and regulations of Bangalore, India. The exclusive
                                                    jurisdiction and venue for actions and disputes may be
                                                    as mentioned above, and You hereby submit to the
                                                    jurisdiction of such courts.
                                                </li>
                                            </ul>
                                            <li>
                                                <b>PRIVACY: </b> We encourage You to read the Privacy Policy,
                                                and to use the information it contains to make informed
                                                decisions regarding Your personal information. Please also
                                                note that certain information, statements, data and
                                                content (such as but not limited to information related to
                                                Your sign-up) which You provide on the Website/Application
                                                are likely to reveal Your information about You. You
                                                acknowledge and agree that Your submission of such
                                                information is voluntary on Your part. Further, You
                                                acknowledge, consent and agree that We may access,
                                                preserve, and disclose information You provide to Us at
                                                any stage during Your use of the Website/Application.
                                                Disclosures of information to Third Parties are further
                                                addressed in Our Privacy Policy. The data collected from
                                                the Users as well as from the Service provider may be
                                                located in servers outside of India.
                                            </li>
                                            <li>
                                                <b>MISCELLANEOUS PROVISIONS:</b>
                                            </li>
                                            <ul>
                                                <li>
                                                    <b>Entire Agreement:</b> This Agreement is the complete
                                                    and exclusive statement of the agreements between You
                                                    and Us with respect to the subject matter hereof and
                                                    supersedes all other communications or representations
                                                    or agreements (whether oral, written or otherwise)
                                                    relating thereto.
                                                </li>
                                                <li>
                                                    <b>Waiver:</b> The failure of either party at any time
                                                    to require performance of any provision of this
                                                    Agreement in no manner shall affect such party’s right
                                                    at a later time to enforce the same. No waiver by either
                                                    party of any breach of this Agreement, whether by
                                                    conduct or otherwise, in any one or more instances,
                                                    shall be deemed to be, or construed as, a further or
                                                    continuing waiver of any other such breach, or a waiver
                                                    of any other breach of this Agreement.
                                                </li>
                                                <li>
                                                    <b>Severability:</b> If any provision of this Agreement
                                                    shall to any extent be held invalid, illegal or
                                                    unenforceable, the validity, legality and enforceability
                                                    of the remaining provisions of this Agreement shall in
                                                    no way be affected or impaired thereby and each such
                                                    provision of this Agreement shall be valid and
                                                    enforceable to the fullest extent permitted by law. In
                                                    such case, this Agreement shall be reformed to the
                                                    minimum extent necessary to correct any invalidity,
                                                    illegality or unenforceability, while preserving to the
                                                    maximum extent the rights and commercial expectations of
                                                    the parties hereto, as expressed herein.
                                                </li>
                                                <li>
                                                    <b>Liability:</b> The Company shall not be liable in any
                                                    way for User Content, including, but not limited to, any
                                                    errors or omissions contained therein, or any loss or
                                                    damage of any kind incurred as a result of the use of
                                                    any User Content posted, emailed, transmitted or
                                                    otherwise made available via the Service or broadcast
                                                    elsewhere.
                                                </li>
                                            </ul>
                                            <li>
                                                <b>CONTACT US: </b> If you have any questions about this Agreement,
                                                the practices of the Website/Application, or your
                                                experience with the Service, you can e-mail us at
                                                support@tummoc.com.
                                            </li>

                                            {/* use of data */}
                                            <br /><br />
                                            <li>
                                                <b>USE OF DATA</b>
                                            </li>
                                            <p>Information We Collect</p>
                                            <b>1.1 Personal Information</b>
                                            <p>We may collect various types of personal information, including but not limited to:</p>
                                            <ul>
                                                <li>Contact information (e.g., name, email address, phone number)</li>
                                                <li>Demographic information (e.g., age, gender, location)</li>
                                                <li>Account credentials (e.g., username, password)</li>
                                                <li>Usage data (e.g., log files, device information, IP address)</li>
                                                <li>Other information you provide to us voluntarily</li>
                                            </ul>

                                            <br />
                                            <b>1.2 Cookies and Similar Technologies</b>
                                            <p>We may use cookies, web beacons, and similar technologies to collect information about your usage of our Services. This helps us analyze trends, administer the website, track users' movements, and gather demographic information.</p>

                                            <p>Use of Information</p>
                                            <p>We use the collected information for various purposes, including:</p>
                                            <ul>
                                                <li>Providing and improving our Services</li>
                                                <li>Personalizing your experience</li>
                                                <li>Responding to your inquiries and providing customer support</li>
                                                <li>Processing transactions and delivering requested products or services</li>
                                                <li>Analyzing usage trends and optimizing our Services</li>
                                                <li>Communicating with you about updates, promotions, and other relevant information</li>
                                            </ul>

                                            <br />
                                            <b>1.3 Disclosure of Information</b>
                                            <p>We may disclose your personal information to third parties in the following circumstances:</p>
                                            <ul>
                                                <li>Service Providers: We may share your information with trusted third-party service providers who assist us in delivering our Services and conducting our business activities.</li>
                                                <li>Legal Compliance: We may disclose your information if required by law, governmental request, court order, or to protect our rights, property, or safety, as well as that of our users or others.</li>
                                                <li>Business Transfers: If our business undergoes a merger, acquisition, or sale of assets, your personal information may be transferred or disclosed as part of the transaction.</li>
                                            </ul>

                                            <br />
                                            <b>1.4 Data Security</b>
                                            <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

                                            <br />
                                            <b>1.5 Your Rights</b>
                                            <p>You have certain rights regarding your personal information, including:</p>
                                            <ul>
                                                <li><b>Access:</b> You can request access to the personal information we hold about you.</li>
                                                <li><b>Correction:</b> You may request corrections or updates to your personal information.</li>
                                                <li><b>Erasure & Account Deletion:</b> You can request the deletion of your personal information by using the "Delete Account" feature available in our Android and iOS apps. Additionally, you can write an email to us at support@tummoc.com for assistance. The process might take 7-14 days, subject to certain legal obligations and legitimate business purposes.</li>
                                                <li><b>Object:</b> You can object to the processing of your personal information in certain circumstances.</li>
                                            </ul>

                                            <br />
                                            <b>1.6 Children's Privacy</b>
                                            <p>Our Services are not intended for individuals under the age of 13 and above. We do not knowingly collect personal information from children. If you believe we have inadvertently collected personal information from a child, please contact us, and we will take appropriate steps to delete such information.</p>

                                            <br />
                                            <b>1.7 Changes to this Privacy Policy</b>
                                            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other communication channels.</p>

                                            <br />
                                            <b>Contact Us</b>
                                            <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us using the information below:</p>
                                            <p>
                                                Transhelp Technologies Pvt Ltd<br />
                                                No. 5 Maruthi Complex, GF, 9th 'B Main,<br />
                                                Dodda Banaswadi Main Rd, Kalyan Nagar,<br />
                                                Bengaluru, Karnataka 560043<br />
                                                <b>Email:</b> support@tummoc.com
                                            </p>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </section>

                    <section style={{ position: "relative" }}>

                        <section className="vote-section" style={{ position: 'absolute', top: '-10rem', width: '100%' }}>
                            <div style={{ background: '#fff', borderRadius: '2rem' }}>
                                <div className="link-section">
                                    <Applink />
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </section>
                </div>
            </>
        )
    }
}