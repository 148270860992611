import React from "react";

export default class Applink extends React.Component {

    render() {
        return (
          <>
            {/* <div>
                <div style={{backgroundImage: "url('/images/headerbg3.png')", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}> */}
                    <div className="row" style={{margin: 0}}>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <img src="/images/side.png" alt="top header logo" className="link-side-img" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <p className="txt-grad getitnow get-app headb">Get the<br></br>app now!</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{display: "flex"}}>
                            <div className="row" style={{margin:0}}>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                                <a href="https://0x92l.app.link/mql2Jm1fqvb" rel="noreferrer" target="_blank">
                                    <img src="/images/googleplay.png" alt="top header logo"  className="link" />
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <a href="https://0x92l.app.link/mql2Jm1fqvb" rel="noreferrer" target="_blank">
                                    <img src="/images/appstore.png" alt="top header logo"  className="link"  />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div>
            </div> */}
          </>
        )
    }

}