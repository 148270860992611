import React from "react";
import Footer from "../footer";
import Header from "../header";
import { Helmet } from "react-helmet";
import Applink from "../applink";
import "./media.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default class Media extends React.Component {

  constructor(props) {
    super(props);
    this.state = { open: false };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      open: !state.open
    }));
  };

  render() {


    const ButtonGroupNext = ({ next, previous, goToSlide, ...rest }) => {
      const { carouselState: { currentSlide } } = rest;
      return (
        <div>
          <button onClick={() => previous()} className="pn-btn a-btn1">
            <i className="fa fa-chevron-left" style={{ fontSize: "1.4rem" }} aria-hidden="true"></i>
          </button>
          <button onClick={() => next()} className="pn-btn a-btn2">
            <i className="fa fa-chevron-right" style={{ fontSize: "1.4rem" }} aria-hidden="true"></i>
          </button>
        </div>
      );
    };


    const responsivenext = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
      },
      mini: {
        breakpoint: { max: 1024, min: 786 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 786, min: 360 },
        items: 1,
        partialVisibilityGutter: 80
      },
      mobile: {
        breakpoint: { max: 360, min: 0 },
        items: 1
      }
    }


    const award = [
      {
        name: 'Elevate 2018, Govt. of Karnataka, Winner'
      },
      {
        name: 'National startup award 2020, Winner'
      },
      {
        name: 'Stamp 2018, WRI India, Winner'
      },
      {
        name: 'IIMC Smart 50, Top 400 startups in India, Finalist'
      },
      {
        name: 'EO Cares, Finalist'
      }
    ]


    const news = [

        // latest Dec 2023
        {
          title: 'Economic Times Auto',
          image: 'ETAuto.png',
          description: 'Tummoc partners with redBus to simplify outstation travel with one solution',
          link: 'https://auto.economictimes.indiatimes.com/news/commercial-vehicle/mhcv/tummoc-partners-with-redbus-to-simplify-outstation-travel-with-one-solution/105102841'
        },
        {
          title: 'Express Mobility',
          image: 'expressmobility.png',
          description: 'Tummoc partners redBus for end-to-end outstation transportation solution',
          link: 'https://www.financialexpress.com/business/express-mobility-tummoc-partners-redbus-for-end-to-end-outstation-transportation-solution-3302454/'
        },
        {
          title: 'The Times of India',
          image: 'timesofindia.png',
          description: '85 percent commuters think Indian public transport is inadequate: Overcrowding, maintenance among major issues',
          link: 'https://timesofindia.indiatimes.com/auto/news/85-percent-commuters-think-indian-public-transport-is-inadequate-overcrowding-maintenance-among-major-issues/articleshow/104553408.cms'
        },
        {
          title: 'Josh Talk',
          image: 'joshtalk.png',
          description: 'कब तक सिर्फ Middle Class औरतों को ये सहना पड़ेगा ? | Monalisha Thakur | Josh Talks Aasha',
          link: 'https://www.youtube.com/watch?v=LUBP3gbIsnc'
        },
        {
          title: 'Financial Express',
          image: 'financialexpress_fundraised.png',
          description: 'Tummoc raises $1 million in pre-series A round led by Inflection Point Ventures',
          link: 'https://www.financialexpress.com/business/industry-tummoc-raises-1-million-in-pre-series-a-round-led-by-inflection-point-ventures-3137656/'
        },
        {
          title: 'Tech Graph',
          image: 'techgraph.png',
          description: 'Tummoc’s Adaptability: Hiranmay Mallick On Meeting Diverse Public Transportation Needs In India',
          link: 'https://techgraph.co/interviews/tummocs-hiranmay-mallick-meeting-diverse-transportation-needs-india/'
        },
        {
          title: 'Your Story',
          image: 'yourstory_CEO.png',
          description: 'WHAT DOES THE RISE OF ALL-IN-ONE MOBILITY APPS REPRESENT?',
          link: 'https://yourstory.com/2023/08/-all-in-one-mobility-commute-digital-age'
        },
        {
          title: 'APN News',
          image: 'apnnews_bmtcpasses.png',
          description: 'Tummoc Surpasses 1.5 Million Distributed BMTC Passes, Celebrates Unprecedented Achievement',
          link: 'https://www.apnnews.com/tummoc-surpasses-1-5-million-distributed-bmtc-passes-celebrates-unprecedented-achievement/'
        },
        {
          title: 'Deccan Herald',
          image: 'deccanherald_rapido.png',
          description: 'Bengaluru: Book Rapido rides through Tummoc app',
          link: 'https://www.deccanherald.com/india/karnataka/bengaluru/bengaluru-book-rapido-rides-through-tummoc-app-1233679.html'
        },
        {
          title: 'Economic Times Auto',
          image: 'ETAuto_rapido.png',
          description: 'Tummoc partners with Rapido for first and last mile connectivity',
          link: 'https://auto.economictimes.indiatimes.com/news/aftermarket/tummoc-partners-with-rapido-for-first-and-last-mile-connectivity/101451762'
        },
        {
          title: 'CXOtoday.com',
          image: 'cxotoday.png',
          description: 'Tummoc: Revolutionizing Urban Commuting with AI-Powered Multi-Modal Solutions',
          link: 'https://cxotoday.com/interviews/tummoc-revolutionizing-urban-commuting-with-ai-powered-multi-modal-solutions/'
        },
        {
          title: 'Deccan Chronicle',
          image: 'deccanchronicle.png',
          description: 'Reimagining Urban Mobility: Role of Public Transport in Reducing Carbon Footprint',
          link: 'https://www.deccanchronicle.com/nation/in-other-news/240623/reimagining-urban-mobility-role-of-public-transport-in-reducing-carbo.html'
        },
        {
          title: 'ET Travel World',
          image: 'ETtravelworld.png',
          description: 'The impact of transit apps on sustainable tourism: Opportunities & challenge',
          link: 'https://travel.economictimes.indiatimes.com/news/people/the-impact-of-transit-apps-on-sustainable-tourism-opportunities-challenges/100065850'
        },
        {
          title: 'Times Drive',
          image: 'timesdrive.png',
          description: 'This smartphone application can live-track DTC buses for you',
          link: 'https://www.timesnownews.com/auto/this-smartphone-application-can-live-track-dtc-buses-for-you-article-98444006'
        },
        {
          title: 'Economic Times Auto',
          image: 'ETAuto_livetracking.png',
          description: 'Tummoc launches live-tracking feature for DTC buses',
          link: 'https://auto.economictimes.indiatimes.com/news/commercial-vehicle/tummoc-launches-live-tracking-feature-for-dtc-buses/98444924'
        },
        {
          title: 'Financial Express Mobility',
          image: 'livetracking_delhi.png',
          description: 'Tummoc introduces live tracking feature for Delhi Transport Corporation buses',
          link: 'https://www.financialexpress.com/business/express-mobility-tummoc-introduces-live-tracking-feature-for-delhi-transport-corporation-buses-3001031/'
        },
        {
          title: 'BW Auto World',
          image: 'bwautoworld_100Cities.png',
          description: 'Tummoc Plans To Expand Its Footprint In 100 Cities By FY24',
          link: 'https://bwautoworld.businessworld.in/article/Tummoc-Plans-To-Expand-Its-Footprint-In-100-Cities-By-FY24/22-03-2023-470075/'
        },
        // {
        //   title: 'Financial Express Mobility',
        //   image: '2million_downloads.png',
        //   description: 'Tummoc, transit app, reaches 2 million downloads',
        //   link: 'https://www.financialexpress.com/business/express-mobility-tummoc-transit-app-reaches-2-million-downloads-2955287/'
        // },

       // latest feb 2023
       {
        title: 'BW Auto World',
        image: 'bwautoworld.png',
        description: 'Tech Is Making Public Transport Easy, Says Tummoc CEO',
        link: 'http://bwautoworld.businessworld.in/article/Tech-Is-Making-Public-Transport-Easy-Says-Tummoc-CEO/03-12-2022-456573/'
      },
      {
        title: 'Travel Economic Times',
        image: 'travel-transport.png',
        description: 'Buses are most preferred mode of transport for 30% women, reveals survey',
        link: 'https://travel.economictimes.indiatimes.com/news/research-and-statistics/figures/buses-are-most-preferred-mode-of-transport-for-30-women-reveals-survey/96421439'
      },
      {
        title: 'Financial Express',
        image: 'urban-mobility.png',
        description: 'Trends in the urban mobility sector that will bloom in 2023',
        link: 'https://www.financialexpress.com/express-mobility/trends-in-the-urban-mobility-sector-that-will-bloom-in-2023/2932854/'
      },
      {
        title: 'Business News Week',
        image: 'auto-sector.png',
        description: 'Expectation of Auto Sector from Budget 2023',
        link: 'https://businessnewsweek.in/business/expectation-of-auto-sector-from-budget-2023/'
      },
      {
        title: 'BW Auto World',
        image: 'bwauto-multimodal.png',
        description: 'Tummoc\'s Multi-Modal Transit App Reaches 2 Million Downloads',
        link: 'http://bwautoworld.businessworld.in/article/Tummoc-s-Multi-Modal-Transit-App-Reaches-2-Million-Downloads/20-01-2023-462718/'
      },
      {
        title: 'Financial Express',
        image: 'financialexpress-tummoc.png',
        description: 'Tummoc, transit app, reaches 2 million downloads',
        link: 'https://www.financialexpress.com/express-mobility/tummoc-transit-app-reaches-2-million-downloads/2955287/'
      },
      {
        title: 'EV STORY',
        image: 'evstory-tech.png',
        description: 'Sustainable Startups Transforming The EV Tech Industry',
        link: 'https://www.evstory.in/sustainable-startups-transforming-the-tech-industry/'
      },
      {
        title: 'Sakshi POST',
        image: 'sakshi-post-tech.png',
        description: 'Technology Brands Hitting The Right Goal',
        link: 'https://english.sakshi.com/news/technology/technology-brands-hitting-right-goal-168307'
      },
      {
        title: 'Life and More',
        image: 'unionbudget.png',
        description: 'CAIT terms Union Budget 2023-24 as a ‘Progressive Economic Document’',
        link: 'https://lifeandmore.in/more/business-industry/progressive-economic-document/'
      },

      // latest-12 dec 2022
      {
        title: 'Financial Express',
        image: 'local-transport.png',
        description: 'Digital transport industry is the new ‘Vocal for Local',
        link: 'https://www.financialexpress.com/lifestyle/travel-tourism/digital-transport-industry-is-the-new-vocal-for-local/2688282/'
      },
      {
        title: 'Times of India',
        image: 'multimodal-mobility.png',
        description: 'Multimodal mobility: Pedaling towards a greener future',
        link: 'https://timesofindia.indiatimes.com/blogs/voices/multimodal-mobility-pedaling-towards-a-greener-future/'
      },
      {
        title: 'Forbes',
        image: 'forbes.png',
        description: 'Every Leader Can Benefit From Coaching. Here’s Why',
        link: 'https://www.forbes.com/sites/benjaminlaker/2022/10/04/every-leader-can-benefit-from-coaching-heres-why/?sh=7bb5787e7e1f'
      },
      {
        title: 'Deccan Herald',
        image: 'tummoc-kannada.png',
        description: 'Tummoc app in Kannada',
        link: 'https://www.deccanherald.com/amp/city/tummoc-app-in-kannada-1154545.html'
      },
      {
        title: 'Bangalore Mirror',
        image: 'tummoc-kannada.png',
        description: 'BMTC TO INTRODUCE E-TICKETS',
        link: 'https://bangaloremirror.indiatimes.com/bangalore/civic/bmtc-to-introduce-e-tickets/amp_articleshow/95019323.cms'
      },
      {
        title: 'sakshi',
        image: 'sakshi.png',
        description: 'Making Commute Easier and Simpler, Tummoc Launches Chatbot Support',
        link: 'https://menglish.sakshi.com/amp/news/business/making-commute-easier-and-simpler-tummoc-launches-chatbot-support-164438'
      },

      // latest
      {
        title: 'Analytics Insight',
        image: 'narayan.jpg',
        description: 'EXCLUSIVE INTERVIEW WITH NARAYAN MISHRA, CO-FOUNDER & CTO AT TUMMOC',
        link: 'https://www.analyticsinsight.net/exclusive-interview-with-narayan-mishra-co-founder-cto-at-tummoc/'
      },
      {
        title: 'Tech Podcast Series',
        image: 'narayan2.png',
        description: 'Tech Podcast Series | Narayan Mishra',
        link: 'https://www.youtube.com/watch?v=LbDM7SkPjHg&list=PLVQFoklbAYzYhvOfWxSZrpFok0tAZYI0w&index=46'
      },
      {
        title: 'The Hindu',
        image: 'n5.jpg',
        description: 'App makes it easy to buy BMTC daily, weekly and monthly passes',
        link: 'https://www.thehindu.com/news/cities/bangalore/bmtcs-digital-passes-generate-25-crore-revenue-within-20-days-of-july/article65667232.ece'
      },
      {
        title: 'Vijay Karnataka',
        image: 'n5.jpg',
        description: "ಬಿಎಂಟಿಸಿಗೆ 'ಟುಮೋಕ್‌' ಟಾನಿಕ್‌, ಡಿಜಿಟಲ್‌ ಆ್ಯಪ್‌ನಿಂದ 24 ದಿನಗಳಲ್ಲೇ ₹2.61 ಕೋಟಿ ಆದಾಯ",
        link: 'https://vijaykarnataka.com/business/news/bmtc-tummoc-digital-bus-passes-a-hit-app-earns-rs-2-61-crore-in-24-days-of-july/articleshow/93150408.cms'
      },
      {
        title: 'APN News',
        image: 'flexi-pass.jpg',
        description: 'Multi Modal Transit App Tummoc Launches Flexi Pass for Hassle Free Travel',
        link: 'https://www.apnnews.com/multi-modal-transit-app-tummoc-launches-flexi-pass-for-hassle-free-travel/'
      },
      {
        title: 'Business News This Week',
        image: 'flexi-pass.jpg',
        description: 'Tummoc Launches Flexi Pass for Hassle Free Trave',
        link: 'http://businessnewsthisweek.com/technology/tummoc-launches-flexi-pass-for-hassle-free-travel/'
      },
      {
        title: 'Media Bulletins',
        image: 'flexi-pass.jpg',
        description: 'Tummoc Launches Flexi Pass for Hassle Free Travel',
        link: 'http://mediabulletins.com/technology/tummoc-launches-flexi-pass-for-hassle-free-travel/'
      },
      {
        title: 'Business News Week',
        image: 'flexi-pass.jpg',
        description: 'Tummoc Launches Flexi Pass for Hassle Free Travel',
        link: 'https://businessnewsweek.in/business/multi-modal-transit-app-tummoc-launches-flexi-pass-for-hassle-free-travel/'
      },
      {
        title: 'Content Media Solution',
        image: 'flexi-pass.jpg',
        description: 'Tummoc Launches Flexi Pass for Hassle Free Travel',
        link: 'https://contentmediasolution.com/business/multi-modal-transit-app-tummoc-launches-flexi-pass-for-hassle-free-travel/'
      },
      {
        title: 'Online Media Cafe',
        image: 'flexi-pass.jpg',
        description: 'Tummoc Launches Flexi Pass for Hassle Free Travel',
        link: 'https://onlinemediacafe.com/business/multi-modal-transit-app-tummoc-launches-flexi-pass-for-hassle-free-travel/'
      },
      {
        title: 'Mumbai News Networks',
        image: '1M-Download.jpg',
        description: 'Multi Modal Transit App, Tummoc reaches 1 million downloads',
        link: 'https://mumbainewsnetworks.blogspot.com/2022/07/multi-modal-transit-app-tummoc-reaches.html?m=1'
      },
      {
        title: 'PNI News',
        image: '1M-Download.jpg',
        description: 'Multi Modal Transit App, Tummoc reaches 1 million downloads',
        link: 'https://www.pninews.com/multi-modal-transit-app-tummoc-reaches-1-million-downloads/'
      },
      {
        title: 'Business News This Week',
        image: '1M-Download.jpg',
        description: 'Tummoc reaches 1 million downloads',
        link: 'http://businessnewsthisweek.com/business/tummoc-reaches-1-million-downloads/'
      },
      {
        title: 'Media Bulletins',
        image: '1M-Download.jpg',
        description: 'Tummoc reaches 1 million downloads',
        link: 'http://mediabulletins.com/business/multi-modal-transit-app/'
      },
      {
        title: 'The Hans India',
        image: '1M-Download.jpg',
        description: 'Multi Modal Transit App, Tummoc reaches 1 million downloads',
        link: 'https://www.thehansindia.com/amp/technology/tech-news/multi-modal-transit-app-tummoc-reaches-1-million-downloads-754004'
      },
      {
        title: 'English Sakshi',
        image: '1M-Download.jpg',
        description: 'Multi Modal Transit App, Tummoc reaches 1 million downloads',
        link: 'https://english.sakshi.com/news/business/multi-modal-transit-app-tummoc-reaches-1-million-downloads-158339'
      },
      {
        title: 'Express Computer',
        image: '1M-Download.jpg',
        description: 'Multi Modal Transit App, Tummoc reaches 1 million downloads',
        link: 'https://www.expresscomputer.in/news/multi-modal-transit-app-tummoc-reaches-1-million-downloads/88453/'
      },
      {
        title: 'IT Voice',
        image: '1M-Download.jpg',
        description: 'Multi Modal Transit App, Tummoc reaches 1 million downloads',
        link: 'https://www.itvoice.in/multi-modal-transit-app-tummoc-reaches-1-million-downloads'
      },
      {
        title: 'Media Brief',
        image: '1M-Download.jpg',
        description: 'Multi Modal Transit App, Tummoc reaches 1 million downloads',
        link: 'https://mediabrief.com/multi-modal-transit-app-tummoc-reaches-1-million-downloads/'
      },
      {
        title: 'AD Gully',
        image: '1M-Download.jpg',
        description: 'Multi Modal Transit App, Tummoc reaches 1 million downloads',
        link: 'https://www.adgully.com/multi-modal-transit-app-tummoc-reaches-1-million-downloads-120476.html?amp=1'
      },
      {
        title: 'Digital Terminal',
        image: '1M-Download.jpg',
        description: 'Multi Modal Transit App, Tummoc reaches 1 million downloads',
        link: 'https://digitalterminal.in/news/public-transport-app-tummoc-clocked-1-million-downloads/30198.html'
      },



      // new 
      {
        title: 'ANI News',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://www.aninews.in/news/business/business/tummoc-the-mobility-startup-eying-to-launch-in-10-more-cities20220308150238/'
      },
      {
        title: 'Business Standard',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://www.business-standard.com/content/press-releases-ani/tummoc-the-mobility-startup-eying-to-launch-in-10-more-cities-122030801087_1.html#:~:text=10%20more%20cities-,New%20Delhi%20%5BIndia%5D%2C%20March%208%20(ANI%2FSRV,Bengaluru%2C%20Kolkata%2C%20and%20Hyderabad.'
      },
      {
        title: 'Daily Hunt',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://m.dailyhunt.in/news/india/english/ani67917250816496966-epaper-anieng/tummoc+the+mobility+startup+eying+to+launch+in+10+more+cities-newsid-n366165834?listname=newspaperLanding&index=68&topicIndex=0&mode=pwa&action=click'
      },
      {
        title: 'Zee 5',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://www.zee5.com/articles/tummoc-the-mobility-startup-eying-to-launch-in-10-more-cities'
      },
      {
        title: 'Print',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://theprint.in/ani-press-releases/tummoc-the-mobility-startup-eying-to-launch-in-10-more-cities/863738/'
      },
      {
        title: 'Hans India',
        image: 'n2.jpg',
        description: "International Women's Day 2022 Live Updates: Women Tech Leaders on 'gender equality today for a sustainable tomorrow' ",
        link: 'https://www.thehansindia.com/amp/live-updates/international-womens-day-2022-live-updates-women-tech-leaders-on-gender-equality-today-for-a-sustainable-tomorrow-732423'
      },
      {
        title: 'India TV',
        image: 'n3.jpg',
        description: "International Women's Day: Women's role in socio-economic development of a country",
        link: 'https://www.indiatvnews.com/amp/news/india/international-women-s-day-women-s-role-in-socio-economic-development-of-a-country-2022-03-08-763195'
      },
      {
        title: 'Christine Twitter',
        image: 'n4.jpg',
        description: "Soon, avail @BMTC_BENGALURU bus passes through @tummoc4u app",
        link: 'https://twitter.com/ChristinMP_TOI/status/1501853160209469446?t=pSLGENsJ3Pyyt4SPIaUGhQ&s=19'
      },
      {
        title: 'BMTC Twitter',
        image: 'n4.jpg',
        description: "Tummoc @BMTC_BENGALURU",
        link: 'https://twitter.com/BMTC_BENGALURU/status/1502103305048436740?t=Qsge3G_5JzhqAnxsx5ZiMw&s=19'
      },
      {
        title: 'The Hindu',
        image: 'n4.jpg',
        description: "Cashless payment options for BMTC passes",
        link: 'https://www.thehindu.com/news/national/karnataka/cashless-payment-options-for-bmtc-passes/article65212221.ece'
      },
      {
        title: 'Survarna - Dailyhunt',
        image: 'n5.webp',
        description: "ಇನ್ಮುಂದೆ ಮೊಬೈಲ್‌ ಆಯಪಲ್ಲೇ BMTC ಬಸ್‌ ಪಾಸ್‌..!",
        link: 'https://m.dailyhunt.in/news/india/kannada/suvarnanews+tv-epaper-suvarna/inmunde+mobail+aayapalle+bmtc+bas+paas-newsid-n366962986?s=a&ss=wsp'
      },
      {
        title: 'Asia Net News',
        image: 'n5.webp',
        description: "ಇನ್ಮುಂದೆ ಮೊಬೈಲ್‌ ಆ್ಯಪಲ್ಲೇ BMTC ಬಸ್‌ ಪಾಸ್‌..!",
        link: 'https://kannada.asianetnews.com/business/bmtc-bus-pass-is-now-available-on-mobile-app-grg-r8k1sy'
      },
      {
        title: 'Times of India',
        image: 'n6.webp',
        description: "Bengaluru: Use mobile app to buy BMTC bus passes",
        link: 'https://timesofindia.indiatimes.com/city/bengaluru/soon-bengalurueans-to-buy-bmtc-bus-passes-through-mobile-app/articleshow/90145697.cms'
      },
      {
        title: 'Free Press Journal',
        image: 'n7.webp',
        description: "Mobility startup Tummoc plans to establish operations in 10 more cities by end of 2022",
        link: 'https://www.freepressjournal.in/business/mobility-startup-tummoc-plans-to-establish-operations-in-10-more-cities-by-end-of-2022'
      },
      {
        title: 'Press Headliner',
        image: 'n1.jpg',
        description: 'Tummoc, the mobility startup, eying to launch in 10 more cities',
        link: 'https://pressheadliner.com/tummoc-the-mobility-startup-eying-to-launch-in-10-more-cities/'
      },
      {
        title: 'India Times',
        image: 'n1.jpg',
        description: 'All things pass',
        link: 'https://bangaloremirror.indiatimes.com/bangalore/cover-story/all-things-pass/articleshow/90158274.cms'
      },
      {
        title: 'Hindustan Times',
        image: 'n8.webp',
        description: "Bengaluru city bus pass will now be available online, here's how you can get one",
        link: 'https://www.hindustantimes.com/cities/bengaluru-news/bengaluru-city-bus-pass-will-now-be-available-online-here-s-how-you-can-get-one-101646993732808-amp.html'
      },


      // old
      {
        title: 'Deccan Herald',
        image: 'n-new1.jpg',
        description: 'This app combines public transport with first and last-mile commute',
        link: 'https://www.deccanherald.com/city/this-app-combines-public-transport-with-first-and-last-mile-commute-997936.html'
      },
      {
        title: 'Business Standard',
        image: 'n-new2.jpg',
        description: 'Track the most economical and easiest way to reach your destination through Tummoc App',
        link: 'https://www.business-standard.com/content/press-releases-ani/track-the-most-economical-and-easiest-way-to-reach-your-destination-through-tummoc-app-121082501241_1.html'
      },
      {
        title: 'Your Story',
        image: 'n-new3.png',
        description: '[Funding alert] Bengaluru-based Tummoc raises $540K in seed round',
        link: 'https://yourstory.com/2021/09/funding-bengaluru-based-mobility-startup-tummoc/amp'
      },
      {
        title: 'Most Popular Stories',
        image: 'n-new4.jpg',
        description: 'Interview with Hiranmay Mallick | Founder of Tummoc (formerly Bykerr)',
        link: 'https://mostpopularstories.com/tummoc/'
      },
      //new card end
      {
        title: 'The New Indian Express',
        image: '1.png',
        description: 'To and Fro, last mile connectivity made easy',
        link: 'https://www.newindianexpress.com/cities/bengaluru/2017/jul/19/to-and-fro-last-mile-connectivity-made-easy-1630882.html'
      },
      {
        title: 'Your Story',
        image: '2.png',
        description: 'Hassled by last-mile connectivity problems, couple puts Bykerr on the road',
        link: 'https://yourstory.com/2017/12/hassled-last-mile-connectivity-problems-couple-puts-bykerr-road/'
      },
      {
        title: 'Deccan Herald',
        image: '3.png',
        description: 'App enables shared bike rides to reach Metro, bus stations',
        link: 'https://www.deccanherald.com/content/646762/app-enables-shared-bike-rides.html'
      },
      {
        title: 'Times of India',
        image: '4.png',
        description: 'BMTC plans to get commuters to bike or pedal to bus terminals',
        link: 'https://timesofindia.indiatimes.com/city/bengaluru/bmtc-plans-to-get-commuters-to-bike-or-pedal-to-bus-terminals/articleshow/62911316.cms'
      },
      {
        title: 'Times of India',
        image: '5.png',
        description: 'KIA passengers can seal last-mile connectivity on Vayu Vajra buses',
        link: 'https://timesofindia.indiatimes.com/city/bengaluru/kia-passengers-can-seal-last-mile-connectivity-on-vayu-vajra-buses/articleshow/65371269.cms'
      },
      {
        title: 'Times of India',
        image: '6.png',
        description: 'STAMP challenge: 3 start-ups selected for Metro connectivity',
        link: 'https://timesofindia.indiatimes.com/city/hyderabad/stamp-challenge-3-start-ups-selected-for-metro-connectivity/articleshow/65371182.cms'
      },
      {
        title: 'Your Story',
        image: '7.png',
        description: 'Karnataka selects 77 startups for funding at Elevate 2018',
        link: 'https://yourstory.com/2018/09/karnataka-selects-77-startups-funding-elevate-2018'
      },
      {
        title: 'ET Now',
        image: '8.png',
        description: 'Solutions that Impact everyday Life',
        link: 'https://youtu.be/DcyNIPgbPfw?t=986'
      },
      {
        title: 'Most Popular Stories',
        image: '9.png',
        description: 'Interview with Hiranmay Mallick | Founder of Tummoc (formerly Bykerr)',
        link: 'https://mostpopularstories.com/tummoc/'
      }
    ]

    return (
      <>
        <Helmet>
          <title>Tummoc | Media</title>
        </Helmet>
        <div>
          <Header />

          <div className="s-top">

            <section className="mediaOne">
              <div className="row" style={{ margin: 0 }}>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 headtext top1">
                  <p className="head1 w-1 headb">The Word on the Street</p>
                  <p className="head2 w-2">
                    Our journey has been eventful, to say the least. With every challenge we faced,
                    our belief in Tummoc as a service tailored for India pushed us to come out stronger.
                    While we were focused on getting here, the world took notice.
                  </p>
                </div>
              </div>

              <div className="vote-section vt-1">
                <div className="sectionFour mediaFour">
                  <div className="row" style={{ height: '100%', margin: 0 }}>

                    <div className="col-lg-7 col-md-7 col-sm-7 b-card1">
                      <img src="/images/videoold.png" class="card-img news-img nw-img md-vdo" alt="newsimage" />
                    </div>

                    <div className="col-lg-5 col-md-5 col-sm-5 bl-1">
                      <p style={{ fontSize: '15px' }}>The New Indian Express</p>
                      <h5>To and Fro, last mile connectivity made easy</h5>
                      <br />
                      <p>
                        It is the only app that connects you the the public transport network
                        and local rideshare options to craft the best suited route for your needs.
                      </p>
                      <a href="https://www.newindianexpress.com/cities/bengaluru/2017/jul/19/to-and-fro-last-mile-connectivity-made-easy-1630882.html" target="_blank">
                        <h6 class="card-title">The New Indian Express <i class="fa fa-arrow-right"></i> </h6>
                      </a>

                    </div>

                  </div>
                </div>
              </div>

            </section>

            <section className="mediaTwo">
              <div className="row" style={{ margin: 0 }}>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 testimonial">
                  <p className="news-head nh-1 headb">In the News</p>
                  <p className="news-txt">
                    Read all about it! Here are some of Tummoc’s notable press mentions:
                  </p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">

                  <div className="row">

                    {news && news.map((item, i) => {
                      return (i < 3) && <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div class="card news-card nw-c">
                          <a href={item.link} target="_blank" rel="noreferrer">
                            <img src={`/images/news/${item.image}`} class="card-img news-img nw-img" style={{ borderRadius: "1rem" }} alt="newsimage" />
                          </a>
                          <div class="card-body">
                            <p class="card-text" style={{ fontSize: '0.8rem' }}>{item.description}</p>
                            <a href={item.link} target="_blank" rel="noreferrer">
                              <h6 class="card-title" style={{ fontSize: '0.8rem' }}>{item.title} <i class="fa fa-arrow-right"></i> </h6>
                            </a>
                          </div>
                        </div>
                      </div>
                    })
                    }

                    {
                      !this.state.open &&
                      <div className="col-lg-12 exp-txt" style={{ padding: 0 }}>
                        <p onClick={this.handleClick} style={{ cursor: 'pointer', color: '#0e2d39', paddingRight: '1rem' }}>
                          Expand <i className="fa fa-chevron-down"></i>
                        </p>
                      </div>
                    }

                    {this.state.open && news && news.map((item, i) => {
                      return (i >= 3) && <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div class="card news-card nw-c">
                          <a href={item.link} target="_blank" rel="nofollow">
                            <img src={`/images/news/${item.image}`} class="card-img news-img nw-img" style={{ borderRadius: "1rem" }} alt="newsimage" />
                          </a>
                          <div class="card-body">
                            <p class="card-text" style={{ fontSize: '0.8rem' }}>{item.description}</p>
                            <a href={item.link} target="_blank" rel="nofollow">
                              <h6 class="card-title" style={{ fontSize: '0.8rem' }}>{item.title} <i class="fa fa-arrow-right"></i> </h6>
                            </a>
                          </div>
                        </div>
                      </div>
                    })
                    }

                    {
                      this.state.open &&
                      <div className="col-lg-12 exp-txt" style={{ padding: 0 }}>
                        <p onClick={this.handleClick} style={{ cursor: 'pointer', color: '#0e2d39', paddingRight: '1rem' }}>
                          Collapse <i className="fa fa-chevron-up"></i>
                        </p>
                      </div>
                    }
                  </div>

                </div>
              </div>
            </section>

            <section className="mediaThree">
              <div style={{ color: '#fff', marginBottom: '1rem' }}>
                <h2 className="m3-p-h">Awards & Recognitions</h2>
              </div>

              <div className="row" style={{ margin: 0, width: '100%', display: 'block', position: 'relative' }}>
                <Carousel
                  additionalTransfrom={0}
                  arrows={false}
                  customButtonGroup={<ButtonGroupNext />}
                  renderButtonGroupOutside={true}
                  draggable
                  autoPlay
                  autoPlaySpeed={30000}
                  focusOnSelect={false}
                  infinite
                  keyBoardControl
                  minimumTouchDrag={80}
                  responsive={responsivenext}
                  showDots={false}
                  partialVisible={true}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                >
                  {award.map((item, i) => {
                    return <div>
                      <div className="card rec-card">
                        <img src="/images/medal.png" style={{ borderRadius: "1rem", width: '100%', height: '100%', padding: '1.5rem' }} alt="award image" />
                      </div>
                      <p className="rec-p">{item.name}</p>
                    </div>
                  })
                  }
                </Carousel>
              </div>
            </section>

            <section style={{ position: "relative" }}>

              <section className="vote-section" style={{ position: 'absolute', top: '-10rem', width: '100%' }}>
                <div style={{ background: '#fff', borderRadius: '2rem' }}>
                  <div className="link-section">
                    <Applink />
                  </div>
                </div>
              </section>

              <Footer />
            </section>

          </div>

        </div>
      </>
    );
  }

}
