import React from "react";
import Footer from "../footer";
import Header from "../header";
import { Helmet } from "react-helmet";
import Applink from "../applink";
import "./drivewithus.css";

export default class Media extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, successMsg: "" };
    this.handleClick = this.handleClick.bind(this);
    this.handleContact = this.handleContact.bind(this);
  }

  handleClick() {
    this.setState((state) => ({
      open: !state.open,
    }));
  }

  handleContact(e) {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    data.subject = "Contact us query";
    e.target.reset();

    fetch("https://www.tummoc.com/api/support/contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result.message);
        this.setState({ successMsg: result.message });

        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Tummoc | Drive with Us</title>
        </Helmet>
        <div>
          <Header />

          <div className="s-top">
            <section className="mediaOne">
              <div className="row" style={{ margin: 0 }}>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 headtext top1">
                  <p className="head1 w-1 txt-grad headb">
                    Be a rider, join <br /> our fleet today!
                  </p>
                  <p className="head2 w-2">
                    Join the smart commute revolution from within. Sign up to be a rider with Tummoc!
                  </p>

                  <a
                    href="https://play.google.com/store/apps/details?id=org.transhelp.bykerrpartner"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <button
                      className="btn my-2 my-sm-0 voteme-btn"
                      type="button"
                    >
                      APPLY NOW
                    </button>
                  </a>
                </div>
              </div>

              <div className="vote-section vt-1">
                <div className="sectionFour mediaFour">
                  <div className="row" style={{ height: "100%", margin: 0 }}>
                    <div className="col-lg-12 col-md-12 col-sm-12 b-card1">
                      <img
                        src="/images/drive.png"
                        class="card-img news-img nw-img md-vdo"
                        alt="newsimage"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="row" style={{ margin: 0, textAlign: "center" }}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 wh-1">
                  <h1>Why drive for Tummoc?</h1>
                  {/* <p className="ph-1">
                    When you join Team Tummoc, you will be a part of the smart
                    commute revolution in India.
                  </p> */}
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 col-12 wh-p">
                  <img
                    src="/images/community.png"
                    alt="top header logo"
                    style={{ height: "7rem" }}
                  />
                  <h4 className="h-1"> Flexibility </h4>
                  <p className="ph-1">
                    As a rider, you will be offered a lot of flexibility when you work with Tummoc.
                  </p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 col-12 wh-p">
                  <img
                    src="/images/pride.png"
                    alt="top header logo"
                    style={{ height: "7rem" }}
                  />
                  <h4 className="h-1"> Opportunity </h4>
                  <p className="ph-1">
                    At Tummoc, you’ll always see opportunities for growth and self-improvement.
                  </p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 col-12 wh-p">
                  <img
                    src="/images/growth.png"
                    alt="top header logo"
                    style={{ height: "7rem" }}
                  />
                  <h4 className="h-1"> Growth </h4>
                  <p className="ph-1">
                    Not only will you be a part of Tummoc’s growth, you’ll also see personal and professional growth for yourself.
                  </p>
                </div>
              </div>
            </section>

            <section className="midsection">
              <div className="mission">
                <div
                  className="row text-center"
                  style={{ margin: 0, padding: "3rem 1rem 0 1rem" }}
                >
                  <div className="col-lg-6 col-md-6 col-12">
                    <p
                      className="news-head hd"
                      style={{ marginLeft: 0, textAlign: "left" }}
                    >
                      Download the App
                    </p>

                    {/* <p
                      className="news-txt"
                      style={{ padding: 0, textAlign: "left" }}
                    >
                      Et has minim elitr intellegat. Mea aeterno eleifend
                      antiopam ad, nam no suscipit quaerendum. At nam minimum
                      ponderum.
                    </p> */}

                    <div className="row" style={{ margin: 0 }}>
                      <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 ">
                        <a
                          href="https://play.google.com/store/apps/details?id=org.transhelp.bykerrpartner"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src="/images/googleplay.png"
                            alt="top header logo"
                            style={{ height: "2.5rem" }}
                            className="link link1"
                          />
                        </a>
                      </div>
                      {/* <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <a href="https://apps.apple.com/us/app/id1534144729" rel="noreferrer" target="_blank">
                            <img src="/images/appstore.png" alt="top header logo" style={{height: '2.5rem'}} className="link link1"  />
                            </a>
                        </div> */}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-12">
                    <img
                      src="/images/dapp.png"
                      alt="newsimage"
                      className="mis-img"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="midsection p-btm">
              <div className="row" style={{ margin: 0, padding: "1rem" }}>
                <div className="col-lg-6 col-md-6 col-12 text-left mis-1">
                  <p className="news-head hd" style={{ marginLeft: 0 }}>
                    How to be a rider?
                  </p>
                  <p className="news-txt" style={{ padding: 0 }}>
                    Et has minim elitr intellegat. Mea aeterno eleifend antiopam
                    ad, nam no suscipit quaerendum. At nam minimum ponderum. Est
                    audiam animal molestiae te. Ex duo eripuit mentitum.Et has
                    minim elitr intellegat. Mea aeterno eleifend antiopam ad,
                    nam no suscipit quaerendum. At nam minimum ponderum. Est
                    audiam animal molestiae te. Ex duo eripuit mentitum.
                  </p>
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <img
                    src="/images/vdo.png"
                    alt="newsimage"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </section>

            <section style={{ position: "relative" }}>
              <section className="newsletter-section doubt">
                <div className="sectionFive five">
                  <div className="row" style={{ margin: 0 }}>
                    <div
                      className="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <p className="newsletter-head">
                        Still in doubt? <br /> Contact Us.
                      </p>
                      <p className="newsletter-txt">
                        We are more than problem solvers. We are commute
                        enthusiasts. Our blogs cover all aspects of the Tummoc
                        experience, commute trends & solutions, and a unique
                        take on public transport.
                      </p>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 subscribe">
                      <form
                        method="post"
                        data-form-title="CONTACT US"
                        onSubmit={(e) => this.handleContact(e)}
                      >
                        <input
                          type="text"
                          placeholder="Name"
                          name="name"
                          className="input-box msg-inp"
                        />
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          className="input-box msg-inp"
                        />
                        <input
                          type="tel"
                          placeholder="Phone"
                          name="phone"
                          className="input-box msg-inp"
                        />
                        <textarea
                          type="text"
                          rows="5"
                          placeholder="Message"
                          name="message"
                          className="input-box msg-txtb"
                        />

                        {this.state.successMsg && (
                          <p style={{ fontSize: "0.8rem", color: "#0e2d39" }}>
                            {this.state.successMsg}
                          </p>
                        )}

                        <button
                          className="btn my-2 my-sm-0 nav-btn vote-btn nlink subscribe-btn"
                          style={{ margin: 0 }}
                          type="submit"
                        >
                          SUBMIT
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </section>

              <Footer />
            </section>
          </div>
        </div>
      </>
    );
  }
}
